import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      map: null,
      mapstyle: [
        {
          name: '',
          mapTileApi:
            'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
          contentBgColor: '#ffffff',
          contentColor: '#000000',
        },
        {
          name: '',
          mapTileApi:
            'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
          contentBgColor: '#ffffff',
          contentColor: '#000000',
        },
        {
          name: '',
          mapTileApi:
            'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
          contentBgColor: '#ffffff',
          contentColor: '#000000',
        },
        {
          name: '',
          mapTileApi:
            'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
          contentBgColor: '#ffffff',
          contentColor: '#000000',
        },
        {
          name: '',
          mapTileApi:
            'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
          contentBgColor: '#ffffff',
          contentColor: '#000000',
        },
      ],
      mapMarker: [
        {
          name: '',
          index: '1',
          type: 'normal',
          image: 'mapMarker/icons/NormlPin/A.png',
        },
        {
          name: '',
          index: '2',
          type: 'normal',
          image: 'mapMarker/icons/NormlPin/A.png',
        },
        {
          name: '',
          index: '3',
          type: 'normal',
          image: 'mapMarker/icons/NormlPin/A.png',
        },
        {
          name: '',
          index: '4',
          type: 'normal',
          image: 'mapMarker/icons/NormlPin/A.png',
        },
        {
          name: '',
          index: '5',
          type: 'normal',
          image: 'mapMarker/icons/NormlPin/A.png',
        },
      ],
      mapFrame: null,
      mapDataUrl: null,
      center: [
        {
          lon: 77.1025,
          lat: 28.7041,
        },
        {
          lon: 77.1025,
          lat: 28.7041,
        },
        {
          lon: 77.1025,
          lat: 28.7041,
        },
        {
          lon: 77.1025,
          lat: 28.7041,
        },
        {
          lon: 77.1025,
          lat: 28.7041,
        },
      ],
      locationName: ['', '', '', '', ''],
      markerLabel: ['', '', '', '', ''],
      zoom: 10,
      extent: [null, null, null, null, null],
      country: '',
    };
  },
  mutations,
  actions,
  getters,
};
