<template>
  <span class="mx-auto" :style="{ backgroundColor: getBg.color }">
    <div
      ref="product"
      class="w-11/12 preview md:w-10/12"
      :class="
        numOfMap > 1
          ? 'frame12x8'
          : 'frame8x12 md:w-[300px] lg:w-[380px] xl:w-[400px]'
      "
      :style="{ outlineColor: selectedOption.frameColor.color }"
    >
      <div
        class="relative grid h-full grid-flow-col p-1 grid-rows-8"
        :style="{ backgroundColor: getBg.color }"
      >
        <img
          src="@/assets/images/preview.svg"
          alt="preview.svg"
          class="absolute z-30 h-full pointer-events-none opacity-20"
        />
        <div
          class="grid content-center text-center"
          :class="
            !getAlternativeFont
              ? 'font-ext-GreatVibes text-lg md:text-xl lg:text-2xl'
              : 'font-semibold text-base md:text-lg lg:xl mb-1'
          "
        >
          {{ getDetailsText.header }}
        </div>
        <div class="flex row-span-6">
          <div
            v-for="num in numOfMap"
            :key="num"
            class="relative grid w-full h-full grid-flow-col grid-rows-5 md:px-2"
          >
            <div class="row-span-4 map-wrap" ref="wrap">
              <div class="shape" :class="shapeClass">
                <map-component
                  :api="getMapStyle[num - 1].mapTileApi"
                  :marker="getMapMarker[num - 1]"
                  :markerIndex="getMapMarker[num - 1].index"
                  :markerType="getMapMarker[num - 1].type"
                  :center="getCenter[num - 1]"
                  :numOfMapOnFrame="numOfMap"
                  :index="num - 1"
                  :mapShape="getMapShape"
                  :showNav="!mobileView"
                  ref="mapComponent"
                />
              </div>
            </div>
            <div class="grid textOnMap conntent-center">
              <div
                id="textScriptPTbold"
                :class="
                  !getAlternativeFont
                    ? 'font-ext-GreatVibes text-[8px] md:text-xl mb-1'
                    : 'text-[6px] md:text-sm mb-1'
                "
              >
                {{ getDetailsText.text[num - 1].main }}
              </div>
              <div class="md:text-xs text-[5px]">
                {{ getDetailsText.text[num - 1].sub }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="grid content-center text-center"
          :class="
            !getAlternativeFont
              ? 'font-ext-GreatVibes text-sm md:text-base lg:text-lg'
              : 'font-semibold text-xs md:text-sm lg:text-base mb-1'
          "
        >
          {{ getDetailsText.footer }}
        </div>
      </div>
    </div>
    <p class="mt-5 text-xs text-center opacity-60">
      *Preview is adjusted as per your screen size, actual print would be to the
      size.
    </p>
    <h2
      class="my-3 text-base font-bold md:text-xl text-center w-10/12 md:w-[500px] xl:w-[750px] mx-auto"
    >
      Create Your Own Map Art | The Rare Window
    </h2>
    <div class="text-sm w-10/12 md:w-[500px] xl:w-[700px] mx-auto">
      <p>
        We feel that travel is one of the most fulfilling aspects of life.
        Exploring new areas fosters curiosity, tenacity, and thankfulness in
        every one of us. Travelling allows us to learn about different cultures
        and better understand our role in the natural world.
      </p>
      <p class="mt-2">
        By encouraging people to travel more with our <b>Location Map Gift</b>,
        we are helping to make the world a better place. Make it an even more
        distinctive and personal experience.
        <b
          >Create Your Own
          <a href="https://therarewindow.com/" class="underline"
            >Personalized Map Art</a
          ></b
        >
        when and where you and your mate had your first date with our
        customizable tool.
      </p>
      <p class="my-2">
        <b>Buy Map Gifts Online</b> and decorate your room with this elegantly
        drawn
        <a href="https://therarewindow.com/celestial-map" class="underline"
          >star map</a
        >. Enter a date and place so we can reflect the sky's unique alignment
        with all the stars above us. Remember the day you first met, the birth
        of a loved one, a wedding, or any other special occasion. The stars,
        like you, are one-of-a-kind.
      </p>
    </div>
  </span>
</template>

<script>
  import { mapGetters } from 'vuex';
  import MapComponent from './map/MapComponent.vue';

  export default {
    components: {
      MapComponent,
    },
    props: {
      numOfMap: {
        type: Number,
        require: true,
      },
    },
    data() {
      return {
        capturing: false,
        setImage: null,
        blob: null,
        wrapDiv: null,
        shapeClass: 'h-full',
      };
    },
    computed: {
      ...mapGetters('storyMap', [
        'selectedOption',
        'selectedFrameSize',
        'getBg',
        'getMapShape',
        'getDetailsText',
        'getAlternativeFont',
      ]),
      ...mapGetters('mapObj', [
        'mapDataUrl',
        'getMapMarker',
        'getMapStyle',
        'getCenter',
      ]),
      mobileView() {
        return window.screen.width <= 480;
      },
    },
    mounted() {
      this.wrapDiv = this.$refs.wrap;
      this.checkRatio();
    },
    methods: {
      checkRatio() {
        if (this.wrapDiv) {
          if (this.wrapDiv[0].clientWidth > this.wrapDiv[0].clientHeight) {
            this.shapeClass = 'h-full';
          } else {
            this.shapeClass = 'w-full';
          }
          for (let i = 0; i < this.$refs.mapComponent.length; i++) {
            this.$refs.mapComponent[i].resizeMap();
          }
        } else {
          this.shapeClass = '';
        }
      },
    },
    watch: {
      numOfMap(newVal) {
        if (newVal) {
          setTimeout(this.checkRatio, 999);
        }
      },
    },
  };
</script>

<style scoped>
  .map-wrap {
    position: relative;
    flex-grow: 2;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px;
  }
  .shape {
    position: relative;
    aspect-ratio: 1 / 1;
  }
  .shape.Square {
    border-radius: 10px;
    overflow: hidden;
  }

  .shape.Circle {
    border-radius: 50%;
    overflow: hidden;
  }

  .textOnMap {
    position: relative;
    z-index: 10;
    text-align: center;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .preview {
    position: relative;
    margin: auto;
    outline: 6px solid transparent;
  }
  .frame12x8 {
    aspect-ratio: 12 / 8;
  }
  .frame8x12 {
    aspect-ratio: 8 / 12;
  }
  .frame8x8 {
    aspect-ratio: 8 / 8;
  }

  @media screen and (min-width: 480px) {
    .preview {
      outline: 12px solid transparent;
    }
  }
</style>
