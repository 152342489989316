<template>
  <app-header />
  <app-loader :show="loader" />
  <div class="container max-w-6xl mx-auto">
    <div class="grid-cols-12 gap-8 lg:grid">
      <div class="col-span-8 px-3 pt-20 mb-5">
        <base-text type="h4">Payment Information</base-text>

        <div class="p-3 border rounded-md border-ext-light-gray">
          <div
            class="pb-3 mb-3 border-b border-ext-light-gray"
            v-if="orderDetails.products[0].printingType !== 'Digital Copy'"
          >
            <div class="grid-cols-12 md:grid">
              <div class="col-span-2 text-ext-gray">Contact:</div>
              <div class="col-span-9">{{ orderDetails.email }}</div>
              <div class="col-span-1 text-right"></div>
            </div>
          </div>
          <div class="pb-3 mb-3 border-b border-ext-light-gray" v-else>
            <div class="grid-cols-12 md:grid">
              <div class="col-span-2 text-ext-gray">Contact:</div>
              <div class="col-span-9">
                {{ orderDetails.billing_address[0].phone }}
              </div>
              <div class="col-span-1 text-right"></div>
            </div>
          </div>
          <div
            class="grid-cols-12 md:grid"
            v-if="orderDetails.products[0].printingType !== 'Digital Copy'"
          >
            <div class="col-span-2 text-ext-gray">Ship to:</div>
            <div class="col-span-9">
              {{ orderDetails.shipping_address[0].address }},
              {{
                orderDetails.shipping_address[0].apartment
                  ? orderDetails.shipping_address[0].apartment + ','
                  : ' '
              }}
              {{ orderDetails.shipping_address[0].pin }},
              {{ orderDetails.shipping_address[0].city }},
              {{ orderDetails.shipping_address[0].state }}
            </div>
            <div class="col-span-1 text-right"></div>
          </div>
          <div v-else>
            <div class="grid-cols-12 md:grid">
              <div class="col-span-2 text-ext-gray">Mail to:</div>
              <div class="col-span-9">{{ orderDetails.email }}</div>
              <div class="col-span-1 text-right"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative col-span-4 lg:pt-16">
        <div class="w-full lg:absolute">
          <div class="p-6 bg-ext-light-pink lg:fixed xl:max-w-sm xl:w-full">
            <p class="uppercase">Your Order</p>
            <hr class="my-4" />
            <div class="grid grid-cols-4 gap-3">
              <div>
                <img
                  :src="orderDetails.finalblob_img"
                  class="pointer-events-none"
                  alt=""
                />
              </div>
              <div class="col-span-3">
                <base-text type="h5" add-class="uppercase">
                  {{ orderDetails.products[0].product_name }}
                </base-text>
                <div class="mb-2 -mt-2 text-ext-gray">Quantity: 1</div>
              </div>
            </div>
            <hr class="my-4" />
            <div class="flex justify-between mb-4">
              <span class="text-ext-gray">Subtotal:</span>
              <strong>₹ {{ orderDetails.total_amount }}</strong>
            </div>
            <div class="flex justify-between mb-4">
              <span class="text-ext-gray">Shipping:</span>
              <strong>Free</strong>
            </div>
            <hr class="my-4" />
            <div class="flex justify-between mb-4">
              <div>
                <strong>Total:</strong>
                <div class="text-sm text-ext-gray">Inclusive of all taxes</div>
              </div>
              <strong class="text-3xl"
                >₹ {{ orderDetails.total_amount }}</strong
              >
            </div>
            <base-button class="w-full" @click.prevent="startPayment"
              >Continue to payment</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppHeader from '@/components/AppHeader.vue';
  import AppLoader from '@/components/AppLoader.vue';
  import BaseText from '@/components/ui/BaseText.vue';
  import BaseButton from '@/components/ui/BaseButton.vue';

  import { mapGetters, mapMutations } from 'vuex';

  export function validName(name) {
    let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
    if (validNamePattern.test(name)) {
      return true;
    }
    return false;
  }

  export default {
    components: { AppHeader, BaseText, BaseButton, AppLoader },
    data() {
      return {
        loader: false,
        sameBillingAddress: false,
        form: {
          email: '',
          send_news_and_Offers: false,
        },
        orderDetails: null,
      };
    },
    computed: {
      ...mapGetters('checkout', [
        'getPrice',
        'getProductDetails',
        'getUserDetails',
        'getPreviewImg',
      ]),
    },
    created() {
      this.getOrderDetails(this.$route.query.orderId);
      this.deleteCookies();
    },
    mounted() {
      this.deleteCookies();
    },
    methods: {
      ...mapMutations('checkout', [
        'setShippingDetails',
        'setBillingDetails',
        'setUserEmail',
        'setSend_news_and_Offers',
      ]),

      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },

      async getOrderDetails(id) {
        this.deleteCookies();
        this.loader = true;
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
          uuid: id,
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };
        await fetch(
          'https://therarewindow.com/api/orderDetails/',
          requestOptions,
        ).then((res) => {
          if (res.ok) {
            res.json().then((data) => (this.orderDetails = data.data));
            this.loader = false;
          }
        });
      },
      async startPayment() {
        this.deleteCookies();
        let formdata = new FormData();
        formdata.append('order_amount', this.orderDetails.total_amount);
        formdata.append('customer_email', this.orderDetails.email);
        formdata.append(
          'customer_phone',
          this.orderDetails.billing_address[0].phone,
        );
        formdata.append('order_id', this.orderDetails.uuid);

        const requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };

        await fetch(
          'https://therarewindow.com/api/startPayment/',
          requestOptions,
        )
          .then((res) => {
            if (res.ok) {
              res.json().then((data) => {
                window.location.replace(data.payment_link);
              });
            }
          })
          .catch((error) => console.log('error', error));
      },
    },
  };
</script>

<style></style>
