<template>
  <app-header />
  <div
    class="container top-16 bottom-0 w-full max-w-6xl mx-auto min-h-[65vh]"
    id="tracking-order"
  >
    <h2
      class="flex justify-center pt-20 text-3xl font-semibold text-orange-400"
    >
      Track Your Order
    </h2>
    <div class="flex flex-row justify-center p-2" v-if="!isTrack">
      <div class="w-2/4">
        <label>Order ID:</label>
        <input
          type="text"
          class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
          placeholder="Enter Your Order ID"
          :class="{ error: v$.orderId.$errors.length }"
          v-model="v$.orderId.$model"
        />
        <div v-for="(error, index) of v$.orderId.$errors" :key="index">
          <div class="errorMsg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center pb-5" v-if="!isTrack">
      <button class="w-1/4 text-white btn btn-primary" @click="trackOrder">
        Track
      </button>
    </div>
    <div class="flex justify-center p-4 mt-4" v-if="showSpinner">
      <svg
        class="w-8 h-8 mr-3 -ml-1 text-black animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
    <div
      class="items-start max-w-2xl p-4 mx-auto mt-4 mb-4 card bg-ext-light-pink rounded-2xl"
      v-if="isTrack"
    >
      <span class="mb-3 text-sm font-medium text-black">Delivered On</span>
      <div class="pt-3 text-xl font-bold">{{ day }}</div>
      <div class="text-base font-semibold">{{ month }}</div>
      <div class="inline">
        <strong class="text-blue-700 text-8xl">{{ date }}</strong
        ><span class="text-year">{{ year }}</span>
      </div>
      <div class="text-sm text-neutral-900">Status:</div>
      <div class="flex inline pb-5">
        <svg
          data-v-3d91bd73=""
          xmlns="http://www.w3.org/2000/svg"
          class="mr-4 w-9 h-9"
          viewBox="0 0 24 24"
          role="img"
          aria-labelledby="circleOkIconTitle"
          stroke="#22c55e"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
          color="#22c55e"
        >
          <title data-v-3d91bd73="" id="circleOkIconTitle">OK</title>
          <polyline data-v-3d91bd73="" points="7 13 10 16 17 9"></polyline>
          <circle data-v-3d91bd73="" cx="12" cy="12" r="10"></circle>
        </svg>
        <span class="text-4xl">{{ status }}</span>
      </div>
      <hr class="w-full pb-5 rounded-md" />
      <div class="w-full">
        <div class="flex inline w-full">
          <span class="w-2/12 columns-1">
            <img src="../assets/delhivery-tracking.png" class="w-full h-1/2" />
          </span>
          <span class="w-8/12 text-sm font-bold columns-8">Delhivery</span>
          <span class="flex flex-col w-3/12 columns-3">
            <span class="text-sm text-stone-800">Tracking ID</span>
            <span class="text-sm text-blue-700">{{ trackingId }}</span></span
          >
        </div>
        <div class="pt-4">
          <ul>
            <li
              v-for="(data, index) in timeLineData"
              :key="index"
              class="flex w-full"
            >
              <div class="flex flex-col items-center w-3/12 columns-3">
                <span>{{ dateFormat(data.date) }}</span>
                <span>{{ timeFormat(data.date) }}</span>
              </div>
              <div
                class="relative flex flex-col items-start w-7/12 pl-10 columns-7"
                :id="index"
                :class="
                  index == timeLineDataLength - 1
                    ? 'border-0'
                    : 'border-l-2 border-dashed border-gray-300'
                "
              >
                <div
                  class="absolute w-6 h-6 rounded-full -left-3"
                  :class="index == 0 ? 'bg-green-500' : 'bg-gray-300'"
                ></div>
                <span><b>Activity:</b> {{ data.activity }}</span>
                <span class="pb-3"><b>Location:</b> {{ data.location }}</span>
                <hr class="w-full pb-3" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>
<script>
  import AppHeader from '@/components/AppHeader.vue';
  import AppFooter from '@/components/AppFooter.vue';
  import moment from 'moment';

  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import { validNonScriptText } from '../Helper/Helper.js';
  export default {
    components: {
      AppHeader,
      AppFooter,
    },

    data() {
      return {
        orderId: '',
        response: null,
        baseUrl: 'https://therarewindow.com/api/trackOrder/',
        day: null,
        month: null,
        year: null,
        date: null,
        isTrack: false,
        status: null,
        showSpinner: false,
        trackingId: null,
        timeLineData: null,
        timeLineDataLength: 0,
      };
    },
    validations() {
      return {
        orderId: {
          required,
          name_validation: {
            $validator: validNonScriptText,
            $message: 'Invalid text. please do not use "<script>" tag',
          },
        },
      };
    },
    setup() {
      return { v$: useVuelidate() };
    },
    mounted() {
      window.scrollTo(0, 0);
      this.deleteCookies();
    },
    methods: {
      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },
      async trackOrder() {
        this.deleteCookies();
        const result = await this.v$.$validate();
        if (!result) {
          return;
        } else {
          this.showSpinner = true;
          this.isTrack = false;
          var raw = JSON.stringify({
            uuid: this.orderId,
          });
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          const reqOpt = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
          };
          await fetch(this.baseUrl, reqOpt)
            .then((response) => response.text())
            .then((result) => {
              const response = JSON.parse(result);
              if (response.status === 200) {
                this.response = JSON.parse(response.data);
                this.showSpinner = false;
                this.isTrack = true;
                const date = new Date(
                  this.response.shipment_track.map((x) => x.edd).toString(),
                );
                this.day = moment.weekdays(date.getDay());
                this.month = date.toLocaleString('default', { month: 'long' });
                this.date = date.getDate();
                this.year = date.getFullYear();
                this.status = this.response.shipment_track
                  .map((x) => x.current_status)
                  .toString();
                this.trackingId = this.response.shipment_track
                  .map((x) => x.awb_code)
                  .toString();
                this.timeLineData = this.response.shipment_track_activities;
                this.timeLineDataLength = this.timeLineData.length;
              } else {
                this.showSpinner = false;
                this.$toast.open({
                  message: response.messages,
                  type: 'error',
                  duration: 5000,
                  dismissible: true,
                  position: 'bottom',
                });
              }
            })
            .catch((error) => {
              console.log('error', error);
              this.showSpinner = false;
              this.isTrack = false;
              this.$toast.open({
                message: error,
                type: 'error',
                duration: 5000,
                dismissible: true,
                position: 'bottom',
              });
            });
        }
      },
      dateFormat(date) {
        this.deleteCookies();
        return moment.utc(date).format('D MMM');
      },
      timeFormat(date) {
        this.deleteCookies();
        return moment.utc(date).format('h:mm A');
      },
    },
  };
</script>
