import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueToast from 'vue-toast-notification';
import './assets/css/tailwind.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueGtag from 'vue-gtag';
import { createHead } from 'unhead';
import * as jQuery from 'jquery/dist/jquery.min.js';
const head = createHead();
window.$ = jQuery;
createApp(App)
  .use(store)
  .use(VueGtag, { config: { id: 'G-E7M9EH6H2V' } })
  .use(router)
  .use(head)
  .use(VueToast)
  .mount('#app');
