<!-- Please remove this file from your project -->
<template>
  <div class="fixed inset-x-0 top-0 z-50 p-3 bg-dark md:py-3">
    <div class="container flex items-center justify-between max-w-6xl mx-auto">
      <router-link :to="{ name: 'home' }"
        ><app-logo class="block w-24 md:w-auto" logo-color="white"
      /></router-link>
      <ul class="flex text-white">
        <li class="px-2 md:px-5">
          <router-link v-if="$route.name != 'home'" :to="{ name: 'home' }"
            >Home</router-link
          >
        </li>
        <li
          class="px-2 cursor-pointer md:px-5"
          v-if="$route.name == 'home'"
          @click="$emit('showProducts', true)"
        >
          Products
        </li>
        <li class="px-2 md:px-5"><a href="#ourStory">About us</a></li>
        <li class="px-2 md:px-5">
          <router-link :to="{ name: 'b2b' }">B2B</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import AppLogo from './ui/AppLogo.vue';
  export default {
    name: 'AppHeader',
    components: {
      AppLogo,
    },
  };
</script>
