import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import B2BPage from '../views/B2BPage.vue';
import Checkout from '../views/CheckoutPage.vue';
import Confirmation from '../views/ConfirmationPage.vue';
import ProceedToPayment from '../views/ProceedToPayment.vue';
import StoryMap from '../views/StoryMap.vue';
import CelestialMap from '../views/CelestialMap.vue';
import PrivacyPolicy from '../views/policyPages/PrivacyPolicy.vue';
import RefundPolicy from '../views/policyPages/RefundPolicy.vue';
import ShippingPolicy from '../views/policyPages/ShippingPolicy.vue';
import TermsAndCondition from '../views/policyPages/TermsAndConditions.vue';
import JourneyMap from '../views/JourneyMap.vue';
import TrackingOrder from '../views/TrackingOrder.vue';
import Feedback from '../views/FeedbackPage.vue';
import ImageDrag from '../views/ImageDrag.vue';
import TheMapOfLove from '../views/TheMapOfLove.vue';
import BlogPage from '../views/BlogPage.vue';
import HomeDecorPiece from '../views/blog/HomeDecorPiece.vue';
import ThePowerOfWords from '../views/blog/ThePowerOfWords.vue';
import Faq from '../views/Faq.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/b2b',
    name: 'b2b',
    component: B2BPage,
  },
  {
    path: '/celestial-map',
    name: 'celestialMap',
    component: CelestialMap,
  },
  {
    path: '/story-map',
    name: 'storyMap',
    component: StoryMap,
  },
  {
    path: '/time-capsule-map',
    name: 'timeCapsuleMap',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "MomentMap" */ '../views/MomentMap.vue'),
  },
  {
    path: '/journey-map',
    name: 'journeyMap',
    component: JourneyMap,
  },
  {
    path: '/theMapOfLove',
    name: 'theMapOfLove',
    component: TheMapOfLove,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
  },
  {
    path: '/proceed-to-payment',
    name: 'proceedToPayment',
    component: ProceedToPayment,
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: Confirmation,
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/refund-policy',
    name: 'refundPolicy',
    component: RefundPolicy,
  },
  {
    path: '/shipping-policy',
    name: 'shippingPolicy',
    component: ShippingPolicy,
  },
  {
    path: '/terms-and-condition',
    name: 'termsAndCondition',
    component: TermsAndCondition,
  },
  {
    path: '/tracking-order',
    name: 'trackingOrder',
    component: TrackingOrder,
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback,
  },
  {
    path: '/image-drag',
    name: 'imageDrag',
    component: ImageDrag,
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogPage,
  },
  {
    path: '/home-decor-piece',
    component: HomeDecorPiece,
    name: 'homeDecorPiece',
  },
  {
    path: '/the-power-of-words',
    component: ThePowerOfWords,
    name: 'thePowerOfWords',
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error-404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
