<template>
  <div class="px-5 mb-5">
    <div class="mb-3 font-semibold">Type of map:</div>
    <radio-option-list
      :options="mapTypes"
      @updateSelect="updateMapType"
      :defaultSelect="selectedVal"
      name="type"
    />
  </div>
</template>

<script>
  import RadioOptionList from '../ui/RadioOptionList.vue';
  export default {
    components: { RadioOptionList },
    data() {
      return {
        selectedVal: '',
        mapTypes: [
          {
            val: 'storyMap',
            label: 'Story map',
          },
          {
            val: 'celestialMap',
            label: 'Celestial Map',
          },
        ],
      };
    },
    mounted() {
      this.selectedVal = this.$route.name;
    },
    methods: {
      updateMapType(type) {
        if (type == 'storyMap') {
          this.$router.push('/story-map');
        }
        if (type == 'celestialMap') {
          this.$router.push('/celestial-map');
        }
      },
    },
  };
</script>

<style></style>
