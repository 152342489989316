<template>
  <div
    v-if="show"
    class="fixed z-50 flex items-center justify-center w-screen h-screen bg-dark"
  >
    <div class="flex items-center justify-center mx-auto">
      <svg
        class="w-16 h-16 mr-3 -ml-1 animate-spin text-ext-yellow"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span
        class="pt-1 pl-5 ml-2 text-gray-400 uppercase border-l border-gray-600"
      >
        <app-logo logo-color="white" />
        Processing...
      </span>
    </div>
  </div>
</template>

<script>
  import AppLogo from '@/components/ui/AppLogo.vue';
  export default {
    components: { AppLogo },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    methods: {},
  };
</script>

<style></style>
