export default {
  setDate(state, payload) {
    let date = new Date(payload.date);
    state.design.date = date;
  },
  setTime(state, payload) {
    state.design.time = payload.time;
  },
  setStarBg(state, payload) {
    state.design.starBg = payload.starBg;
  },
  setName1(state, name1) {
    state.detail.name1 = name1;
  },
  setName2(state, name2) {
    state.detail.name2 = name2;
  },
  setEvent(state, event) {
    state.detail.event = event;
  },
  setDetailDate(state, date) {
    state.detail.date = date;
  },
  setDetailTime(state, time) {
    state.detail.time = time;
  },
  setLocation(state, location) {
    state.detail.location = location;
  },
  setQuote(state, quote) {
    state.detail.quote = quote;
  },
  setLeftImage(state, leftImage) {
    state.detail.leftImage = leftImage;
  },
  setRightImage(state, rightImage) {
    state.detail.rightImage = rightImage;
  },
  setCenterImage(state, centerImage) {
    state.detail.centerImage = centerImage;
  },
  setPrintingType(state, payload) {
    state.selectedOption.printingType = payload.name;
  },
  setFrameColor(state, payload) {
    state.selectedOption.frameColor.color = payload;
  },
  setGiftingMessage(state, payload) {
    state.selectedOption.giftingMessage = payload;
  },
  setGiftWrapping(state, payload) {
    state.selectedOption.giftWrapping = payload;
  },
  resetDetails(state) {
    state.detail.name1 = 'Name 1';
    state.detail.name2 = 'Name 2';
    state.detail.event = 'Event Name';
    state.detail.date = 'Date';
    state.detail.time = 'Time';
    state.detail.location = 'Location';
    state.detail.quote = 'A quote for that special moment';
    state.detail.leftImage = 'https://www.w3schools.com/tags/img_girl.jpg';
    state.detail.centerImage = 'https://www.w3schools.com/tags/img_girl.jpg';
    state.detail.rightImage = 'https://www.w3schools.com/tags/img_girl.jpg';
  },
};
