<template>
  <div class="px-5 mb-4">
    <div class="mb-3 font-semibold">Shape of maps:</div>
    <select-option :options="options" @selectedOptionHandler="updateMap" />
  </div>
</template>

<script>
  import SelectOption from '../ui/SelectOption.vue';
  export default {
    components: { SelectOption },
    data() {
      return {
        options: [
          {
            id: 1,
            image: 'iconSquare.svg',
            color: '',
            name: 'Square',
          },
          {
            id: 2,
            image: 'iconCircle.svg',
            color: '',
            name: 'Circle',
          },
          {
            id: 3,
            image: 'iconHeart.svg',
            color: '',
            name: 'Heart',
          },
        ],
      };
    },
    methods: {
      updateMap(selected) {
        this.$emit('updateMapShape', selected);
      },
    },
  };
</script>

<style></style>
