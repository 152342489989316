<template>
  <div class="px-5 mb-5">
    <div class="mb-3 font-semibold">Number of map:</div>
    <radio-option-list
      :options="numOfMaps"
      @updateSelect="updateNumOfMap"
      :defaultSelect="numOfMaps[0].val"
      name="num"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import RadioOptionList from '../ui/RadioOptionList.vue';
  export default {
    components: { RadioOptionList },
    data() {
      return {
        numOfMaps: [
          {
            val: 3,
            label: 'Triple map',
          },
          {
            val: 2,
            label: 'Double map',
          },
          {
            val: 1,
            label: 'Single map',
          },
        ],
      };
    },
    computed: {
      ...mapGetters('mapObj', ['getMap']),
    },
    methods: {
      // ...mapMutations("selected", ["setNumberOfMaps"]),
      updateNumOfMap(num) {
        this.$emit('updatedNum', num);
      },
    },
  };
</script>

<style></style>
