<template>
  <!-- <div class="fixed z-30 top-20">{{ center }}</div> -->
  <div
    class="map"
    :class="mapShape?.name == 'Square' && 'border border-black'"
    ref="mapContainer"
    id="map"
  >
    <map-shape :shape="mapShape" />
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { Map, Marker, NavigationControl } from 'maplibre-gl';
  import MapShape from '@/components/map/MapShape.vue';
  export default {
    name: 'MapComponent',
    components: { MapShape },
    props: {
      index: {
        type: Number,
        required: true,
      },
      api: {
        type: String,
        required: true,
      },
      markerIndex: {
        required: true,
      },
      markerType: {
        required: true,
      },
      marker: {
        type: Object,
        required: true,
      },
      markerLabel: {
        type: String,
        required: false,
      },
      center: {
        type: Object,
        required: true,
      },
      frameSize: {
        type: String,
      },
      numOfMapOnFrame: {
        type: Number,
      },
      mapShape: {
        type: Object,
      },
      showNav: {
        type: Boolean,
      },
    },

    data() {
      return {
        map: null,
        initialState: { lng: 77.1025, lat: 28.7041, zoom: 14 },
        mapMarker: null,
        markerIcon: document.createElement('div'),
        markerImg: document.createElement('img'),
        markerText: document.createElement('p'),
      };
    },
    computed: {
      ...mapGetters('mapObj', ['getMap']),
    },
    mounted() {
      this.map = new Map({
        container: this.$refs.mapContainer,
        style: this.api,
        center: [this.center.lon, this.center.lat],
        zoom: this.initialState.zoom,
        attributionControl: false,
      });

      this.showNav && this.map.addControl(new NavigationControl());
      this.setMapObj(this.map);
      this.markerImg.src = require(`../../assets/images/${this.marker.image}`);
      if (this.markerType == 'character') {
        this.markerImg.className = 'w-1/2 h-auto';
      } else {
        this.markerImg.className = 'w-1/5 h-auto';
      }
      this.mapMarker = new Marker(this.markerImg)
        .setLngLat([this.center.lon, this.center.lat])
        .addTo(this.getMap);

      this.map.once('idle', () => {
        this.setMapDataUrl(this.map.getCanvas().toDataURL('image/jpeg', 0.85));
      });
      this.setExtent({ ext: this.map.getBounds(), key: this.index });
      this.map.on('moveend', this.updateMarker);
      this.map.resize();
      this.map.on('zoomend', () => {
        this.setExtent({ ext: this.map.getBounds(), key: this.index });
        this.setZoom(this.map.getZoom());
      });
    },
    beforeUnmount() {
      this.map?.remove();
    },
    methods: {
      ...mapMutations('mapObj', [
        'setMapDataUrl',
        'setMapObj',
        'setExtent',
        'setZoom',
      ]),
      updateMarker() {
        this.mapMarker.setLngLat(this.map.getBounds().getCenter());
        this.setExtent({ ext: this.map.getBounds(), key: this.index });
      },
      resizeMap() {
        let map = this.map;
        setTimeout(function () {
          map.resize();
          this.updateMarker;
        }, 1000);
      },
    },
    watch: {
      api(newVal) {
        if (newVal) {
          this.map.setStyle(newVal);
        }
      },
      markerIndex(newVal) {
        if (newVal) {
          this.mapMarker.remove();
          this.markerImg.src = require(`../../assets/images/${this.marker.image}`);
          if (this.markerType == 'character') {
            this.markerImg.className = 'w-1/2 h-auto';
          } else {
            this.markerImg.className = 'w-1/5 h-auto';
          }
          this.mapMarker = new Marker(this.markerImg)
            .setLngLat(this.map.getCenter())
            .addTo(this.map);
        }
      },
      center: {
        handler(newVal) {
          if (newVal) {
            this.map.flyTo({ center: [newVal.lon, newVal.lat], zoom: 15 });
            this.mapMarker.setLngLat([newVal.lon, newVal.lat]);
            this.setExtent({ ext: this.map.getBounds(), key: this.index });
          }
        },
        deep: true,
      },
      frameSize(newVal) {
        if (newVal) {
          this.resizeMap();
          this.setExtent({ ext: this.map.getBounds(), key: this.index });
        }
      },
    },
  };
</script>

<style scoped>
  @import 'maplibre-gl/dist/maplibre-gl.css';

  .map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .watermark {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 999;
  }
  details.mapboxgl-ctrl-attrib.mapboxgl-compact-show,
  details.maplibregl-ctrl-attrib.maplibregl-compact-show {
    display: none !important;
  }
</style>
