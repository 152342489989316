export default {
  selectedOption(state) {
    return state;
  },
  imageTitle(state) {
    return state.title;
  },
  getDetailsText(state) {
    return state.details;
  },
  getDesign(state) {
    return state.design;
  },
  getBg(state) {
    return state.bg;
  },
  getNumOfMap(state) {
    return state.numOfMaps;
  },
  getMapShape(state) {
    return state.mapShape;
  },
  getStarMapDiv(state) {
    return state.starMapDiv;
  },
  getCelestial(state) {
    return state.celestial;
  },
  getCelestialConfig(state) {
    return state.celestialConfig;
  },
  getCelestialConfigReset(state) {
    return state.celestialConfigReset;
  },
  getAlternativeFont(state) {
    return state.details.alternativeFont;
  },
};
