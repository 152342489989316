export default {
  selectedOption(state) {
    return state.selectedOption;
  },
  selectedFrameSize(state) {
    return state.selectedOption.frameSize;
  },
  getNumOfMap(state) {
    return state.selectedOption.numOfMaps;
  },
  getDesign(state) {
    return state.design;
  },
  getMapShape(state) {
    return state.mapShape;
  },
  getDetail(state) {
    return state.detail;
  },
};
