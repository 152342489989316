<template>
  <app-header-home />
  <div class="container px-4 pt-20 mx-auto">
    <h1 class="text-xl font-bold text-center">DELIVERY POLICY</h1>
    <p class="pt-2 pb-2">Last updated date: June 05, 2022</p>
    <ol class="pt-6">
      <li class="pb-8">
        <h1 class="text-xl font-bold">1. GENERAL</h1>
        <p class="pt-3 pb-0 pl-6">
          a. This website with the URL of
          <a href="/" class="cursor-pointer">www.therarewindow.com</a>
          ("Website/Site") is operated by Rarewindow Private Limited
          <b>("We/Our/Us")</b>.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. We are committed to delivering the order placed by You within the
          given period and in accordance with this Delivery Policy
          <b>("Policy")</b>.
        </p>
        <p class="pt-3 pb-0 pl-6">
          c. Currently, We do offer worldwide shipping on all our products
          subject to the provisions of this Policy.
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. You are advised to read Our Terms and Conditions along with this
          Policy at
          <router-link
            :to="{ name: 'termsAndCondition' }"
            class="cursor-pointer"
          >
            www.therarewindow.com/terms-and-condition </router-link
          >.
        </p>
        <p class="pt-3 pb-0 pl-6">
          e. By using this website, You agree to be bound by the terms contained
          in this Policy without modification. If You do not agree to the terms
          contained in this Policy, You are advised not to transact on this
          website.
        </p>
        <p class="pt-3 pb-3 pl-6">
          f. All orders are subject to product availability. If an item is not
          in stock at the time you place your order, We will notify you and
          refund the total amount using the original method of payment.
        </p>
        <p class="pt-3 pb-3 pl-6">
          g. We make all commercially reasonable endeavours to ensure that the
          products are delivered to you in a timely manner.
        </p>
        <p class="pt-3 pb-3 pl-6">
          h. Please read this Policy before making a purchase, so that You
          understand Your rights as well as what You can expect from Us.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">2. DEFINITIONS</h1>
        <p class="pt-3 pb-0 pl-6">
          a. <b>"Customer"</b> means a person who buys any goods for
          consideration but does not include a person who purchases such goods
          with the purpose of reselling them.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. <b>"Website"</b> - means this website with the URL:
          <a href="/" class="cursor-pointer">www.therarewindow.com</a>.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">3. COST OF SHIPPING</h1>
        <p class="pt-3 pb-0 pl-6">
          a. Shipping and handling rates may vary based on product, packaging,
          size, volume, type and other considerations. The cost of shipment will
          be calculated at the check-out window.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">4. SHIPPING UPDATES</h1>
        <p class="pt-3 pb-0 pl-6">
          a. Once your order ships, you will get a notification with the details
          of the courier service provider and other relevant information through
          email or SMS.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. For more details, you can check the shipment details at:
        </p>
        <div class="pt-3 pb-0 pl-14">
          <p class="pt-2 pb-2">
            Click on the 'Track your Order' section, enter the Order ID you must
            have received on email after placing the order and click on submit.
            You will receive the shipping current status of your order.
          </p>
        </div>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">5. HANDLING TIME</h1>
        <p class="pt-3 pb-0 pl-6">
          a. It usually takes 3-5 business days to process and dispatch the
          products once the order is placed with us.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. We thrive to process and dispatch your products at the earliest.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">6. TIME FOR SHIPPING</h1>
        <p class="pt-3 pb-0 pl-6">
          a. Delivery time is dependent on the product ordered and the location
          of delivery. Approximate delivery is specified on the product details
          or will be shown at the check-out window.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. The delivery time is indicative and can vary due to external
          factors.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">7. INTERNATIONAL SHIPPING</h1>
        <p class="pt-3 pb-0 pl-6">
          a. We do provide shipments to all the countries.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. Shipping charges for Our international customers will be calculated
          during check out depending on the order value and other dimensions of
          Your order.
        </p>
        <p class="pt-3 pb-0 pl-6">
          c. Standard international shipment may take approximately 15 Business
          Days unless otherwise specifically mentioned in the product details.
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. International customers assume responsibility for any duties and/or
          taxes that may be incurred from customs.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">8. OTHER RULES</h1>
        <p class="pt-3 pb-0 pl-6">
          a. The shipping address cannot be changed once the order has been
          placed on the Website.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. While We shall strive to ship all your ordered products together,
          this may not always be possible due to product characteristics, or
          availability.
        </p>
        <p class="pt-3 pb-0 pl-6">
          c. Partial refunds will not be entertained.
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. We endeavour to engage logistic partners, employees, agents with
          the highest standards and ethics, you agree and acknowledge that the
          actions, inactions of the delivery individuals are not in our control,
          and it is not possible for us to monitor and observe each delivery
          executive and We shall not be liable for any action or inaction from
          the part of logistic partners. Any disputes between You and logistics
          partners shall be settled between you and the logistics partner.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">9. RESTRICTIONS</h1>
        <p class="pt-3 pb-0 pl-6">
          a. Some items may not be eligible to be delivered to all geographical
          locations. You will be notified of the restricted items at the
          check-out page or before that.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">10. MISSING OR LOST PACKAGES</h1>
        <p class="pt-3 pb-0 pl-9">
          a. If the products are lost during transit, we will offer a free
          reshipment or a refund.
        </p>
        <p class="pt-3 pb-0 pl-9">
          b. If the order does not make it to its destination and is delayed at
          the courier office, the Customers are responsible for contacting the
          Courier service provider before approaching Us.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">11. WRONG OR INCOMPLETE ADDRESS</h1>
        <p class="pt-3 pb-0 pl-9">
          a. You shall ensure that all information that is submitted by You to
          us on the platform is true, complete, accurate and sufficient to
          identify the actual place of delivery.
        </p>
        <p class="pt-3 pb-0 pl-9">
          b. If You have entered the wrong shipping information or contact
          details, it might cause a delay or missed delivery and you will not be
          eligible for a reshipment or a refund on such transaction.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">12. RETURN AND REFUND</h1>
        <p class="pt-3 pb-0 pl-9">
          a. The return process of a product may be subject to additional terms
          depending on the nature and category of the product. Any such
          additional terms may be specified under the Return and Refund Policy
          or be intimated at the time of purchase of the product. For more
          details You can check our Refund Policy at:
          <router-link :to="{ name: 'refundPolicy' }" class="cursor-pointer"
            >www.therarewindow.com/refund-policy.</router-link
          >
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">13. YOUR DATA</h1>
        <p class="pt-3 pb-0 pl-9">
          a. The privacy of your data supplied to Us during the shipping
          procedure is also governed by our privacy policy, which can be
          accessed under the following link:
          <router-link
            :to="{ name: 'privacyPolicy' }"
            class="italic cursor-pointer"
            >www.therarewindow.com/privacy-policy</router-link
          >.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">14. CHANGES TO THIS POLICY</h1>
        <p class="pt-3 pb-0 pl-9">
          a. Please note that We may from time to time change the terms of this
          Policy and every time You wish to use this website, please check the
          Policy to ensure You understand the terms and conditions that apply at
          that time.
        </p>
        <p class="pt-3 pb-0 pl-9">
          b. If you do not wish to accept the revised Policy, you should not
          continue to use the Services. If you continue to use the Services
          after the date on which the changes, come into effect, Your use of the
          Services indicates your agreement to be bound by the new Policy.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">15. FORCE MAJEURE</h1>
        <p class="pt-3 pb-0 pl-9">
          We shall not be considered in breach of guarantee or terms of service
          and shall not be liable to the Customer for any cessation,
          interruption, or delay in the performance of its obligations by reason
          beyond Our control including natural disasters, pandemics, fire, an
          act of God or public enemy, famine, plague, the action of the court or
          public authority, change in law, explosion, war, terrorism, armed
          conflict, labour strike, lockout, boycott or similar event beyond our
          reasonable control, whether foreseen or unforeseen.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">16. CONTACT US</h1>
        <p class="pt-3 pb-0 pl-9">
          For any feedback, concern, or query, You may please reach out to Us on
          the contact details below.
        </p>
        <p class="pt-3 pb-0 pl-9">
          Customer Care:
          <a href="mailto:support@therarewindow.com" class="cursor-pointer"
            >support@therarewindow.com</a
          >
        </p>
      </li>
    </ol>
  </div>
  <app-footer />
</template>

<script>
  import AppHeaderHome from '@/components/AppHeaderHome.vue';
  import AppFooter from '@/components/AppFooter.vue';
  export default {
    components: {
      AppHeaderHome,
      AppFooter,
    },
    mounted() {
      window.scrollTo(0, 0);
      if (
        document.cookie.includes('csrftoken') ||
        document.cookie.includes('sessionid')
      ) {
        const date = new Date(
          new Date().setTime(new Date().getUTCSeconds() + 1),
        );
        document.cookie = `csrftoken=;expires=${date};`;
        document.cookie = `sessionid=;expires=${date};`;
      }
    },
  };
</script>
