<template>
  <div class="px-5 mb-4">
    <div class="mb-3 font-semibold">Map styling:</div>
    <select-option :options="options" @selectedOptionHandler="updateMap" />
  </div>
</template>

<script>
  import SelectOption from '../ui/SelectOption.vue';
  export default {
    components: { SelectOption },
    data() {
      return {
        options: [
          {
            id: 0,
            image: 'mapStyling/style1.jpg',
            color: '#F7F7F7',
            name: '',
            mapTileApi:
              'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
            contentBgColor: '#E2D6BA',
            contentColor: '#000000',
          },
          {
            id: 1,
            image: 'mapStyling/style2.jpg',
            color: '#F7F7F7',
            name: '',
            mapTileApi:
              'https://api.maptiler.com/maps/767a7b0e-b4b4-40a8-9c64-95dd96dfbc7d/style.json?key=lOUgqb5qcG1eDUoQSBbD',
            contentBgColor: '#E2D6BA',
            contentColor: '#000000',
          },
          {
            id: 2,
            image: 'mapStyling/style3.jpg',
            color: '#F7F7F7',
            name: '',
            mapTileApi:
              'https://api.maptiler.com/maps/1a9b1678-8d3d-451e-bea8-3999b7653c03/style.json?key=lOUgqb5qcG1eDUoQSBbD',
            contentBgColor: '#E2D6BA',
            contentColor: '#000000',
          },
          {
            id: 3,
            image: 'mapStyling/style4.jpg',
            color: '#F7F7F7',
            name: '',
            mapTileApi:
              'https://api.maptiler.com/maps/72b160ef-7141-407b-9733-49656876a2e9/style.json?key=lOUgqb5qcG1eDUoQSBbD',
            contentBgColor: '#E2D6BA',
            contentColor: '#000000',
          },
          {
            id: 4,
            image: 'mapStyling/style5.jpg',
            color: '#F7F7F7',
            name: '',
            mapTileApi:
              'https://api.maptiler.com/maps/aa0e5e72-28a0-4112-b4fa-c4868e463b32/style.json?key=lOUgqb5qcG1eDUoQSBbD',
            contentBgColor: '#E2D6BA',
            contentColor: '#000000',
          },
          {
            id: 5,
            image: 'mapStyling/style6.jpg',
            color: '#F7F7F7',
            name: '',
            mapTileApi:
              'https://api.maptiler.com/maps/3268f700-33a6-41e9-88e3-95a696d64fe0/style.json?key=13NAUPgM8t0KWKl79Fm7',
            contentBgColor: '#E2D6BA',
            contentColor: '#000000',
          },
          {
            id: 6,
            image: 'mapStyling/style7.jpg',
            color: '#F7F7F7',
            name: '',
            mapTileApi:
              'https://api.maptiler.com/maps/9c75281f-0e9e-4268-9797-c220578be3e4/style.json?key=13NAUPgM8t0KWKl79Fm7',
            contentBgColor: '#E2D6BA',
            contentColor: '#000000',
          },
          {
            id: 7,
            image: 'mapStyling/style8.jpg',
            color: '#F7F7F7',
            name: '',
            mapTileApi:
              'https://api.maptiler.com/maps/ec73ad6c-b78e-49bd-8c51-24c8b029d5b5/style.json?key=13NAUPgM8t0KWKl79Fm7',
            contentBgColor: '#E2D6BA',
            contentColor: '#000000',
          },
        ],
        mapStyle: 'setMapStyle',
      };
    },
    methods: {
      updateMap(selected) {
        this.$emit('updateStyle', selected);
      },
    },
  };
</script>

<style></style>
