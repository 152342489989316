<template>
  <div class="px-5 mb-4">
    <div class="mb-3 font-semibold">Map marker:</div>
    <div class="overflow-y-auto max-h-64">
      <select-option :options="options" @selectedOptionHandler="updateMap" />
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import SelectOption from '../ui/SelectOption.vue';
  export default {
    components: { SelectOption },
    data() {
      return {
        options: [
          // {
          //   id: 1,
          //   image: 'mapMarker/character/RakshaBandhan/A.png',
          //   color: '#F7F7F7',
          //   name: null,
          //   type: 'character',
          // },
          // {
          //   id: 2,
          //   image: 'mapMarker/character/RakshaBandhan/B.png',
          //   color: '#F7F7F7',
          //   name: null,
          //   type: 'character',
          // },
          // {
          //   id: 3,
          //   image: 'mapMarker/character/independenceDay/A.png',
          //   color: '#F7F7F7',
          //   name: null,
          //   type: 'character',
          // },
          // {
          //   id: 4,
          //   image: 'mapMarker/character/independenceDay/B.png',
          //   color: '#F7F7F7',
          //   name: null,
          //   type: 'character',
          // },
          // {
          //   id: 5,
          //   image: 'mapMarker/character/independenceDay/C.png',
          //   color: '#F7F7F7',
          //   name: null,
          //   type: 'character',
          // },
          {
            id: 1,
            image: 'mapMarker/character/FrienshipFriends/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 2,
            image: 'mapMarker/character/FrienshipFriends/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 3,
            image: 'mapMarker/character/FrienshipFriends/C.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 4,
            image: 'mapMarker/character/GirlProposingBoy/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 5,
            image: 'mapMarker/character/GirlProposingBoy/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 6,
            image: 'mapMarker/character/BoyProposingGirl/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 7,
            image: 'mapMarker/character/BoyProposingGirl/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 8,
            image: 'mapMarker/character/inLove/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 9,
            image: 'mapMarker/character/inLove/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 10,
            image: 'mapMarker/character/WeddingIndianStyle/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 11,
            image: 'mapMarker/character/WeddingIndianStyle/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 12,
            image: 'mapMarker/character/WeddingWesternStyle/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 13,
            image: 'mapMarker/character/WeddingWesternStyle/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 14,
            image: 'mapMarker/character/HavingAbaby/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 15,
            image: 'mapMarker/character/HavingAbaby/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 16,
            image: 'mapMarker/character/officeSendoff/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 17,
            image: 'mapMarker/character/officeSendoff/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 18,
            image: 'mapMarker/character/BoyGraduating/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 19,
            image: 'mapMarker/character/BoyGraduating/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 20,
            image: 'mapMarker/character/GirlGraduating/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 21,
            image: 'mapMarker/character/GirlGraduating/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'character',
          },
          {
            id: 22,
            image: 'mapMarker/icons/anniversary/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 23,
            image: 'mapMarker/icons/anniversary/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 24,
            image: 'mapMarker/icons/bday/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 25,
            image: 'mapMarker/icons/bday/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 26,
            image: 'mapMarker/icons/Cheers/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 27,
            image: 'mapMarker/icons/Cheers/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 28,
            image: 'mapMarker/icons/Coffee/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 29,
            image: 'mapMarker/icons/Coffee/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 30,
            image: 'mapMarker/icons/Coffee/C.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 31,
            image: 'mapMarker/icons/Coffee/D.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 32,
            image: 'mapMarker/icons/cycling/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 33,
            image: 'mapMarker/icons/cycling/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 34,
            image: 'mapMarker/icons/Dog/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 35,
            image: 'mapMarker/icons/Dog/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 36,
            image: 'mapMarker/icons/engagementRing/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 37,
            image: 'mapMarker/icons/engagementRing/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 38,
            image: 'mapMarker/icons/firelit/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 39,
            image: 'mapMarker/icons/firelit/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 40,
            image: 'mapMarker/icons/heart/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 41,
            image: 'mapMarker/icons/heart/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 42,
            image: 'mapMarker/icons/heart/C.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 43,
            image: 'mapMarker/icons/hiking/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 44,
            image: 'mapMarker/icons/hiking/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 45,
            image: 'mapMarker/icons/hiking/C.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 46,
            image: 'mapMarker/icons/hiking/D.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 47,
            image: 'mapMarker/icons/holiday/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 48,
            image: 'mapMarker/icons/holiday/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 49,
            image: 'mapMarker/icons/Music/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 50,
            image: 'mapMarker/icons/Music/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 51,
            image: 'mapMarker/icons/Music/C.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 52,
            image: 'mapMarker/icons/newBabyBorn/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 53,
            image: 'mapMarker/icons/newBabyBorn/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 54,
            image: 'mapMarker/icons/NormlPin/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 55,
            image: 'mapMarker/icons/NormlPin/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 56,
            image: 'mapMarker/icons/OfficeMeeting/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 57,
            image: 'mapMarker/icons/OfficeMeeting/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 58,
            image: 'mapMarker/icons/Party/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 59,
            image: 'mapMarker/icons/Party/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 60,
            image: 'mapMarker/icons/Party/C.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 61,
            image: 'mapMarker/icons/Party/D.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 62,
            image: 'mapMarker/icons/photograhy/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 63,
            image: 'mapMarker/icons/photograhy/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 64,
            image: 'mapMarker/icons/sleepBed/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 65,
            image: 'mapMarker/icons/sleepBed/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 66,
            image: 'mapMarker/icons/traveling/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 67,
            image: 'mapMarker/icons/traveling/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 68,
            image: 'mapMarker/icons/traveling/C.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 69,
            image: 'mapMarker/icons/traveling/D.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 70,
            image: 'mapMarker/icons/newCar/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 71,
            image: 'mapMarker/icons/newCar/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 72,
            image: 'mapMarker/icons/newCar/C.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 73,
            image: 'mapMarker/icons/newCar/D.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 74,
            image: 'mapMarker/icons/newCar/E.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 75,
            image: 'mapMarker/icons/newCar/F.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 76,
            image: 'mapMarker/icons/newHome/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 77,
            image: 'mapMarker/icons/newHome/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 78,
            image: 'mapMarker/icons/newHome/C.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 79,
            image: 'mapMarker/icons/newHome/D.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 80,
            image: 'mapMarker/icons/scooty/A.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
          {
            id: 81,
            image: 'mapMarker/icons/scooty/B.png',
            color: '#F7F7F7',
            name: null,
            type: 'normal',
          },
        ],
      };
    },
    created() {
      if (this.$route.name == 'storyMap') {
        this.options = this.options.filter(
          (option) => option.type != 'character',
        );
      }
    },
    methods: {
      ...mapMutations('mapObj', ['setMapMarker']),
      updateMap(selected) {
        this.$emit('updateMarker', selected);
      },
    },
  };
</script>

<style></style>
