<template>
  <div class="px-5 mb-4">
    <div class="font-semibold">Printing type:</div>
    <div
      class="mb-3 text-xs underline cursor-pointer text-ext-gray"
      @click="showModal = !showModal"
    >
      Know more about Printing type
    </div>
    <select-option
      :options="options"
      @selectedOptionHandler="updatePrintingType"
    />
    <base-modal
      white
      :show-modal="showModal"
      @closeModal="showModal = !showModal"
    >
      <div class="my-5">
        <h2 class="mb-5 text-3xl font-bold">Know about printing type</h2>
        <div class="py-8 overflow-y-auto">
          <div class="flex mx-auto w-max">
            <div class="mx-3" v-if="country == 'India'">
              <h2 class="text-2xl font-semibold">Single level printing</h2>
              <img
                src="@/assets/images/printType/2D.jpg"
                class="max-w-xs my-3 rounded-lg max-w-56"
                alt=""
              />
            </div>
            <div class="mx-3" v-if="!tcm && country == 'India'">
              <h2 class="text-2xl font-semibold">Double level printing</h2>
              <img
                src="@/assets/images/printType/3D.jpg"
                class="max-w-xs my-3 rounded-lg"
                alt=""
              />
            </div>
            <div class="mx-3">
              <h2 class="text-2xl font-semibold">Digital printing</h2>
              <img
                src="@/assets/images/printType/DIGITAL.jpg"
                class="max-w-xs my-3 rounded-lg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
  import SelectOption from '../ui/SelectOption.vue';
  import BaseModal from '../ui/BaseModal.vue';
  import { mapGetters } from 'vuex';
  export default {
    components: { SelectOption, BaseModal },
    data() {
      return {
        showModal: false,
        tcm: false,
        options: [
          {
            id: 0,
            image: 'printType/printSingleLavel.svg',
            name: 'Single Level',
          },
          {
            id: 1,
            image: 'printType/printDoubleLavel.svg',
            name: 'Double Level',
          },
          {
            id: 2,
            image: 'printType/printDigitalCopy.svg',
            name: 'Digital Copy',
          },
        ],
        country: '',
      };
    },
    computed: {
      ...mapGetters('mapObj', ['getCountry']),
    },
    created() {
      if (
        this.$route.name == 'timeCapsuleMap' ||
        this.$route.name == 'theMapOfLove'
      ) {
        this.tcm = true;
        this.options = this.options.filter(
          (option) => option.name != 'Double Level',
        );
      }
      setTimeout(() => {
        this.country = this.getCountry;
        if (this.country != 'India' && this.country != undefined) {
          this.options = this.options.filter(
            (option) => option.name == 'Digital Copy',
          );
          this.updatePrintingType(
            this.options.filter((option) => option.name == 'Digital Copy'),
          );
        }
      }, 1000);
    },
    methods: {
      updatePrintingType(type) {
        this.$emit('selectedPrintingType', type);
      },
    },
  };
</script>

<style></style>
