<template>
  <div class="min-h-screen bg-ext-light-pink">
    <AppHeader />
    <app-loader :show="loader" />
    <div class="absolute bottom-0 w-full lg:flex top-16">
      <div
        class="self-center flex-grow hidden lg:block overflow-auto h-full"
        v-if="!mobileView"
      >
        <CelestialPreview :num-of-map="selectedOption.numOfMaps" />
      </div>
      <div
        class="flex-shrink bg-white relative xl:w-[512px] lg:w-[380px] sideBar"
      >
        <ul
          class="fixed z-10 grid w-full grid-cols-5 mb-6 text-sm font-semibold text-center bg-white lg:grid-cols-4 sm:text-base shadow-ext-bottom-2 lg:relative lg:w-auto"
        >
          <li
            class="py-2 cursor-pointer lg:hidden"
            :class="
              previewSelected
                ? 'border-b-2 border-ext-yellow bg-ext-yellow text-white'
                : 'bg-dark text-white'
            "
            @click="selectedTab = 'preview'"
          >
            <img
              src="../assets/images/eyeIcon.svg"
              alt=""
              class="mx-auto mb-2"
            />
            Preview*
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              structureSelected || lastActiveTab == 'structure'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              :src="
                tabStructureValidated
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/iconStructure.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Structure
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              designSelected || lastActiveTab == 'design'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              :src="
                tabDesignValidated
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/tabDesign.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Design
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              detailsSelected || lastActiveTab == 'details'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              :src="
                tabDetailsValidated
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/tabDetails.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Details
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              formatSelected || lastActiveTab == 'format'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              src="../assets/images/tabFormat.svg"
              alt=""
              class="mx-auto mb-1"
            />
            Format
          </li>
        </ul>
        <div
          ref="options"
          class="absolute inset-x-0 bottom-0 pb-16 overflow-y-auto top-24"
          :class="previewSelected ? 'hidden' : ''"
        >
          <div v-show="structureSelected">
            <map-type />
            <!-- <number-of-maps @updated-num="(num) => setNumberOfMaps(num)" /> -->
            <map-shape @update-map-shape="(shape) => setMapShape(shape)" />
            <!-- <choose-bg @update-bg="(bg) => setBg(bg)" /> -->
          </div>
          <div v-show="designSelected">
            <base-card
              class="m-5"
              v-for="index in getNumOfMap"
              :key="index"
              :open="index == 1 ? true : false"
            >
              <template #icon
                ><img src="@/assets/images/marker.svg"
              /></template>
              <template #title>Map {{ index }} Design</template>
              <template #body>
                <location-input
                  ref="locationInput"
                  @update-center="
                    (center) => {
                      if (center) {
                        updateLocation(center, index);
                        locationAdded = true;
                      } else {
                        locationAdded = false;
                      }
                    }
                  "
                />
                <div class="px-5 mb-8">
                  <div class="mb-2 font-semibold">Date*</div>
                  <input
                    type="date"
                    class="form-control"
                    :class="
                      focus == 'date' && date[index - 1] == null
                        ? 'errorInput'
                        : ''
                    "
                    placeholder="Your title"
                    v-model="date[index - 1]"
                    @input="setDate({ index: index, date: date[index - 1] })"
                  />
                </div>
                <div class="px-5 mb-8">
                  <div class="mb-2 font-semibold">Time*</div>
                  <input
                    type="time"
                    class="form-control"
                    :class="
                      focus == 'time' && time[index - 1] == null
                        ? 'errorInput'
                        : ''
                    "
                    placeholder="Your title"
                    v-model="time[index - 1]"
                    @input="setTime({ index: index, time: time[index - 1] })"
                  />
                </div>
                <star-map-styling
                  @update-star-map-style="
                    (styling) => updateStarMapStyle(styling, index)
                  "
                />
                <div class="px-5 mb-8">
                  <div class="mb-3 font-semibold">Show Star Name :</div>
                  <radio-option-list
                    :options="starNameVisibility"
                    @updateSelect="updateStarNameVisibility($event, index)"
                    :defaultSelect="starNameVisibility[0].val"
                    name="starMapNameVisibility"
                  />
                </div>
              </template>
            </base-card>
          </div>
          <div v-show="detailsSelected">
            <div class="px-5 mb-8">
              <div class="pb-5">
                <input
                  id="af"
                  type="checkbox"
                  class="inputCheckSq"
                  v-model="alternativeFont"
                  @change="setAlternativeFont(alternativeFont)"
                />
                <label for="af"> Use alternative font</label>
              </div>
              <div class="mb-2 font-semibold">Header*</div>
              <input
                type="text"
                class="form-control"
                :class="
                  focus == 'header' && headerText == '' ? 'errorInput' : ''
                "
                placeholder="Your title"
                maxlength="40"
                v-model="headerText"
                @input="setDetailsHeaderText(headerText)"
              />
              <small class="text-xs text-ext-gray">Max 40 characters</small>
            </div>
            <base-card
              class="m-5"
              open
              v-for="index in getNumOfMap"
              :key="index"
            >
              <template #icon
                ><img src="@/assets/images/marker.svg"
              /></template>
              <template #title>Map {{ index }} Details</template>
              <template #body>
                <div class="px-5 mb-8">
                  <div class="mb-2 font-semibold">Title*</div>
                  <input
                    type="text"
                    class="form-control"
                    :class="
                      focus == 'title' && title[index - 1] == ''
                        ? 'errorInput'
                        : ''
                    "
                    placeholder="Your title"
                    maxlength="25"
                    v-model="title[index - 1]"
                    @input="setTitle({ index: index, title: title[index - 1] })"
                  />
                  <small class="text-xs text-ext-gray">Max 25 characters</small>
                </div>
                <div class="px-5 mb-8">
                  <div class="mb-2 font-semibold">Subtext*</div>
                  <input
                    type="text"
                    class="form-control"
                    :class="
                      focus == 'subtitle' && subTitle[index - 1] == ''
                        ? 'errorInput'
                        : ''
                    "
                    placeholder="Subtext about your memory"
                    maxlength="45"
                    v-model="subTitle[index - 1]"
                    @input="
                      setSubTitle({ index: index, sub: subTitle[index - 1] })
                    "
                  />
                  <small class="text-xs text-ext-gray">Max 45 characters</small>
                </div>
              </template>
            </base-card>
            <div class="px-5 mb-8">
              <div class="mb-2 font-semibold">Footer*</div>
              <input
                type="text"
                class="form-control"
                :class="
                  focus == 'footer' && footerText == '' ? 'errorInput' : ''
                "
                placeholder="Subtext about your memory"
                maxlength="40"
                v-model="footerText"
                @input="setDetailsFooterText(footerText)"
              />
              <small class="text-xs text-ext-gray">Max 40 characters</small>
            </div>
          </div>
          <div v-show="formatSelected">
            <frame-color
              v-if="printType != 'Digital Copy'"
              @update-frame-color="
                (color) => {
                  setFrameColor(color.color);
                  checkPrice('celestialMap');
                }
              "
            />
            <printing-type
              @selected-printing-type="(type) => updatePrintType(type)"
            />
            <div class="px-5 mb-8" v-if="printType != 'Digital Copy'">
              <div class="mb-2 font-semibold">Gifting message</div>
              <input
                type="text"
                class="form-control"
                maxlength="100"
                placeholder="Write your message"
                v-model="giftingMessage"
                @change="setGiftingMessage(giftingMessage)"
              />
              <div class="my-2 text-xs text-ext-gray">
                Extra charges would apply for gifting
              </div>
              <input
                type="checkbox"
                class="mr-2 inputCheckSq"
                v-model="giftWrapping"
                @change="
                  setGiftWrapping(giftWrapping);
                  checkPrice('celestialMap');
                "
              />
              Add gift wrapping
            </div>
          </div>
        </div>
        <div
          class="fixed inset-x-0 bottom-0 z-10 flex items-center justify-between p-2 bg-white shadow-sm sm:p-4 lg:absolute"
        >
          <span class="text-2xl font-semibold"
            ><span v-if="formatSelected">₹ {{ getPrice }}</span></span
          >
          <div class="flex items-center">
            <button
              v-if="!structureSelected && !previewSelected"
              class="mr-2 btn-outline"
              @click="back"
            >
              <img
                src="@/assets/images/ArrowRight.svg"
                alt="arrow"
                class="mr-1 rotate-180"
              />
              Previous
            </button>
            <button
              v-if="!previewSelected"
              class="btn-primary"
              :class="formatSelected && getPrice == 0 ? 'hidden' : ''"
              @click="next"
            >
              {{ ctaBtnText }}
              <img
                src="@/assets/images/ArrowRight.svg"
                alt="arrow"
                class="ml-1"
              />
            </button>
            <button
              v-else
              class="btn-primary"
              @click="selectedTab = 'structure'"
            >
              Customize
              <img
                src="@/assets/images/ArrowRight.svg"
                alt="arrow"
                class="ml-1"
              />
            </button>
          </div>
        </div>
        <template v-if="mobileView">
          <div
            v-if="previewSelected"
            class="self-center flex-grow block px-8 pt-20 lg:hidden overflow-auto h-full pb-24"
          >
            <CelestialPreview :num-of-map="selectedOption.numOfMaps" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  // import { mapState } from "vuex";
  import MapType from '../components/productOptions/MapType.vue';
  // import NumberOfMaps from "../components/productOptions/NumberOfMaps.vue";
  import MapShape from '../components/productOptions/MapShape.vue';
  // import ChooseBg from '../components/productOptions/ChooseBg.vue';
  import LocationInput from '../components/productOptions/LocationInput.vue';
  import AppHeader from '../components/AppHeader.vue';
  import AppLoader from '../components/AppLoader.vue';
  import StarMapStyling from '../components/productOptions/StarMapStyling.vue';
  import FrameColor from '../components/productOptions/FrameColor.vue';
  import PrintingType from '../components/productOptions/PrintingType.vue';
  import CelestialPreview from '../components/CelestialPreview.vue';
  import { mapGetters, mapMutations, mapActions } from 'vuex';
  import BaseCard from '@/components/ui/BaseCard.vue';
  import RadioOptionList from '../components/ui/RadioOptionList.vue';
  import moment from 'moment';
  import { useHead } from 'unhead';

  export default {
    components: {
      AppHeader,
      BaseCard,
      MapType,
      AppLoader,
      MapShape,
      // ChooseBg,
      LocationInput,
      StarMapStyling,
      FrameColor,
      PrintingType,
      CelestialPreview,
      RadioOptionList,
    },
    data() {
      return {
        selectedTab: 'preview',
        date: [null, null, null],
        time: [null, null, null],
        title: ['', null, null],
        subTitle: ['', null, null],
        giftingMessage: '',
        giftWrapping: false,
        printType: null,
        price: null,
        alternativeFont: false,
        headerText: '',
        footerText: '',
        loader: false,
        locationAdded: false,
        focus: '',
        lastActiveTab: 'structure',
        tabStructureValidated: false,
        tabDesignValidated: false,
        tabDetailsValidated: false,
        starNameVisibility: [
          {
            val: false,
            label: 'No',
          },
          {
            val: true,
            label: 'Yes',
          },
        ],
      };
    },
    computed: {
      ...mapGetters('celestialMap', ['selectedOption', 'getNumOfMap']),
      ...mapGetters('checkout', ['getPrice', 'getImages']),
      ...mapGetters('mapObj', ['getLocationName', 'getCountry']),
      structureSelected() {
        return this.selectedTab === 'structure';
      },
      designSelected() {
        return this.selectedTab === 'design';
      },
      detailsSelected() {
        return this.selectedTab === 'details';
      },
      formatSelected() {
        return this.selectedTab === 'format';
      },
      previewSelected() {
        return this.selectedTab === 'preview';
      },
      mobileView() {
        return window.screen.width < 1024;
      },
      ctaBtnText() {
        return this.structureSelected ||
          this.designSelected ||
          this.detailsSelected
          ? 'Next'
          : 'Checkout';
      },
    },
    async created() {
      // document.title = 'Celestial Map';
      this.mobileView
        ? (this.selectedTab = 'preview')
        : (this.selectedTab = 'structure');
      if (!this.getCountry) await this.fetchCountry();
      setTimeout(() => {
        if (this.getCountry != 'India') {
          const option = {
            id: 2,
            image: 'printType/printDigitalCopy.svg',
            name: 'Digital Copy',
          };
          this.updatePrintType(option);
        }
      }, 1000);
    },
    mounted() {
      useHead({
        title: 'Diy Personalized Star Map | Celestial Map | The Rare Window',
        meta: [
          {
            name: 'title',
            content:
              'Best Personalized Gifts - Celestial Maps | The Rare Window',
          },
          {
            name: 'description',
            content:
              'Create a DIY personalized star map for your partner. This art with a custom celestial map is truly one of the most beautiful things to give as a gift to your special one.',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: 'https://therarewindow.com/celestial-map',
          },
        ],
      });
      this.deleteCookies();
    },
    beforeUnmount() {
      this.setCountry('');
    },
    methods: {
      ...mapMutations('celestialMap', [
        'setImageTitle',
        'setImageSubTitle',
        'setGiftingMessage',
        'setGiftWrapping',
        'setNumberOfMaps',
        'setBg',
        'setMapShape',
        'setTitle',
        'setSubTitle',
        'setDetailsHeaderText',
        'setDetailsFooterText',
        'setDate',
        'setTime',
        'setStarMapStyle',
        'setFrameColor',
        'setPrintingType',
        'setAlternativeFont',
        'setStarMapStarNameVisibility',
      ]),
      ...mapMutations('mapObj', ['setCenter', 'setLocationName', 'setCountry']),
      ...mapMutations('checkout', ['setProductDetails', 'setNumOfMaps']),
      ...mapActions('celestialMap', ['createImg']),
      ...mapActions('checkout', ['checkPrice']),
      ...mapActions('mapObj', ['fetchCountry']),

      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },

      updateStarMapStyle(style, index) {
        this.deleteCookies();
        this.setStarMapStyle({
          starBg: style.contentBgColor,
          key: index,
        });
      },
      updateStarNameVisibility(visibility, index) {
        this.deleteCookies();
        this.setStarMapStarNameVisibility({
          propername: visibility,
          key: index,
        });
      },
      updateLocation(center, key) {
        this.deleteCookies();
        this.setCenter({
          center: { lon: center.lon, lat: center.lat },
          key: key,
        });
        this.setLocationName({ locationName: center.locationName, key: key });
      },
      updatePrintType(type) {
        this.deleteCookies();
        this.setPrintingType(type);
        this.printType = type.name;
        if (this.printType == 'Digital Copy') {
          this.giftWrapping = false;
          this.setGiftWrapping(this.giftWrapping);
          this.checkPrice('celestialMap');
        } else {
          this.checkPrice('celestialMap');
        }
      },
      back() {
        this.deleteCookies();
        if (this.structureSelected) {
          this.selectedTab = 'preview';
        }
        if (this.designSelected) {
          this.selectedTab = 'structure';
        }
        if (this.detailsSelected) {
          this.selectedTab = 'design';
        }
        if (this.formatSelected) {
          this.selectedTab = 'details';
        }
      },
      async next() {
        this.deleteCookies();
        if (this.structureSelected) {
          this.$refs.options.scrollTop = 0;
          this.selectedTab = 'design';
          this.tabStructureValidated = true;
        } else if (this.designSelected) {
          let time = moment(this.selectedOption.design[0].date).format(
            'MMMM Do, YYYY',
          );
          this.subTitle[0] =
            this.getLocationName[0].split(',').shift() + ' | ' + time;
          this.setSubTitle({
            index: 1,
            sub: this.getLocationName[0].split(',').shift() + ' | ' + time,
          });
          if (this.locationAdded) {
            if (this.date[0]) {
              if (this.time[0]) {
                this.$refs.options.scrollTop = 0;
                this.selectedTab = 'details';
                this.tabDesignValidated = true;
              } else {
                this.focus = 'time';
                this.$toast.open({
                  message: 'Please select time.',
                  type: 'error',
                  duration: 5000,
                  dismissible: true,
                  position: 'top',
                });
                this.tabDesignValidated = false;
              }
            } else {
              this.focus = 'date';
              this.$toast.open({
                message: 'Please select date.',
                type: 'error',
                duration: 5000,
                dismissible: true,
                position: 'top',
              });
            }
          } else {
            this.$refs.locationInput[0].makeFocus();
            this.$toast.open({
              message: 'Please add location.',
              type: 'error',
              duration: 5000,
              dismissible: true,
              position: 'top',
            });
          }
        } else if (this.detailsSelected) {
          if (this.headerText) {
            if (this.title[0]) {
              if (this.subTitle[0]) {
                if (this.footerText) {
                  this.$refs.options.scrollTop = 0;
                  this.selectedTab = 'format';
                  this.tabDetailsValidated = true;
                } else {
                  this.focus = 'footer';
                  this.$toast.open({
                    message: 'Please add footer text.',
                    type: 'error',
                    duration: 5000,
                    dismissible: true,
                    position: 'top',
                  });
                  this.tabDetailsValidated = false;
                }
              } else {
                this.focus = 'subtitle';
                this.$toast.open({
                  message: 'Please add subtitle text.',
                  type: 'error',
                  duration: 5000,
                  dismissible: true,
                  position: 'top',
                });
              }
            } else {
              this.focus = 'title';
              this.$toast.open({
                message: 'Please add title text.',
                type: 'error',
                duration: 5000,
                dismissible: true,
                position: 'top',
              });
            }
          } else {
            this.focus = 'header';
            this.$toast.open({
              message: 'Please add header text.',
              type: 'error',
              duration: 5000,
              dismissible: true,
              position: 'top',
            });
          }
        } else if (this.formatSelected) {
          this.loader = true;
          this.selectedTab = 'preview';
          this.$refs.options.scrollTop = 0;
          this.checkout();
          setTimeout(async () => {
            await this.createImg().then((x) => {
              if (x) this.$router.push('/checkout');
            });
          }, 4000);
        }
      },
      checkout() {
        this.deleteCookies();
        this.setProductDetails({
          mapType: this.getNumOfMap,
          productName: this.selectedOption.productName,
          frameSize: '8x12',
          frameColor: this.selectedOption.frameColor.color,
          printingType: this.selectedOption.printingType,
          giftingMsg: this.giftingMessage,
          giftWrap: this.giftWrapping,
          blobImage: this.getImages,
        });
        this.setNumOfMaps(this.getNumOfMap);
      },
    },
    watch: {
      formatSelected(newVal) {
        if (newVal) {
          this.checkPrice('celestialMap');
        }
      },
      selectedTab(newVal) {
        if (newVal != 'preview') {
          this.lastActiveTab = newVal;
        }
      },
    },
  };
</script>

<style scoped>
  .sideBar {
    height: calc(100vh - 65px);
  }
</style>
