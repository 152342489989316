<template>
  <app-header />
  <app-loader :show="loader" />
  <div class="container max-w-6xl mx-auto">
    <div class="grid-cols-12 gap-8 lg:grid">
      <div class="col-span-8 px-3 pt-20">
        <base-text type="h4">Checkout Information</base-text>
        <base-card open className="mb-5">
          <template #icon><img src="@/assets/images/mail.svg" /></template>
          <template #title>Contact Information</template>
          <template #body>
            <div class="p-5">
              Email:
              <input
                type="email"
                placeholder="Enter email"
                class="my-2 form-control"
                :class="{ error: v$.form.email.$errors.length }"
                v-model="v$.form.email.$model"
              />
              <div v-for="(error, index) of v$.form.email.$errors" :key="index">
                <div class="errorMsg">{{ error.$message }}</div>
              </div>
              <!-- <input
                type="checkbox"
                id="nl"
                class="mr-2"
                v-model="form.send_news_and_Offers"
              />
              <label for="nl">Email me with news and offers</label> -->
            </div>
          </template>
        </base-card>

        <base-card
          open
          className="mb-5"
          v-if="getProductDetails.printingType !== 'Digital Copy'"
        >
          <template #icon><img src="@/assets/images/marker.svg" /></template>
          <template #title>Shipping address</template>
          <template #body>
            <div class="p-5">
              <div class="grid-cols-8 gap-6 md:grid">
                <div class="col-span-8">
                  Country/Region:
                  <select
                    class="my-2 form-control"
                    :class="{ error: v$.form.shipping.country.$errors.length }"
                    v-model="v$.form.shipping.country.$model"
                    disabled
                  >
                    <option
                      v-for="country in countries"
                      :key="country.code"
                      :value="country.name"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                  <div
                    v-for="(error, index) of v$.form.shipping.country.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  First name
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter your first name"
                    :class="{
                      error: v$.form.shipping.firstName.$errors.length,
                    }"
                    v-model="v$.form.shipping.firstName.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.shipping.firstName.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  Last name
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter your last name"
                    :class="{ error: v$.form.shipping.lastName.$errors.length }"
                    v-model="v$.form.shipping.lastName.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.shipping.lastName.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-8">
                  Address:
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter your location"
                    :class="{ error: v$.form.shipping.address.$errors.length }"
                    v-model="v$.form.shipping.address.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.shipping.address.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  Apartment, Suite, etc. (Optional)
                  <input
                    type="text"
                    class="my-2 form-control"
                    :class="{
                      error: v$.form.shipping.apartment.$errors.length,
                    }"
                    placeholder="House no, apartment etc."
                    v-model="v$.form.shipping.apartment.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.shipping.apartment.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  PIN Code
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="PIN Code"
                    :class="{ error: v$.form.shipping.pin.$errors.length }"
                    v-model="v$.form.shipping.pin.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.shipping.pin.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  City
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter City"
                    :class="{ error: v$.form.shipping.city.$errors.length }"
                    v-model="v$.form.shipping.city.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.shipping.city.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  State
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter State"
                    :class="{ error: v$.form.shipping.state.$errors.length }"
                    v-model="v$.form.shipping.state.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.shipping.state.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-8">
                  Phone no.
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="8174XXXXXX"
                    :class="{ error: v$.form.shipping.phone.$errors.length }"
                    v-model="v$.form.shipping.phone.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.shipping.phone.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </base-card>

        <div
          class="p-5"
          v-if="getProductDetails.printingType !== 'Digital Copy'"
        >
          <input
            type="checkbox"
            id="Biilling"
            class="mr-2 inputCheckSq"
            v-model="sameBillingAddress"
            @change="makeSameBillingAddress"
          />
          <label for="Biilling"
            >Use same as shipping address for billing address</label
          >
        </div>
        <base-card open v-if="!sameBillingAddress" className="mb-5">
          <template #icon><img src="@/assets/images/marker.svg" /></template>
          <template #title>Billing address</template>
          <template #body>
            <div class="p-5">
              <div class="grid-cols-8 gap-6 md:grid">
                <div class="col-span-8">
                  Country/Region:
                  <select
                    class="my-2 form-control"
                    :class="{ error: v$.form.billing.country.$errors.length }"
                    v-model="v$.form.billing.country.$model"
                    disabled
                  >
                    <option
                      v-for="country in countries"
                      :key="country.code"
                      :value="country.name"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                  <div
                    v-for="(error, index) of v$.form.billing.country.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  First name
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter your first name"
                    :class="{ error: v$.form.billing.firstName.$errors.length }"
                    v-model="v$.form.billing.firstName.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.billing.firstName.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  Last name
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter your last name"
                    :class="{ error: v$.form.billing.lastName.$errors.length }"
                    v-model="v$.form.billing.lastName.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.billing.lastName.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-8">
                  Address:
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter your location"
                    :class="{ error: v$.form.billing.address.$errors.length }"
                    v-model="v$.form.billing.address.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.billing.address.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  Apartment, Suite, etc. (Optional)
                  <input
                    type="text"
                    class="my-2 form-control"
                    :class="{ error: v$.form.billing.apartment.$errors.length }"
                    placeholder="House no, apartment etc."
                    v-model="v$.form.billing.apartment.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.billing.apartment.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  PIN Code
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="PIN Code"
                    :class="{ error: v$.form.billing.pin.$errors.length }"
                    v-model="v$.form.billing.pin.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.billing.pin.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  City
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter City"
                    :class="{ error: v$.form.billing.city.$errors.length }"
                    v-model="v$.form.billing.city.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.billing.city.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-4">
                  State
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="Enter State"
                    :class="{ error: v$.form.billing.state.$errors.length }"
                    v-model="v$.form.billing.state.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.billing.state.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-span-8">
                  Phone no.
                  <input
                    type="text"
                    class="my-2 form-control"
                    placeholder="8174XXXXXX"
                    :class="{ error: v$.form.billing.phone.$errors.length }"
                    v-model="v$.form.billing.phone.$model"
                  />
                  <div
                    v-for="(error, index) of v$.form.billing.phone.$errors"
                    :key="index"
                  >
                    <div class="errorMsg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </base-card>
      </div>
      <div class="relative col-span-4 lg:pt-16">
        <div class="w-full lg:absolute">
          <div class="p-6 bg-ext-light-pink lg:fixed xl:max-w-sm xl:w-full">
            <p class="uppercase">Your Order</p>
            <hr class="my-4" />
            <div class="grid grid-cols-4 gap-3">
              <div>
                <img :src="getPreviewImg" class="pointer-events-none" alt="" />
              </div>
              <div class="col-span-3">
                <base-text type="h5" add-class="uppercase">
                  {{ getProductDetails.productName }}
                </base-text>
                <div class="mb-2 -mt-2 text-ext-gray">Quantity: 1</div>
                <!-- <router-link :to="getProductEditUrl"> Edit </router-link> -->
              </div>
            </div>
            <hr class="my-4" />
            <div class="grid grid-cols-4 gap-1">
              <input
                type="text"
                class="col-span-3 uppercase bg-white form-control"
                v-model="couponCode"
                placeholder="Discount Code"
              />
              <button
                class="rounded-lg btn-primary p-3"
                @click.prevent="applyCoupon"
              >
                Apply
              </button>
            </div>
            <div v-if="errorCouponCode">
              <span class="capitalize errorMsg">{{ errorCouponCode }}</span>
            </div>
            <hr class="my-4" />
            <div v-if="discount != 0" class="flex justify-between mb-4">
              <span class="text-ext-gray">Discount:</span>
              <strong> {{ discount }}%</strong>
            </div>
            <div class="flex justify-between mb-4">
              <span class="text-ext-gray">Subtotal:</span>
              <strong>₹ {{ getPrice }}</strong>
            </div>
            <div class="flex justify-between mb-4">
              <span class="text-ext-gray">Shipping:</span>
              <strong>Free</strong>
            </div>
            <hr class="my-4" />
            <div class="flex justify-between mb-4">
              <div>
                <strong>Total:</strong>
                <div class="text-sm text-ext-gray">Inclusive of all taxes</div>
              </div>
              <strong class="text-3xl">₹ {{ getPrice }}</strong>
            </div>
            <base-button class="w-full" @click.prevent="continuePayment"
              >Continue to payment</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppHeader from '@/components/AppHeader.vue';
  import AppLoader from '../components/AppLoader.vue';
  import BaseText from '@/components/ui/BaseText.vue';
  import BaseCard from '@/components/ui/BaseCard.vue';
  import BaseButton from '@/components/ui/BaseButton.vue';
  import useVuelidate from '@vuelidate/core';
  import {
    required,
    email,
    numeric,
    minLength,
    maxLength,
  } from '@vuelidate/validators';
  import { validName, validNonScriptText } from '../Helper/Helper.js';
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    components: {
      AppHeader,
      AppLoader,
      BaseText,
      BaseCard,
      BaseButton,
    },
    data() {
      return {
        loader: false,
        countries: null,
        sameBillingAddress: true,
        couponCode: '',
        discount: 0,
        errorCouponCode: null,
        form: {
          email: '',
          send_news_and_Offers: false,
          shipping: {
            country: 'India',
            firstName: '',
            lastName: '',
            address: '',
            apartment: '',
            pin: '',
            city: '',
            state: '',
            phone: '',
          },
          billing: {
            country: 'India',
            firstName: '',
            lastName: '',
            address: '',
            apartment: '',
            pin: '',
            city: '',
            state: '',
            phone: '',
          },
        },
      };
    },
    validations() {
      return {
        form: {
          email: {
            required,
            email,
          },
          shipping: {
            country: {
              required,
            },
            firstName: {
              required,
              name_validation: {
                $validator: validName,
                $message:
                  'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
              },
            },
            lastName: {
              required,
              name_validation: {
                $validator: validName,
                $message:
                  'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
              },
            },
            phone: {
              required,
              numeric,
              min: minLength(10),
              max: maxLength(10),
            },
            address: {
              required,
              name_validation: {
                $validator: validNonScriptText,
                $message: 'Invalid text. please do not use "<script>" tag',
              },
            },
            apartment: {
              name_validation: {
                $validator: validNonScriptText,
                $message: 'Invalid text. please do not use "<script>" tag',
              },
            },
            pin: {
              numeric,
              required,
            },
            city: {
              required,
              name_validation: {
                $validator: validNonScriptText,
                $message: 'Invalid text. please do not use "<script>" tag',
              },
            },
            state: {
              required,
              name_validation: {
                $validator: validNonScriptText,
                $message: 'Invalid text. please do not use "<script>" tag',
              },
            },
          },
          billing: {
            country: {
              required,
            },
            firstName: {
              required,
              name_validation: {
                $validator: validName,
                $message:
                  'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
              },
            },
            lastName: {
              required,
              name_validation: {
                $validator: validName,
                $message:
                  'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
              },
            },
            phone: {
              required,
              numeric,
              min: minLength(10),
              max: maxLength(10),
            },
            address: {
              required,
              name_validation: {
                $validator: validNonScriptText,
                $message: 'Invalid text. please do not use "<script>" tag',
              },
            },
            apartment: {
              name_validation: {
                $validator: validNonScriptText,
                $message: 'Invalid text. please do not use "<script>" tag',
              },
            },
            pin: {
              numeric,
              required,
            },
            city: {
              required,
              name_validation: {
                $validator: validNonScriptText,
                $message: 'Invalid text. please do not use "<script>" tag',
              },
            },
            state: {
              required,
              name_validation: {
                $validator: validNonScriptText,
                $message: 'Invalid text. please do not use "<script>" tag',
              },
            },
          },
        },
      };
    },
    setup() {
      return { v$: useVuelidate() };
    },
    computed: {
      ...mapGetters('checkout', [
        'getPrice',
        'getProductDetails',
        'getImages',
        'getPreviewImg',
        'getNumOfMaps',
      ]),
    },
    created() {
      if (this.getProductDetails.productName == '') {
        this.$router.push('/');
      }
    },
    mounted() {
      this.getCountries();
      this.deleteCookies();
    },
    methods: {
      ...mapMutations('checkout', [
        'setShippingDetails',
        'setBillingDetails',
        'setUserEmail',
        'setSend_news_and_Offers',
        'setPrice',
        'setProductName',
      ]),
      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },
      async applyCoupon() {
        this.deleteCookies();
        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        let raw = JSON.stringify({
          coupon_code: this.couponCode,
          productName: this.getProductDetails.productName,
          frameSize: this.getProductDetails.frameSize,
          giftWrap: this.getProductDetails.giftWrap,
          printingType: this.getProductDetails.printingType,
          mapType:
            this.getProductDetails.productName == 'JourneyMap'
              ? 1
              : this.getNumOfMaps,
          frameColor: this.getProductDetails.frameColor,
        });
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };
        await fetch(
          'https://therarewindow.com/api/calculatePrice/',
          requestOptions,
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.data) {
              this.setPrice(result.data.price);
              this.errorCouponCode = '';
              this.discount = result.data.discount;
            } else {
              this.errorCouponCode = result.messages;
            }
          })
          .catch((err) => console.log(err));
      },
      async getCountries() {
        this.deleteCookies();
        await fetch('/config/countries.json')
          .then((res) => res.json())
          .then((data) => (this.countries = data));
      },
      async continuePayment() {
        this.deleteCookies();
        if (this.getProductDetails.printingType == 'Digital Copy') {
          this.v$.form.shipping.country.$model = 'India';
          this.v$.form.shipping.firstName.$model = 'Test';
          this.v$.form.shipping.lastName.$model = 'Test';
          this.v$.form.shipping.address.$model = 'test';
          this.v$.form.shipping.pin.$model = '394210';
          this.v$.form.shipping.city.$model = 'Surat';
          this.v$.form.shipping.state.$model = 'Gujarat';
          this.v$.form.shipping.phone.$model = '7986451320';
        }
        if (this.sameBillingAddress) {
          this.makeSameBillingAddress();
        }
        const result = await this.v$.$validate();
        if (!result) {
          return;
        } else {
          this.loader = true;
          this.getProductDetails.mapType =
            this.getProductDetails.productName == 'Journey Map' ||
            this.getProductDetails.productName == 'map of love'
              ? 1
              : this.getProductDetails.mapType;
          let raw = JSON.stringify({
            send_news_and_Offers: this.form.send_news_and_Offers,
            total_amount: this.getPrice,
            discount_code: this.couponCode,
            quantity: 1,
            email: this.form.email,
            product: this.getProductDetails,
            final_image: this.getImages.final,
            base_image: this.getImages.base,
            map_image: this.getImages.map,
            shipping_address: this.form.shipping,
            billing_address: this.form.billing,
          });
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
          };
          await fetch(
            'https://therarewindow.com/api/createOrder/',
            requestOptions,
          ).then((res) => {
            if (res.ok) {
              res.json().then((data) => {
                this.loader = false;
                this.setProductName('');
                this.$router.push(
                  '/proceed-to-payment?orderId=' + data.data.uuid,
                );
              });
            }
          });
        }
      },
      makeSameBillingAddress() {
        this.deleteCookies();
        if (this.sameBillingAddress) {
          this.form.billing = this.form.shipping;
        } else {
          this.form.billing = {
            firstName: '',
            lastName: '',
            address: '',
            apartment: '',
            pin: '',
            city: '',
            state: '',
            phone: '',
            country: 'India',
          };
        }
      },
    },
  };
</script>

<style></style>
