<template>
  <h1 class="mb-4 text-2xl font-bold md:text-3xl flex justify-center">
    Sky Map
  </h1>
  <span class="mx-auto" :style="{ backgroundColor: getBg.color }">
    <div
      ref="product"
      class="w-10/12 preview"
      :class="
        numOfMap > 1
          ? 'frame12x8'
          : 'frame8x12 md:w-[300px] lg:w-[380px] xl:w-[400px]'
      "
      :style="{ outlineColor: selectedOption.frameColor.color }"
    >
      <div
        class="relative grid h-full grid-flow-col p-1 grid-rows-8"
        :style="{ backgroundColor: getBg.color }"
      >
        <img
          src="@/assets/images/preview.svg"
          alt="preview.svg"
          class="absolute z-30 h-full pointer-events-none opacity-20"
        />
        <div
          class="grid content-center text-center"
          :class="
            !getAlternativeFont
              ? 'font-ext-GreatVibes text-base md:text-xl lg:text-2xl'
              : 'font-bold text-base md:text-xl lg:text-2xl mb-1'
          "
        >
          {{ getDetailsText.header }}
        </div>
        <div class="flex row-span-6">
          <div
            v-for="num in numOfMap"
            :key="num"
            class="relative grid w-full h-full grid-flow-col grid-rows-5 px-2"
          >
            <!-- {{ getMapMarker[num - 1] }} -->
            <div class="row-span-4 map-wrap" ref="wrap">
              <div class="shape" :class="shapeClass">
                <StarMap
                  :mapShape="getMapShape"
                  :location="getCenter[num - 1]"
                  :date="getDesign[num - 1].date"
                  :time="getDesign[num - 1].time"
                  :color="getDesign[num - 1].starBg"
                  :mapId="'celestial-map' + num"
                  :propername="getDesign[num - 1].propername"
                />
              </div>
            </div>
            <div class="grid textOnMap conntent-center">
              <div
                id="textScriptPTbold"
                :class="
                  !getAlternativeFont
                    ? 'font-ext-GreatVibes text-[10px] md:text-xl mb-2'
                    : 'text-[9px] md:text-xl mb-3'
                "
              >
                {{ getDetailsText.text[num - 1].main }}
              </div>
              <div class="md:text-xs text-[7px]">
                {{ getDetailsText.text[num - 1].sub }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="grid content-center text-center"
          :class="
            !getAlternativeFont
              ? 'font-ext-GreatVibes text-sm md:text-base lg:text-xl'
              : 'font-bold text-sm md:text-base lg:text-xl mb-1'
          "
        >
          {{ getDetailsText.footer }}
        </div>
      </div>
    </div>
    <p class="mt-5 text-xs text-center opacity-60">
      *Preview is adjusted as per your screen size, actual print would be to the
      size.
    </p>
    <h2 class="my-3 text-base font-bold md:text-xl text-center">
      Create A DIY Personalized Star Map for Your Partner
    </h2>
    <div class="text-sm w-10/12 md:w-[300px] lg:w-[380px] xl:w-[700px] mx-auto">
      <p>
        Our beautiful <b>Location Star Map</b> can depict the stars' unique
        alignment in a location and time of your choosing. For instance, your
        birthday, first kiss, wedding day, or the birth of your child.
      </p>
      <p class="mt-2">
        Each <b>Sky Map Date Location</b> is made to order and personalized with
        your text. Our <b>Star Map of My Location</b> is scientifically
        validated, capturing the vivid details of your memories and allowing you
        to revisit them every day. Capture the night sky at a stunning occasion
        you'll never forget. We align the stars for that unforgettable occasion.
      </p>
      <p class="mt-2">
        Make your own customized and one-of-a-kind star map print. Choose your
        preferred design, color, and wording to capture your most wonderful
        moment. Make your own night sky print with your unique touch.
      </p>
      <p class="my-2">
        Our <b>First Date Star Map</b> is an exquisite present for couples,
        Valentine's Day, when we first met, girlfriend
        <a href="https://therarewindow.com/story-map" class="underline"
          >gift map</a
        >, boyfriend gift, weddings, anniversaries, and so on. We can show the
        stars on any particular day to present this a unique sky map.
      </p>
    </div>
  </span>
</template>

<script>
  import { mapGetters } from 'vuex';
  import StarMap from './map/StarMap.vue';

  export default {
    components: {
      StarMap,
    },
    props: {
      numOfMap: {
        type: Number,
        require: true,
      },
    },
    data() {
      return {
        capturing: false,
        setImage: null,
        blob: null,
        wrapDiv: null,
        shapeClass: 'h-full',
      };
    },
    computed: {
      ...mapGetters('celestialMap', [
        'selectedOption',
        'getBg',
        'getMapShape',
        'getDetailsText',
        'getDesign',
        'getAlternativeFont',
      ]),
      ...mapGetters('mapObj', ['getCenter']),
    },
    mounted() {
      this.wrapDiv = this.$refs.wrap;
      this.checkRatio();
    },
    methods: {
      checkRatio() {
        if (this.wrapDiv) {
          if (this.wrapDiv[0].clientWidth > this.wrapDiv[0].clientHeight) {
            this.shapeClass = 'h-full';
          } else {
            this.shapeClass = 'w-full';
          }
        } else {
          this.shapeClass = '';
        }
      },
    },
    watch: {
      numOfMap(newVal) {
        if (newVal) {
          setTimeout(this.checkRatio, 999);
        }
      },
    },
  };
</script>

<style scoped>
  .map-wrap {
    position: relative;
    flex-grow: 2;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px;
  }
  .shape {
    position: relative;
    aspect-ratio: 1 / 1;
  }
  .shape.Square {
    border-radius: 10px;
    overflow: hidden;
  }
  /* .shapeTest {
  position: absolute;
} */
  .shape.Circle {
    border-radius: 50%;
    overflow: hidden;
  }

  .textOnMap {
    position: relative;
    z-index: 10;
    text-align: center;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .preview {
    position: relative;
    margin: auto;
    outline: 6px solid transparent;
  }
  .frame12x8 {
    aspect-ratio: 12 / 8;
  }
  .frame8x12 {
    aspect-ratio: 8 / 12;
  }
  .frame8x8 {
    aspect-ratio: 8 / 8;
  }
  @media screen and (min-width: 480px) {
    .preview {
      outline: 12px solid transparent;
    }
  }
</style>
