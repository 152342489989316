export default {
  async checkPrice(context, product) {
    const productDetails = context.rootGetters[product + '/selectedOption'];
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      productName: productDetails.productName,
      frameSize: product == 'journeyMap' ? '8x12' : productDetails.frameSize,
      frameColor: productDetails.frameColor.color,
      printingType: productDetails.printingType,
      giftWrap: productDetails.giftWrapping,
      mapType:
        product == 'journeyMap' || product == 'theMapOfLove'
          ? 1
          : productDetails.numOfMaps,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    await fetch('https://therarewindow.com/api/calculatePrice/', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        context.commit('setPrice', result.data.price);
        // context.dispatch("checkout/setPrice", result.data.price);
      })
      .catch((error) => console.log('error: ', error));
  },
};
