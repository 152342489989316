import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Map, Marker } from 'maplibre-gl';

export default {
  async createImg(context) {
    let isImageAdded = false;
    let frameWidth = 0;
    let frameHeight = 0;
    let orientation = '';
    let selectedOptions = context.rootGetters['journeyMap/selectedOption'];
    let mapDetails = context.rootGetters['journeyMap/getDetails'];

    frameWidth = 1152;
    frameHeight = 768;
    orientation = 'landscape';

    const doc = new jsPDF({
      orientation: orientation,
      unit: 'px',
      format: [frameWidth, frameHeight],
    });
    let docWidth = doc.internal.pageSize.getWidth();
    let docHeight = doc.internal.pageSize.getHeight();

    let frame = document.createElement('div');
    frame.style.height = docHeight + 'px';
    frame.style.width = docWidth + 'px';
    frame.className = 'p-10 printFrame';
    frame.style.backgroundColor = selectedOptions.bg.color;
    frame.id = 'printFrame';
    document.body.appendChild(frame);

    let parent = document.createElement('div');
    parent.id = 'parent';
    parent.className = 'relative grid h-full grid-flow-col p-1 grid-rows-8';

    let header = document.createElement('div');
    header.className =
      'flex items-center justify-center text-5xl -mt-14 Content';
    header.appendChild(document.createTextNode(mapDetails.header));
    parent.appendChild(header);

    let main = document.createElement('div');
    main.className = 'flex justify-between px-2';
    main.id = 'main';

    let leftWrapper = document.createElement('div');
    leftWrapper.id = 'leftWrapper';
    leftWrapper.className = 'w-2/5';

    let leftShapeParts = document.createElement('div');
    leftShapeParts.className = 'flex items-center';
    leftShapeParts.id = 'left-shapePart';
    leftWrapper.appendChild(leftShapeParts);

    let leftFooterWrapper = document.createElement('div');
    leftFooterWrapper.className =
      'flex isolate -mt-8 px-6 w-full justify-between uppercase text-sm leading-none text-center z-20 relative';
    leftFooterWrapper.id = 'left-FooterWrapper';
    leftWrapper.appendChild(leftFooterWrapper);

    let centerWrapper = document.createElement('div');
    centerWrapper.className = 'flex flex-col justify-between w-1/5';
    centerWrapper.id = 'centerWrapper';

    let centerHeader = document.createElement('div');
    centerHeader.className = 'relative mt-2';
    centerHeader.id = 'centerHeader';
    centerWrapper.appendChild(centerHeader);

    let centerShapeParts = document.createElement('div');
    centerShapeParts.className = 'mt-[212px] z-20 relative';
    centerShapeParts.id = 'center-shapePart';
    centerWrapper.appendChild(centerShapeParts);

    let rightWrapper = document.createElement('div');
    rightWrapper.id = 'rightWrapper';
    rightWrapper.className = 'w-2/5';

    let rightShapeParts = document.createElement('div');
    rightShapeParts.className = 'flex items-center';
    rightShapeParts.id = 'right-shapePart';
    rightWrapper.appendChild(rightShapeParts);

    let rightFooterWrapper = document.createElement('div');
    rightFooterWrapper.className =
      'flex isolate -mt-8 px-6 w-full justify-between uppercase text-sm leading-none text-center z-20 relative';
    rightFooterWrapper.id = 'right-FooterWrapper';
    rightWrapper.appendChild(rightFooterWrapper);

    main.appendChild(leftWrapper);
    main.appendChild(centerWrapper);
    main.appendChild(rightWrapper);

    parent.appendChild(main);
    frame.appendChild(parent);

    let payload = {
      i: 0,
      docWidth: docWidth,
    };
    const data = await context.dispatch('captureMap', payload);
    if (data) return (isImageAdded = true);
    else return isImageAdded;
  },
  async captureMap(context, { i, docWidth }) {
    return new Promise((resolve, reject) => {
      const mapMarker = context.rootGetters['mapObj/getMapMarker'];
      const center = context.rootGetters['mapObj/getCenter'];
      const zoom = context.rootGetters['mapObj/getZoom'];
      const mapStyle = context.rootGetters['mapObj/getMapStyle'];
      let selectedOptions = context.rootGetters['journeyMap/selectedOption'];
      let mapDetails = context.rootGetters['journeyMap/getDetails'];
      const hidden = document.createElement('div');
      hidden.className = 'hidden-map';
      document.body.appendChild(hidden);

      const container = document.createElement('div');
      if (i == 0 || i == 1 || i == 3 || i == 4) {
        container.style.width = '210px';
        container.style.height = '420px';
      }
      if (i == 2) {
        container.style.width = '210px';
        container.style.height = '210px';
      }
      hidden.appendChild(container);

      let main_content = document.createElement('div');
      if (i == 1 || i == 0) {
        main_content.className = 'relative';
        document.getElementById('left-shapePart').appendChild(main_content);
      }

      if (i == 3 || i == 4) {
        main_content.className = 'relative';
        document.getElementById('right-shapePart').appendChild(main_content);
      }

      if (i == 2) {
        main_content.className = 'relative w-full mx-auto';
        document.getElementById('center-shapePart').appendChild(main_content);
        let subHeader = document.createElement('p');
        subHeader.className =
          'absolute top-0 left-1/2 -translate-x-1/2 w-full capitalize text-center font-normal leading-none text-xl max-w-[20ch] mx-auto';
        document.getElementById('centerHeader').appendChild(subHeader);
      }

      let mapWrapper = document.createElement('div');
      mapWrapper.className = 'w-full h-full absolute';
      main_content.appendChild(mapWrapper);

      let img = document.createElement('img');
      img.className = 'w-full h-full border Print';
      mapWrapper.appendChild(img);

      let shape = document.createElement('div');
      shape.className = 'relative pointer-events-none z-10';
      main_content.appendChild(shape);

      let footerWrapper = document.createElement('div');
      let centerFooter = document.createElement('div');

      if (i == 0) {
        let svg = document.getElementById('left-heart-1').cloneNode(true);
        svg.id = `shape${i}`;
        shape.appendChild(svg);

        footerWrapper.className = 'flex-1 flex flex-col items-center';
        let footerImage = document.createElement('img');
        footerImage.src = mapDetails.text[i].image;
        footerImage.className =
          'w-14 h-14 rounded-full bg-slate-300 bg-cover bg-center';
        footerWrapper.appendChild(footerImage);

        let footerText = document.createElement('div');
        footerText.className = 'mt-1';

        let footerMainText = document.createElement('div');
        footerMainText.className =
          'max-w-[12ch] break-words mb-1 font-semibold text-base';
        footerMainText.innerText = mapDetails.text[i].main;
        footerText.appendChild(footerMainText);

        let footerSubText = document.createElement('div');
        footerSubText.className = 'max-w-[15ch] break-words text-xs';
        footerSubText.innerText = mapDetails.text[i].sub;
        footerText.appendChild(footerSubText);

        footerWrapper.appendChild(footerText);
        document
          .getElementById('left-FooterWrapper')
          .appendChild(footerWrapper);
      }

      if (i == 1) {
        let svg = document.getElementById('left-heart-2').cloneNode(true);
        svg.id = `shape${i}`;
        shape.appendChild(svg);

        footerWrapper.className = 'flex-1 flex flex-col items-center';
        let footerImage = document.createElement('img');
        footerImage.src = mapDetails.text[i].image;
        footerImage.className =
          'w-14 h-14 rounded-full bg-slate-300 bg-cover bg-center';
        footerWrapper.appendChild(footerImage);

        let footerText = document.createElement('div');
        footerText.className = 'mt-1';

        let footerMainText = document.createElement('div');
        footerMainText.className =
          'max-w-[12ch] break-words mb-1 font-semibold text-base';
        footerMainText.innerText = mapDetails.text[i].main;
        footerText.appendChild(footerMainText);

        let footerSubText = document.createElement('div');
        footerSubText.className = 'max-w-[15ch] break-words text-xs';
        footerSubText.innerText = mapDetails.text[i].sub;
        footerText.appendChild(footerSubText);

        footerWrapper.appendChild(footerText);
        document
          .getElementById('left-FooterWrapper')
          .appendChild(footerWrapper);
      }

      if (i == 2) {
        let svg = document.getElementById('center-heart').cloneNode(true);
        svg.id = `shape${i}`;
        shape.appendChild(svg);

        footerWrapper.className =
          'mt-1 uppercase text-sm leading-none text-center';

        let footerWrapperMain = document.createElement('div');
        footerWrapperMain.className = 'flex flex-col items-center';

        let footerImage = document.createElement('img');
        footerImage.src = mapDetails.text[i].image;
        footerImage.className =
          'w-14 h-14 rounded-full bg-slate-300 bg-cover bg-center';
        footerWrapperMain.appendChild(footerImage);

        let footerText = document.createElement('div');
        footerText.className = 'mt-1';

        let footerMainText = document.createElement('div');
        footerMainText.className =
          'max-w-[12ch] break-words mb-1 font-semibold text-base';
        footerMainText.innerText = mapDetails.text[i].main;
        footerText.appendChild(footerMainText);

        let footerSubText = document.createElement('div');
        footerSubText.className = 'max-w-[15ch] break-words text-xs';
        footerSubText.innerText = mapDetails.text[i].sub;
        footerText.appendChild(footerSubText);

        footerWrapperMain.appendChild(footerText);
        footerWrapper.appendChild(footerWrapperMain);

        centerFooter.className = 'w-full';

        document.getElementById('center-shapePart').appendChild(footerWrapper);
      }

      if (i == 3) {
        let svg = document.getElementById('right-heart-1').cloneNode(true);
        svg.id = `shape${i}`;
        shape.appendChild(svg);

        footerWrapper.className = 'flex-1 flex flex-col items-center';
        let footerImage = document.createElement('img');
        footerImage.src = mapDetails.text[i].image;
        footerImage.className =
          'w-14 h-14 rounded-full bg-slate-300 bg-cover bg-center';
        footerWrapper.appendChild(footerImage);

        let footerText = document.createElement('div');
        footerText.className = 'mt-1';

        let footerMainText = document.createElement('div');
        footerMainText.className =
          'max-w-[12ch] break-words mb-1 font-semibold text-base';
        footerMainText.innerText = mapDetails.text[i].main;
        footerText.appendChild(footerMainText);

        let footerSubText = document.createElement('div');
        footerSubText.className = 'max-w-[15ch] break-words text-xs';
        footerSubText.innerText = mapDetails.text[i].sub;
        footerText.appendChild(footerSubText);

        footerWrapper.appendChild(footerText);
        document
          .getElementById('right-FooterWrapper')
          .appendChild(footerWrapper);
      }

      if (i == 4) {
        let svg = document.getElementById('right-heart-2').cloneNode(true);
        svg.id = `shape${i}`;
        shape.appendChild(svg);

        footerWrapper.className = 'flex-1 flex flex-col items-center';
        let footerImage = document.createElement('img');
        footerImage.src = mapDetails.text[i].image;
        footerImage.className =
          'w-14 h-14 rounded-full bg-slate-300 bg-cover bg-center';
        footerWrapper.appendChild(footerImage);

        let footerText = document.createElement('div');
        footerText.className = 'mt-1';

        let footerMainText = document.createElement('div');
        footerMainText.className =
          'max-w-[12ch] break-words mb-1 font-semibold text-base';
        footerMainText.innerText = mapDetails.text[i].main;
        footerText.appendChild(footerMainText);

        let footerSubText = document.createElement('div');
        footerSubText.className = 'max-w-[15ch] break-words text-xs';
        footerSubText.innerText = mapDetails.text[i].sub;
        footerText.appendChild(footerSubText);

        footerWrapper.appendChild(footerText);
        document
          .getElementById('right-FooterWrapper')
          .appendChild(footerWrapper);
      }
      const rendermap = new Map({
        container: container,
        style: mapStyle[i].mapTileApi,
        center: [center[i].lon, center[i].lat],
        zoom: zoom,
        attributionControl: false,
        zoomControl: true,
      });

      let markerIcon = document.createElement('img');
      markerIcon.src = require(`../../../assets/images/${mapMarker[i].image}`);
      if (mapMarker[i].type == 'character') {
        markerIcon.className = 'w-1/2 h-auto';
      } else {
        markerIcon.className = 'w-1/5 h-auto';
      }

      rendermap.once('idle', () => {
        new Marker(markerIcon)
          .setLngLat(rendermap.getCenter())
          .addTo(rendermap);
        html2canvas(
          document.getElementsByClassName('hidden-map')[0].children[0],
          {
            useCORS: true,
            backgroundColor: null,
            allowTaint: true,
          },
        ).then(
          (mapCanvas) => {
            img.src = mapCanvas.toDataURL('image/png');
            rendermap.remove();
            hidden.parentNode?.removeChild(hidden);
            if (i < selectedOptions.numOfMaps - 1) {
              i++;
              let payload = {
                i: i,
                docWidth: docWidth,
              };
              context.dispatch('captureMap', payload);
            } else {
              setTimeout(() => {
                let imgPayload = {
                  final: null,
                  base: null,
                  map: null,
                };
                html2canvas(document.getElementById('printFrame'), {
                  useCORS: true,
                  allowTaint: true,
                  scale: 2,
                }).then(
                  (finalCanvas) => {
                    let previewImg = finalCanvas.toDataURL('image/png');
                    context.commit('checkout/setPreviewImg', previewImg, {
                      root: true,
                    });
                    imgPayload.final = previewImg;

                    let maps = document.querySelectorAll('.Print');
                    for (let x = 0; x < maps.length; x++) {
                      maps[x].style.display = 'none';
                    }
                    html2canvas(document.getElementById('printFrame'), {
                      useCORS: true,
                      allowTaint: true,
                      scale: 2,
                    }).then(
                      (baseCanvas) => {
                        imgPayload.base = baseCanvas.toDataURL(
                          'image/png',
                          1.0,
                        );

                        for (let x = 0; x < maps.length; x++) {
                          maps[x].removeAttribute('style');
                        }

                        let content = document.querySelectorAll('.Content');
                        for (let x = 0; x < content.length; x++) {
                          content[x].style.visibility = 'hidden';
                        }
                        document.getElementById(
                          'printFrame',
                        ).style.backgroundColor = 'transparent';
                        html2canvas(document.getElementById('printFrame'), {
                          useCORS: true,
                          allowTaint: true,
                          scale: 2,
                        }).then(
                          (mapCanvas) => {
                            imgPayload.map = mapCanvas.toDataURL(
                              'image/png',
                              1.0,
                            );
                            document.body.removeChild(
                              document.getElementById('printFrame'),
                            );
                            context.commit('checkout/setImages', imgPayload, {
                              root: true,
                            });
                            resolve(imgPayload);
                          },
                          (error) => {
                            reject(error);
                          },
                        );
                      },
                      (error) => {
                        reject(error);
                      },
                    );
                  },
                  (error) => {
                    reject(error);
                  },
                );
              }, 500);
            }
          },
          (error) => {
            reject(error);
          },
        );
      });
    });
  },
};
