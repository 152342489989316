<template>
  <div class="w-screen bg-[#F7F7F7]">
    <div v-if="!image">
      <label
        class="block tracking-wide text-xs font-bold mb-1 mx-2"
        for="upload-logo"
      >
        {{ labelText }}
      </label>
      <div class="w-full items-center justify-center">
        <label
          class="w-full flex flex-col items-center px-4 py-6 text-gray-500 rounded-lg tracking-wide border-2 border-dashed border-background-secondary cursor-pointer hover:bg-background-primary hover:text-gray-600"
        >
          <div class="flex flex-col items-center px-4 py-6 w-full dragBox">
            <svg
              width="28"
              height="26"
              viewBox="0 0 28 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 8.0004C19.9943 8.0004 19.9889 7.99848 19.9845 7.995L15.7081 3.70619L14.0011 1.99419C14.0038 1.99443 14.0064 1.99557 14.0084 1.99743C14.0086 1.99754 14.0087 1.99765 14.0088 1.99776L20.002 7.99097C20.0021 7.99109 20.0022 7.99121 20.0024 7.99133C20.0045 7.99363 20.0056 7.99664 20.0056 7.99977C20.0038 8.00019 20.0019 8.0004 20 8.0004ZM13.9916 1.99743C13.9936 1.99557 13.9962 1.99443 13.9989 1.99419L12.2919 3.70619L8.00834 8.0022C8.00605 8.00425 8.00308 8.00539 8 8.00539C7.99837 8.00539 7.99678 8.00507 7.99529 8.00447C7.99469 8.00299 7.99437 8.00139 7.99437 7.99977C7.99437 7.99663 7.99555 7.99361 7.99767 7.99131C7.99777 7.9912 7.99788 7.99108 7.99799 7.99097L13.9912 1.99776C13.9913 1.99765 13.9914 1.99754 13.9916 1.99743ZM27 15.9998V23.9998C27 24.265 26.8946 24.5193 26.7071 24.7069C26.5196 24.8944 26.2652 24.9998 26 24.9998H2C1.73478 24.9998 1.48043 24.8944 1.29289 24.7069C1.10536 24.5193 1 24.265 1 23.9998V15.9998C1 15.7345 1.10536 15.4802 1.29289 15.2927C1.48043 15.1051 1.73478 14.9998 2 14.9998H10.0005C10.0342 17.1771 11.8264 19.0497 14.0847 18.9995C15.1306 18.9774 16.1261 18.5464 16.858 17.7989C17.5902 17.0512 18.0002 16.0463 18 14.9998H26C26.2652 14.9998 26.5196 15.1051 26.7071 15.2927C26.8946 15.4802 27 15.7345 27 15.9998ZM24 19.9998C24 19.5053 23.8534 19.022 23.5787 18.6108C23.304 18.1997 22.9135 17.8793 22.4567 17.6901C21.9999 17.5009 21.4972 17.4513 21.0123 17.5478C20.5273 17.6443 20.0819 17.8824 19.7322 18.232C19.3826 18.5816 19.1445 19.0271 19.048 19.512C18.9516 19.997 19.0011 20.4997 19.1903 20.9565C19.3795 21.4133 19.7 21.8037 20.1111 22.0784C20.5222 22.3531 21.0055 22.4998 21.5 22.4998C22.163 22.4998 22.7989 22.2364 23.2678 21.7675C23.7366 21.2987 24 20.6628 24 19.9998Z"
                fill="#172327"
                stroke="#172327"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>

            <span class="mt-2 text-sm normal-case text-center">
              Drag your image here to start uploading - max 2 mb
            </span>
            <input
              id="upload-logo"
              type="file"
              class="opacity-0"
              :accept="acceptedFileTypes"
              maxlength="2000000"
              @change="onFileChange"
              @dragover="drag()"
              @drop="drop()"
            />
            <span
              id="error-message"
              class="validation-error-label block text-red-600 text-center"
              v-if="error"
              >File upload size is larger than 2MB</span
            >
            <span class="py-4">------- OR ------ </span>
            <span
              class="border-solid border-2 border-neutral-900 rounded-lg p-2"
              id="browse-button"
            >
              Browse image
            </span>
          </div>
        </label>
      </div>
    </div>
    <!-- When the user loads the image -->
    <div
      v-if="image"
      class="w-full flex flex-col items-center px-4 py-6 text-gray-500 rounded-lg tracking-wide border-2 border-dashed border-background-secondary cursor-pointer hover:bg-background-primary hover:text-gray-600"
    >
      <img :src="image" :alt="alt" :class="imageClass" />
      <button
        class="bg-blue-700 text-white hover:bg-blue-700-hover text-white text-sm py-1 px-4 rounded focus:outline-none focus:shadow-outline"
        @click="removeImage"
      >
        Remove File
      </button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ImageDrag',
    data() {
      return {
        acceptedFileTypes: 'image/*',
        image: null,
        imageClass: 'w-40 text-center p-4',
        error: false,
      };
    },
    mounted() {
      this.deleteCookies();
    },
    methods: {
      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },
      onFileChange(e) {
        this.deleteCookies();
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        var file_size = files[0].size;
        if (file_size > 2000000) {
          this.error = true;
          this.drop();
        } else {
          this.createImage(files[0]);
          this.drop();
        }
      },
      createImage(file) {
        this.deleteCookies();
        this.error = false;
        const reader = new FileReader();
        const t = this;
        reader.onload = (e) => {
          t.image = e.target.result;
          t.$emit('load-image', e.target.result);
        };
        reader.readAsDataURL(file);
      },
      removeImage() {
        this.deleteCookies();
        this.image = null;
      },
      drag() {
        this.deleteCookies();
        document.getElementById('upload-logo').parentNode.className =
          'draging dragBox flex flex-col items-center px-4 py-6 w-full';
      },
      drop() {
        this.deleteCookies();
        document.getElementById('upload-logo').parentNode.className =
          'dragBox flex flex-col items-center px-4 py-6 w-full';
      },
    },
  };
</script>
<style>
  .draging {
    transform: scale(1.1);
  }
</style>
