<template>
  <div>
    <app-header-home />
    <div class="container px-4 pt-20 mx-auto">
      <base-text type="h1" class="text-center"> FAQ's </base-text>
      <div class="grid gap-4 lg:grid-cols-2">
        <base-card
          class="m-5 sm:grid-cols-2"
          v-for="(data, index) in faqData"
          :key="index"
          :open="data.id == 1 ? true : false"
        >
          <template #title>
            <base-text type="h4"> {{ data.question }} </base-text>
          </template>
          <template #body>
            <base-text type="p" class="mx-3">{{
              data.answer
            }}</base-text></template
          ></base-card
        >
      </div>
    </div>
    <app-footer />
  </div>
</template>

<script>
  import AppHeaderHome from '../components/AppHeaderHome.vue';
  import BaseText from '../components/ui/BaseText.vue';
  import BaseCard from '@/components/ui/BaseCard.vue';
  import AppFooter from '../components/AppFooter.vue';
  import { useHead } from 'unhead';
  export default {
    name: 'FaqComponent',
    components: {
      AppHeaderHome,
      BaseText,
      BaseCard,
      AppFooter,
    },
    data() {
      return {
        faqData: [
          {
            id: 1,
            question:
              '1. What type of paper is used for personalized wall art?',
            answer: 'We use premium luster fine art paper.',
          },
          {
            id: 2,
            question:
              '2. Does Personalized Travel Map Gift offer various sizes?',
            answer:
              'Yes, Location Map Gift offers three different sizes-square circle art.',
          },
          {
            id: 3,
            question: '3. Do you offer big and small canvas prints for sale?',
            answer:
              'Yes, we offer both big and small prints, so that they can be placed according to the décor.',
          },
          {
            id: 4,
            question: '4. What style do the frames have?',
            answer:
              'Each and every framed print is completely mounted in the frame and prepared for hanging.',
          },
          {
            id: 5,
            question: '5. How are city map gifts helpful?',
            answer:
              'City Map gifts are helpful for displaying locations marking with milestones.',
          },
          {
            id: 6,
            question:
              '6. Can we choose different backgrounds for different art works?',
            answer:
              'Yes, there are many background colour options available which can be chosen while designing of the gifts.',
          },
          {
            id: 7,
            question: '7. Are celestial maps available in different formats?',
            answer:
              'Yes, celestial maps are available in different forms of format.',
          },
        ],
      };
    },
    mounted() {
      this.deleteCookies();
      window.scrollTo(0, 0);
      useHead({
        title: 'Frequently Asked Questions (FAQ) | The Rare Window',
        meta: [
          {
            name: 'title',
            content: 'Frequently Asked Questions (FAQ) | The Rare Window',
          },
          {
            name: 'description',
            content: `Discover the answers to your questions about rare item collecting and investing on The Rare Window's FAQ page. Get expert insights to build your collection with confidence.`,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: 'https://therarewindow.com/faq',
          },
        ],
      });
    },
    methods: {
      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },
    },
  };
</script>

<style></style>
