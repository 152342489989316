<template>
  <h1 class="mb-2 md:mb-4 text-2xl md:text-3xl font-bold flex justify-center">
    The map of love
  </h1>
  <span class="mx-auto">
    <div
      ref="product"
      id="theMapOfLove"
      class="isolate frame12x8 w-[18rem] sm:w-11/12 md:w-11/12 sm:max-w-[38rem] md:max-w-[45rem] lg:max-w-[42rem] xl:max-w-[49rem] mx-auto px-1 pt-3 xs:pt-4 pb-3 xs:pb-2 bg-dark-teal backgroundImage-frame bg-cover bg-center preview"
      :style="{ outlineColor: selectedOption.frameColor.color }"
    >
      <img
        src="@/assets/images/preview.svg"
        alt="preview.svg"
        class="absolute z-30 h-full pointer-events-none opacity-20 pb-3 xs:pb-2"
      />
      <div
        class="z-20 relative xs:p-0.5 sm:p-2 md:pt-3 lg:pt-5 flex justify-center gap-1 xl:gap-0"
      >
        <div
          class="self-end drop-shadow-myDropShadow-xs xs:drop-shadow-myDropShadow-sm mb-4 xs:mb-7 sm:mb-9"
        >
          <div
            class="clipPath flex justify-start items-center bg-pink-800 px-2 py-0.5 xs:py-0.5 sm:py-1 sm:px-2 md:px-2.5 lg:px-3 xl:py-2 2xl:px-4 2xl:py-px"
          >
            <p
              class="font-ext-GreatVibes font-normal text-xxs xs:text-lg sm:text-xl 2xl:text-2xl text-center text-white leading-none xs:leading-none sm:leading-none xl:leading-none w-[10ch] max-w-[15ch] translate-y-px xs:translate-y-[3px] md:translate-y-[3px] lg:translate-y-px translate-x-[-2px] md:translate-x-[-4px] xl:translate-x-[-2px]"
            >
              {{ getDetail.name1 }}
            </p>
          </div>
        </div>

        <div class="flex isolate">
          <div
            id="circleMap1"
            class="overflow-hidden w-20 h-20 xs:w-36 xs:h-36 sm:w-44 sm:h-44 md:w-52 md:h-52 lg:w-44 lg:h-44 xl:w-60 xl:h-60 rounded-full -mr-6 z-10 bg-slate-600 map-wrap"
          >
            <StarMap
              :mapShape="getMapShape"
              :location="getCenter[0]"
              :date="getDesign.date"
              :time="getDesign.time"
              :mapId="'celestial-map1'"
              :color="getDesign.starBg"
              :propername="getDesign.propername"
            />
          </div>
          <div
            id="circleMap2"
            class="overflow-hidden w-20 h-20 xs:w-36 xs:h-36 sm:w-44 sm:h-44 md:w-52 md:h-52 lg:w-44 lg:h-44 xl:w-60 xl:h-60 rounded-full bg-slate-500"
          >
            <div class="w-full h-full relative">
              <map-component
                :api="getMapStyle[1].mapTileApi"
                :marker="getMapMarker[1]"
                :markerIndex="getMapMarker[1].index"
                :markerType="getMapMarker[1].type"
                :center="getCenter[1]"
                :markerLabel="getMarkerLabel[1]"
                :numOfMapOnFrame="numOfMap"
                :index="1"
                :mapShape="getMapShape"
                :showNav="!mobileView"
                ref="mapComponent"
              />
            </div>
          </div>
        </div>

        <div
          class="self-end drop-shadow-myDropShadow-xs xs:drop-shadow-myDropShadow-sm mb-4 xs:mb-7 sm:mb-9"
        >
          <div
            class="clipPath flex justify-start items-center bg-cyan-700 px-2 py-0.5 xs:py-0.5 sm:py-1 sm:px-2 md:px-2.5 lg:px-3 xl:py-2 2xl:px-4 2xl:py-px"
          >
            <p
              class="font-ext-GreatVibes font-normal text-xxs xs:text-lg sm:text-xl 2xl:text-2xl text-center text-white leading-none xs:leading-none sm:leading-none xl:leading-none w-[10ch] max-w-[15ch] translate-y-px xs:translate-y-[3px] md:translate-y-[3px] lg:translate-y-px translate-x-[-2px] md:translate-x-[-4px] xl:translate-x-[-2px]"
            >
              {{ getDetail.name2 }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="z-10 relative -mt-2 xs:-mt-5 sm:-mt-6 md:-mt-8 lg:-mt-10 xs:p-2 md:pt-3 lg:pt-5 flex justify-around gap-8 xs:gap-20 md:gap-32"
      >
        <div class="flex items-end">
          <img
            class="drop-shadow-human-sm sm:drop-shadow-human-lg w-7 xs:w-12 sm:w-16 md:w-[4.5rem] -mr-3.5 xs:-mr-6 sm:-mr-7 md:-mr-[2.15rem] lg:-mr-[1.75rem] xl:-mr-[2.5rem] z-10"
            src="@/assets/images/female-vector.svg"
            id="female-svg"
            alt="A female holding the left part of the heart"
          />
          <div class="relative self-start">
            <div class="absolute pointer-events-none">
              <svg viewBox="0 0 1 1" fill="none" id="leftHeart">
                <g clip-path="url(#clip0_191_3816)">
                  <defs>
                    <clipPath
                      clipPathUnits="objectBoundingBox"
                      id="leftHeartClipPath"
                    >
                      <path
                        fill="#FBB03B"
                        d="M.752.422c-.058 0-.07.02-.085.033a.041.041 0 0 1-.042.007C.614.458.617.442.617.442c.01-.099.09-.341.09-.341a.314.314 0 0 0-.508.095L.197.199.196.202c-.192.45.444.751.51.79a2.334 2.334 0 0 1-.09-.4C.614.586.62.578.635.576a.04.04 0 0 1 .027.007c.04.04.082.036.082.036C.817.618.84.558.84.522c0-.035-.03-.1-.088-.1Z"
                      />
                    </clipPath>
                  </defs>
                </g>
                <defs>
                  <clipPath id="clip0_191_3816">
                    <path fill="#fff" d="M0 0h1v1H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              class="drop-shadow-heart-sm sm:drop-shadow-heart-md xl:drop-shadow-heart-lg"
            >
              <div
                id="leftHeartMap"
                class="w-20 xs:w-36 sm:w-44 md:w-52 lg:w-44 xl:w-60 aspect-square bg-slate-400"
                style="clip-path: url(#leftHeartClipPath)"
              >
                <div class="w-full h-full relative">
                  <map-component
                    :api="getMapStyle[2].mapTileApi"
                    :marker="getMapMarker[2]"
                    :markerIndex="getMapMarker[2].index"
                    :markerType="getMapMarker[2].type"
                    :center="getCenter[2]"
                    :markerLabel="getMarkerLabel[2]"
                    :numOfMapOnFrame="numOfMap"
                    :index="2"
                    :mapShape="getMapShape"
                    :showNav="!mobileView"
                    ref="mapComponent"
                  />
                </div>
              </div>
              <div
                class="absolute left-[28%] top-[18%] sm:top-[20%] w-[1.125rem] xs:w-7 sm:w-9 md:w-11 2xl:w-12 h-[1.125rem] xs:h-7 sm:h-9 md:h-11 2xl:h-12 rounded-full overflow-hidden drop-shadow-myDropShadow-xs sm:drop-shadow-myDropShadow-sm bg-slate-500"
              >
                <img
                  class="object-cover object-center"
                  :src="getDetail.leftImage"
                  alt="200"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute top-2 sm:top-4 md:top-6 lg:top-8 -bottom-1 left-1/2 -translate-x-1/2 flex flex-col justify-between xs:justify-center xs:gap-4 sm:gap-5 md:gap-2 lg:gap-0 items-center"
        >
          <div
            class="text-4xs xs:text-xxs sm:text-xs md:text-sm text-center font-bold leading-tight w-[22ch]"
          >
            <p class="uppercase">{{ getDetail.event }}</p>
            <p>{{ getDetail.date }} | {{ getDetail.time }}</p>
            <p class="uppercase">{{ getDetail.location }}</p>
          </div>
          <div
            class="w-6 xs:w-10 sm:w-12 md:w-14 2xl:w-[4.5rem] h-6 xs:h-10 sm:h-12 md:h-14 2xl:h-[4.5rem] bg-slate-500 rounded-full drop-shadow-myDropShadow-xs sm:drop-shadow-myDropShadow-sm overflow-hidden"
          >
            <img
              class="object-cover object-center"
              :src="getDetail.centerImage"
              alt="500"
            />
          </div>
          <div
            class="font-ext-GreatVibes font-normal text-xxs xs:text-base sm:text-2xl md:text-2xl lg:leading-none text-center leading-none xs:leading-none sm:leading-none md:leading-none tracking-wide w-[18ch]"
          >
            <p>{{ getDetail.quote }}</p>
          </div>
        </div>
        <div class="flex items-end">
          <div class="relative self-start">
            <div class="absolute pointer-events-none">
              <svg viewBox="0 0 1 1" fill="none" id="rightHeart">
                <g clip-path="url(#clip0_191_3819)">
                  <defs>
                    <clipPath
                      clipPathUnits="objectBoundingBox"
                      id="rightHeartClipPath"
                    >
                      <path
                        fill="#FBB03B"
                        d="M.273.992a2.334 2.334 0 0 1-.091-.4c0-.007.006-.015.02-.017A.04.04 0 0 1 .23.582c.04.04.082.036.082.036.071 0 .095-.06.095-.096 0-.035-.03-.1-.088-.1-.058 0-.07.02-.085.033a.041.041 0 0 1-.042.007C.181.458.183.442.183.442c.01-.1.09-.341.09-.341a.314.314 0 0 1 .51.098L.784.2C.976.651.34.953.274.992Z"
                      />
                    </clipPath>
                  </defs>
                </g>
                <defs>
                  <clipPath id="clip0_191_3819">
                    <path fill="#fff" d="M0 0h1v1H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              class="drop-shadow-heart-sm sm:drop-shadow-heart-md xl:drop-shadow-heart-lg"
            >
              <div
                id="rightHeartMap"
                class="w-20 xs:w-36 sm:w-44 md:w-52 lg:w-44 xl:w-60 aspect-square bg-slate-400"
                style="clip-path: url(#rightHeartClipPath)"
              >
                <div class="w-full h-full relative">
                  <map-component
                    :api="getMapStyle[3].mapTileApi"
                    :marker="getMapMarker[3]"
                    :markerIndex="getMapMarker[3].index"
                    :markerType="getMapMarker[3].type"
                    :center="getCenter[3]"
                    :markerLabel="getMarkerLabel[3]"
                    :numOfMapOnFrame="numOfMap"
                    :index="2"
                    :mapShape="getMapShape"
                    :showNav="!mobileView"
                    ref="mapComponent"
                  />
                </div>
              </div>
              <div
                class="absolute right-[28%] sm:right-[30%] top-[18%] w-[1.125rem] xs:w-7 sm:w-9 md:w-11 2xl:w-12 h-[1.125rem] xs:h-7 sm:h-9 md:h-11 2xl:h-12 rounded-full overflow-hidden drop-shadow-myDropShadow-xs sm:drop-shadow-myDropShadow-sm bg-slate-500"
              >
                <img
                  class="object-cover object-center"
                  :src="getDetail.rightImage"
                  alt="201"
                />
              </div>
            </div>
          </div>
          <img
            class="drop-shadow-human-sm sm:drop-shadow-human-lg w-7 xs:w-12 sm:w-16 md:w-[4.5rem] -ml-[1.125rem] lg:-ml-[3rem] xs:-ml-8 sm:-ml-11 md:-ml-12 z-10"
            src="@/assets/images/male-vector.svg"
            alt="A male holding the right part of the heart"
            id="male-svg"
          />
        </div>
      </div>
    </div>
    <p class="mt-5 text-xs text-center opacity-60">
      *Preview is adjusted as per your screen size, actual print would be to the
      size.
    </p>
    <h2
      class="my-3 text-base font-bold md:text-xl text-center w-10/12 md:w-[500px] xl:w-[750px] mx-auto"
    >
      Create personalized Map
    </h2>
    <div class="text-sm w-10/12 md:w-[500px] xl:w-[700px] mx-auto">
      <p>
        The Map of Love is a unique and personalized journey map created by The
        Rare Window, a boutique customer experience agency. It is designed to
        help individuals and couples navigate their romantic relationships by
        understanding the different stages and milestones they may encounter.
      </p>
      <p class="my-2">
        The Map of Love is based on the belief that every relationship is unique
        and that there is no one-size-fits-all solution to building and
        maintaining a successful partnership. It takes into account the
        different factors that can impact a relationship, such as communication
        styles, love languages, and attachment styles.
      </p>
      <p class="my-2">
        The Rare Window works closely with clients to create a personalized
        <a href="https://therarewindow.com/time-capsule-map" class="underline"
          >Map of Love</a
        >
        that reflects their unique needs and experiences. Whether you are in the
        initial phase of a relationship or have been together for years, The Map
        of Love can help you navigate the ups and downs of idyllic love with
        greater ease and understanding.
      </p>
    </div>
  </span>
</template>
<script>
  import { mapGetters } from 'vuex';
  import StarMap from './map/StarMap.vue';
  import MapComponent from './map/MapComponent.vue';
  export default {
    components: {
      StarMap,
      MapComponent,
    },
    props: {
      numOfMap: {
        type: Number,
        require: true,
      },
    },
    computed: {
      ...mapGetters('mapObj', [
        'mapDataUrl',
        'getMapMarker',
        'getMapStyle',
        'getCenter',
        'getMarkerLabel',
      ]),
      ...mapGetters('theMapOfLove', [
        'getMapShape',
        'getDesign',
        'selectedOption',
        'selectedFrameSize',
        'getDetail',
      ]),
      mobileView() {
        return window.screen.width <= 480;
      },
    },
  };
</script>
<style scoped>
  .map-wrap {
    position: relative;
    flex-grow: 2;
    justify-content: center;
    align-items: center;
    display: flex;
    /* padding: 10px; */
  }
  .map-wrap.Square {
    aspect-ratio: 1 / 1;
  }
  .frame12x8 {
    aspect-ratio: 12 / 8;
  }
  .preview {
    position: relative;
    margin: auto;
    outline: 6px solid transparent;
  }
  @media screen and (min-width: 480px) {
    .preview {
      outline: 12px solid transparent;
    }
  }
  img.maplibregl-marker.mapboxgl-marker.maplibregl-marker-anchor-center.mapboxgl-marker-anchor-center {
    width: 30px;
    height: 30px;
  }
  .mapboxgl-ctrl-top-right,
  .maplibregl-ctrl-top-right {
    right: 15px !important;
    top: 40px !important;
  }
  div#celestial-map1 {
    border: none !important;
  }
  div#map {
    border: none !important;
  }
</style>
