// import axios from "axios";
// import domtoimage from "dom-to-image-more";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Map, Marker } from 'maplibre-gl';
// import { saveAs } from "file-saver";

export default {
  async createImg(context) {
    let frameWidth = 0;
    let frameHeight = 0;
    let orientation = '';
    let isImageAdded = false;
    let selectedOptions = context.rootGetters['storyMap/selectedOption'];
    const bg = context.rootGetters['storyMap/getBg'];

    if (selectedOptions.numOfMaps == 1) {
      frameWidth = 768;
      frameHeight = 1152;
      orientation = 'portrait';
    } else {
      frameWidth = 1152;
      frameHeight = 768;
      orientation = 'landscape';
    }
    const doc = new jsPDF({
      orientation: orientation,
      unit: 'px',
      format: [frameWidth, frameHeight],
    });
    let docWidth = doc.internal.pageSize.getWidth();
    let docHeight = doc.internal.pageSize.getHeight();

    let frame = document.createElement('div');
    frame.style.height = docHeight + 'px';
    frame.style.width = docWidth + 'px';
    frame.style.backgroundColor = bg.color;
    frame.className = 'p-10 printFrame';
    frame.id = 'printFrame';
    document.body.appendChild(frame);

    let parent = document.createElement('div');
    parent.id = 'parent';
    parent.className = 'grid h-full grid-flow-col p-1 grid-rows-8';
    // frame.setAttribute('style', 'background-color: ' + bg.color);

    let header = document.createElement('div');
    header.className =
      'flex items-center justify-center text-5xl font-ext-GreatVibes -mt-14 Content';
    header.appendChild(document.createTextNode(selectedOptions.details.header));
    parent.appendChild(header);

    let main = document.createElement('div');
    main.className = 'flex row-span-6';
    main.id = 'main';
    parent.appendChild(main);

    let footer = document.createElement('div');
    footer.className =
      'flex items-center justify-center text-4xl font-ext-GreatVibes -mt-14 Content';
    footer.appendChild(document.createTextNode(selectedOptions.details.footer));
    parent.appendChild(footer);

    frame.appendChild(parent);

    let payload = {
      i: 0,
      docWidth: docWidth,
    };
    const data = await context.dispatch('captureMap', payload);
    if (data) return (isImageAdded = true);
    else return isImageAdded;
  },
  async captureMap(context, { i, docWidth }) {
    return new Promise((resolve, reject) => {
      const mapShape = context.rootGetters['storyMap/getMapShape'];
      const extent = context.rootGetters['mapObj/getExtent'];
      const mapMarker = context.rootGetters['mapObj/getMapMarker'];
      const center = context.rootGetters['mapObj/getCenter'];
      const zoom = context.rootGetters['mapObj/getZoom'];
      const mapStyle = context.rootGetters['mapObj/getMapStyle'];
      let selectedOptions = context.rootGetters['storyMap/selectedOption'];

      const hidden = document.createElement('div');
      hidden.className = 'hidden-map';
      document.body.appendChild(hidden);
      const container = document.createElement('div');
      container.style.width = docWidth + 'px';
      container.style.height = container.style.width;
      hidden.appendChild(container);

      let wrapper = document.createElement('div');
      wrapper.className =
        'relative grid w-full h-full grid-flow-col grid-rows-5 px-2';
      document.getElementById('main').appendChild(wrapper);

      let mapWrapper = document.createElement('div');
      mapWrapper.className =
        'flex items-center justify-center row-span-4 p-5 map-wrap';
      wrapper.appendChild(mapWrapper);

      let shape = document.createElement('div');
      if (selectedOptions.numOfMaps == 3) {
        shape.className = 'relative w-full shape';
      } else {
        shape.className = 'relative h-full shape';
      }
      mapWrapper.appendChild(shape);

      if (mapShape.name == 'Heart' || mapShape.name == 'Circle') {
        let svg = document.getElementById('Warstwa_1').cloneNode(true);
        svg.id = mapShape.name;
        svg.setAttribute('style', 'position: absolute;');
        shape.appendChild(svg);
      }

      let img = document.createElement('img');
      img.className =
        'object-contain w-full h-full border border-transparent Print';
      shape.appendChild(img);

      let textWrapper = document.createElement('div');
      textWrapper.className = 'grid textOnMap content-center';
      wrapper.appendChild(textWrapper);

      let title = document.createElement('div');
      title.className =
        'flex items-center justify-center text-xl font-ext-GreatVibes md:text-3xl -mt-6 mb-3 Content';
      title.appendChild(
        document.createTextNode(selectedOptions.details.text[i].main),
      );
      textWrapper.appendChild(title);

      let subtitle = document.createElement('div');
      subtitle.className = 'flex items-center justify-center Content';
      subtitle.appendChild(
        document.createTextNode(selectedOptions.details.text[i].sub),
      );
      textWrapper.appendChild(subtitle);

      const rendermap = new Map({
        container: container,
        style: mapStyle[i].mapTileApi,
        center: [center[i].lon, center[i].lat],
        zoom: zoom,
        attributionControl: false,
        zoomControl: false,
      });
      rendermap.fitBounds(extent[i]);

      let markerIcon = document.createElement('img');
      markerIcon.src = require(`../../../assets/images/${mapMarker[i].image}`);
      if (mapMarker[i].type == 'character') {
        markerIcon.className = 'w-1/2 h-auto';
      } else {
        markerIcon.className = 'w-1/5 h-auto';
      }

      rendermap.once('idle', () => {
        new Marker(markerIcon)
          .setLngLat(rendermap.getCenter())
          .addTo(rendermap);
        html2canvas(
          document.getElementsByClassName('hidden-map')[0].children[0],
          {
            useCORS: true,
            backgroundColor: null,
            allowTaint: true,
          },
        ).then(
          (mapCanvas) => {
            img.src = mapCanvas.toDataURL('image/png');
            rendermap.remove();
            hidden.parentNode?.removeChild(hidden);
            if (i < selectedOptions.numOfMaps - 1) {
              i++;
              let payload = {
                i: i,
                docWidth: docWidth,
              };
              context.dispatch('captureMap', payload);
            } else {
              setTimeout(() => {
                let imgPayload = {
                  final: null,
                  base: null,
                  map: null,
                };
                html2canvas(document.getElementById('printFrame'), {
                  useCORS: true,
                  allowTaint: true,
                  scale: 2,
                }).then(
                  (finalCanvas) => {
                    let previewImg = finalCanvas.toDataURL('image/png');
                    context.commit('checkout/setPreviewImg', previewImg, {
                      root: true,
                    });
                    imgPayload.final = previewImg;

                    let maps = document.querySelectorAll('.Print');
                    for (let x = 0; x < maps.length; x++) {
                      maps[x].style.display = 'none';
                    }
                    html2canvas(document.getElementById('printFrame'), {
                      useCORS: true,
                      allowTaint: true,
                      scale: 2,
                    }).then(
                      (baseCanvas) => {
                        imgPayload.base = baseCanvas.toDataURL(
                          'image/png',
                          1.0,
                        );

                        for (let x = 0; x < maps.length; x++) {
                          maps[x].removeAttribute('style');
                        }

                        let content = document.querySelectorAll('.Content');
                        for (let x = 0; x < content.length; x++) {
                          content[x].style.visibility = 'hidden';
                        }
                        document.getElementById(
                          'printFrame',
                        ).style.backgroundColor = 'transparent';
                        html2canvas(document.getElementById('printFrame'), {
                          useCORS: true,
                          allowTaint: true,
                          scale: 2,
                        }).then(
                          (mapCanvas) => {
                            imgPayload.map = mapCanvas.toDataURL(
                              'image/png',
                              1.0,
                            );
                            document.body.removeChild(
                              document.getElementById('printFrame'),
                            );
                            context.commit('checkout/setImages', imgPayload, {
                              root: true,
                            });
                            resolve(imgPayload);
                          },
                          (error) => {
                            reject(error);
                          },
                        );
                      },
                      (error) => {
                        reject(error);
                      },
                    );
                  },
                  (error) => {
                    reject(error);
                  },
                );
              }, 500);
            }
          },
          (error) => {
            reject(error);
          },
        );
      });
    });
  },
};
