<template>
  <div class="relative z-20 mx-8 xl:mx-0">
    <base-text type="h2" add-class="md:w-1/2">
      We are being loved by our happy customers️
    </base-text>
    <div class="mt-16" v-if="testimonials.length != 0">
      <carousel :settings="settings" :breakpoints="breakpoints">
        <slide v-for="(testimonial, index) in testimonials" :key="index">
          <div class="w-full h-full p-6 m-3 text-left rounded-2xl borderDashed">
            <base-text type="h4">
              {{ testimonial.Message }}
            </base-text>
            <div class="flex items-center mt-10">
              <img
                :src="testimonial.user_image"
                :alt="testimonial.name"
                class="object-fill w-10 h-10"
              />
              <div class="ml-3">
                <base-text type="h5">{{ testimonial.name }}</base-text>
                <div class="flex -mt-3">
                  <img
                    v-for="index in testimonial.Rating"
                    :key="index"
                    src="../assets/images/star.svg"
                    :alt="`star${index}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>No testimonial available.</div>
  </div>
</template>

<script>
  import 'vue3-carousel/dist/carousel.css';
  import { Carousel, Slide, Navigation } from 'vue3-carousel';
  import BaseText from '../components/ui/BaseText.vue';
  export default {
    components: {
      Carousel,
      Slide,
      Navigation,
      BaseText,
    },
    data() {
      return {
        settings: {
          itemsToShow: 1,
          itemsToScroll: 1,
          snapAlign: 'start',
          autoplay: '2000',
          wrapAround: true,
        },
        breakpoints: {
          // 700px and up
          700: {
            itemsToShow: 2,
            snapAlign: 'center',
          },
          // 1024 and up
          1024: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
        },
        testimonials: [],
      };
    },
    created() {
      this.getTestimonials();
    },
    methods: {
      async getTestimonials() {
        await fetch('https://therarewindow.com/api/getTestimonial/').then(
          (res) => {
            if (res.ok) {
              res.json().then((data) => {
                const newData = [];
                data.data.forEach((element) => {
                  newData.push({
                    Message: element.Message,
                    Rating: element.Rating == '' ? 0 : parseInt(element.Rating),
                    admin_consent: element.admin_consent,
                    email: element.email,
                    id: element.id,
                    name: element.name,
                    order_id: element.order_id,
                    productMedia: element.productMedia,
                    productMedia1: element.productMedia1,
                    productMedia2: element.productMedia2,
                    productMedia3: element.productMedia3,
                    productMedia4: element.productMedia4,
                    user_consent: element.user_consent,
                    user_image: element.user_image,
                  });
                });
                data.data = newData;
                this.testimonials = data.data;
              });
            }
          },
        );
      },
    },
  };
</script>

<style>
  .carousel__prev--in-active,
  .carousel__next--in-active {
    display: none;
  }
</style>
