import { countries, zones } from 'moment-timezone/data/meta/latest.json';
const timeZoneCityToCountry = {};
var userCity;
var userCountry;
var userTimeZone;
export default {
  async fetchCountry({ commit }) {
    Object.keys(zones).forEach((z) => {
      const cityArr = z.split('/');
      const city = cityArr[cityArr.length - 1];
      timeZoneCityToCountry[city] = countries[zones[z].countries[0]].name;
    });
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var tzArr = userTimeZone.split('/');
    userCity = tzArr[tzArr.length - 1];
    if (userCity == 'Calcutta') {
      userCity = 'Kolkata';
    }
    userCountry = timeZoneCityToCountry[userCity];
    commit('setCountry', userCountry);
  },
};
