import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import celestial from 'd3-celestial';

export default {
  namespaced: true,
  state() {
    return {
      productName: 'celestial map',
      title: 'Your title goes here',
      subText: 'Subtext about your memory',
      mapShape: { name: 'Square', color: '' },
      numOfMaps: 1,
      frameSize: '8x12',
      bg: {
        color: '#ffffff',
        image: '',
      },
      design: [
        {
          starBg: '#FFFFFF',
          date: new Date(),
          time: null,
          propername: false,
        },
        {
          starBg: '',
          date: new Date(),
          time: null,
          propername: false,
        },
        {
          starBg: '',
          date: new Date(),
          time: null,
          propername: false,
        },
      ],
      details: {
        header: 'Your header goes here',
        footer: 'Your footer goes here',
        alternativeFont: false,
        text: [
          { main: 'Your map 1 title', sub: 'Your map 1 subtext' },
          { main: 'Your map 2 title', sub: 'Your map 2 subtext' },
          { main: 'Your map 3 title', sub: 'Your map 3 subtext' },
        ],
      },
      printingType: '',
      Location: '',
      frameColor: {
        color: '#000000',
        name: 'Title',
      },
      giftingMessage: '',
      giftWrapping: false,
      starMapDiv: null,
      celestial: celestial.Celestial(),
      celestialConfig: {
        container: 'celestial-map1',
        width: 0,
        projection: 'stereographic', // Map projection used: airy, aitoff, armadillo, august, azimuthalEqualArea, azimuthalEquidistant, baker, berghaus, boggs, bonne, bromley, collignon, craig, craster, cylindricalEqualArea, cylindricalStereographic, eckert1, eckert2, eckert3, eckert4, eckert5, eckert6, eisenlohr, equirectangular, fahey, foucaut, ginzburg4, ginzburg5, ginzburg6, ginzburg8, ginzburg9, gringorten, hammer, hatano, healpix, hill, homolosine, kavrayskiy7, lagrange, larrivee, laskowski, loximuthal, mercator, miller, mollweide, mtFlatPolarParabolic, mtFlatPolarQuartic, mtFlatPolarSinusoidal, naturalEarth, nellHammer, orthographic, patterson, polyconic, rectangularPolyconic, robinson, sinusoidal, stereographic, times, twoPointEquidistant, vanDerGrinten, vanDerGrinten2, vanDerGrinten3, vanDerGrinten4, wagner4, wagner6, wagner7, wiechel, winkel3
        // transform: 'equatorial', // Coordinate transformation: equatorial (default), ecliptic, galactic, supergalactic
        controls: false, // zoom controls

        // geopos: [this.lat, this.lng],
        orientationfixed: true,
        follow: 'zenith',
        disableAnimations: false,
        zoomlevel: 3,
        stars: {
          colors: false,
          size: 10, // ***change star size
          limit: 6,
          exponent: -0.26,
          designation: false, // *** Show star names
          style: { fill: '#000000', opacity: 1 }, // *** Change star color.
          propername: false, // *** Show proper name
          propernameType: 'name',
          propernameStyle: {
            fill: '#172327',
            font: `14px Helvetica, Arial, sans-serif`,
            align: 'center',
            baseline: 'center',
          },
          propernameLimit: 2.0,
        },
        dsos: {
          show: false,
          size: 6,
          designation: false,
          propername: false,
          data: 'dsos.6.json',
        },
        constellations: {
          names: false, // Show constellation names
          namesType: 'en', // Type of name Latin (iau, default), 3 letter designation (desig) or other language (see list below)
          nameStyle: {
            fill: '#000000',
            align: 'center',
            baseline: 'middle',
            font: [
              '30px Helvetica, Arial, sans-serif', // Style for constellations
              '20px Helvetica, Arial, sans-serif', // Different fonts for diff.
              '18px Helvetica, Arial, sans-serif',
            ],
          }, // ranked constellations
          lines: true, // Show constellation lines, style below
          lineStyle: { stroke: '#000000', width: 2, opacity: 0.6 }, // ***Line width and color
          bounds: false, // Show constellation boundaries, style below
          boundStyle: {
            stroke: '#cccc00',
            width: 0.5,
            opacity: 0.8,
            dash: [2, 4],
          },
        },
        planets: {
          show: false,
          which: [
            'sol',
            'mer',
            'ven',
            'ter',
            'lun',
            'mar',
            'jup',
            'sat',
            'ura',
            'nep',
          ],
          // Font styles for planetary symbols
          symbolType: 'disk',
          symbols: {
            // Character and color for each symbol in 'which', simple circle \u25cf
            sol: {
              symbol: '\u2609',
              letter: 'Su',
              fill: '#ffff00',
              size: '24',
            },
            mer: { symbol: '\u263F', letter: 'Me', fill: '#cccccc' },
            ven: { symbol: '\u2640', letter: 'V', fill: '#eeeecc' },
            ter: { symbol: '\u2295', letter: 'T', fill: '#00ccff' },
            lun: { symbol: '\u25CF', letter: 'L', fill: '#ffffff', size: '24' }, // overridden by generated crecent, except letter & size
            mar: { symbol: '\u2642', letter: 'Ma', fill: '#ff6600' },
            cer: { symbol: '\u26B3', letter: 'C', fill: '#cccccc' },
            ves: { symbol: '\u26B6', letter: 'Ma', fill: '#cccccc' },
            jup: { symbol: '\u2643', letter: 'J', fill: '#ffaa33' },
            sat: { symbol: '\u2644', letter: 'Sa', fill: '#ffdd66' },
            ura: { symbol: '\u2645', letter: 'U', fill: '#66ccff' },
            nep: { symbol: '\u2646', letter: 'N', fill: '#6666ff' },
            plu: { symbol: '\u2647', letter: 'P', fill: '#aaaaaa' },
            eri: { symbol: '\u26AA', letter: 'E', fill: '#eeeeee' },
          },
          names: true,
          nameStyle: {
            fill: '#cccccc',
            font: "17px 'Lucida Sans Unicode', 'DejaVu Sans'",
            align: 'right',
            baseline: 'top',
          },
          namesType: 'desig',
        },
        mw: {
          show: false,
        },
        lines: {
          // Display & styles for graticule & some planes
          graticule: {
            show: true,
            stroke: '#cccccc',
            width: 2,
            opacity: 0.8,
            // grid values: "outline", "center", or [lat,...] specific position
            lon: {
              pos: [''],
              fill: '#eee',
              font: '10px Helvetica, Arial, sans-serif',
            },
            // grid values: "outline", "center", or [lon,...] specific position
            lat: {
              pos: [''],
              fill: '#eee',
              font: '10px Helvetica, Arial, sans-serif',
            },
          },
          equatorial: {
            show: true,
            stroke: '#aaaaaa',
            width: 1.3,
            opacity: 0.7,
          },
          ecliptic: {
            show: false,
            stroke: '#66cc66',
            width: 1.3,
            opacity: 0.7,
          },
          galactic: {
            show: false,
            stroke: '#cc6666',
            width: 1.3,
            opacity: 0.7,
          },
          supergalactic: {
            show: false,
            stroke: '#cc66cc',
            width: 1.3,
            opacity: 0.7,
          },
        },
        background: {
          // Background style
          fill: '#ffffff', // ***Area fill
          opacity: 1,
          stroke: 'rgba(0,0,0,0.5)', // ***Outline
          width: 1.5,
        },
      },
      celestialConfigReset: {
        container: 'celestial-map1',
        width: 0,
        projection: 'stereographic', // Map projection used: airy, aitoff, armadillo, august, azimuthalEqualArea, azimuthalEquidistant, baker, berghaus, boggs, bonne, bromley, collignon, craig, craster, cylindricalEqualArea, cylindricalStereographic, eckert1, eckert2, eckert3, eckert4, eckert5, eckert6, eisenlohr, equirectangular, fahey, foucaut, ginzburg4, ginzburg5, ginzburg6, ginzburg8, ginzburg9, gringorten, hammer, hatano, healpix, hill, homolosine, kavrayskiy7, lagrange, larrivee, laskowski, loximuthal, mercator, miller, mollweide, mtFlatPolarParabolic, mtFlatPolarQuartic, mtFlatPolarSinusoidal, naturalEarth, nellHammer, orthographic, patterson, polyconic, rectangularPolyconic, robinson, sinusoidal, stereographic, times, twoPointEquidistant, vanDerGrinten, vanDerGrinten2, vanDerGrinten3, vanDerGrinten4, wagner4, wagner6, wagner7, wiechel, winkel3
        // transform: 'equatorial', // Coordinate transformation: equatorial (default), ecliptic, galactic, supergalactic
        controls: false, // zoom controls

        // geopos: [this.lat, this.lng],
        orientationfixed: true,
        follow: 'zenith',
        disableAnimations: false,
        zoomlevel: 3,
        stars: {
          colors: false,
          size: 3, // ***change star size
          limit: 6,
          exponent: -0.26,
          designation: true,
          style: { fill: '#000000', opacity: 1 }, // *** Change star color.
          propername: true,
          propernameType: 'name',
          propernameStyle: {
            fill: '#172327',
            font: `10px Helvetica, Arial, sans-serif`,
            align: 'center',
            baseline: 'center',
          },
          propernameLimit: 2.0,
        },
        dsos: {
          show: false,
          size: 6,
          designation: false,
          propername: false,
          data: 'dsos.6.json',
        },
        constellations: {
          names: true, // Show constellation names
          namesType: 'en', // Type of name Latin (iau, default), 3 letter designation (desig) or other language (see list below)
          nameStyle: {
            fill: '#000000',
            align: 'center',
            baseline: 'middle',
            font: [
              '14px Helvetica, Arial, sans-serif', // Style for constellations
              '12px Helvetica, Arial, sans-serif', // Different fonts for diff.
              '11px Helvetica, Arial, sans-serif',
            ],
          }, // ranked constellations
          lines: true, // Show constellation lines, style below
          lineStyle: { stroke: '#000000', width: 1, opacity: 0.6 }, // ***Line width and color
          bounds: false, // Show constellation boundaries, style below
          boundStyle: {
            stroke: '#cccc00',
            width: 0.5,
            opacity: 0.8,
            dash: [2, 4],
          },
        },
        planets: {
          show: false,
          which: [
            'sol',
            'mer',
            'ven',
            'ter',
            'lun',
            'mar',
            'jup',
            'sat',
            'ura',
            'nep',
          ],
          // Font styles for planetary symbols
          symbolType: 'disk',
          symbols: {
            // Character and color for each symbol in 'which', simple circle \u25cf
            sol: {
              symbol: '\u2609',
              letter: 'Su',
              fill: '#ffff00',
              size: '24',
            },
            mer: { symbol: '\u263F', letter: 'Me', fill: '#cccccc' },
            ven: { symbol: '\u2640', letter: 'V', fill: '#eeeecc' },
            ter: { symbol: '\u2295', letter: 'T', fill: '#00ccff' },
            lun: { symbol: '\u25CF', letter: 'L', fill: '#ffffff', size: '24' }, // overridden by generated crecent, except letter & size
            mar: { symbol: '\u2642', letter: 'Ma', fill: '#ff6600' },
            cer: { symbol: '\u26B3', letter: 'C', fill: '#cccccc' },
            ves: { symbol: '\u26B6', letter: 'Ma', fill: '#cccccc' },
            jup: { symbol: '\u2643', letter: 'J', fill: '#ffaa33' },
            sat: { symbol: '\u2644', letter: 'Sa', fill: '#ffdd66' },
            ura: { symbol: '\u2645', letter: 'U', fill: '#66ccff' },
            nep: { symbol: '\u2646', letter: 'N', fill: '#6666ff' },
            plu: { symbol: '\u2647', letter: 'P', fill: '#aaaaaa' },
            eri: { symbol: '\u26AA', letter: 'E', fill: '#eeeeee' },
          },
          names: true,
          nameStyle: {
            fill: '#cccccc',
            font: "17px 'Lucida Sans Unicode', 'DejaVu Sans'",
            align: 'right',
            baseline: 'top',
          },
          namesType: 'desig',
        },
        mw: {
          show: false,
        },
        lines: {
          // Display & styles for graticule & some planes
          graticule: {
            show: true,
            stroke: '#cccccc',
            width: 2,
            opacity: 0.8,
            // grid values: "outline", "center", or [lat,...] specific position
            lon: {
              pos: [''],
              fill: '#eee',
              font: '10px Helvetica, Arial, sans-serif',
            },
            // grid values: "outline", "center", or [lon,...] specific position
            lat: {
              pos: [''],
              fill: '#eee',
              font: '10px Helvetica, Arial, sans-serif',
            },
          },
          equatorial: {
            show: true,
            stroke: '#aaaaaa',
            width: 1.3,
            opacity: 0.7,
          },
          ecliptic: {
            show: false,
            stroke: '#66cc66',
            width: 1.3,
            opacity: 0.7,
          },
          galactic: {
            show: false,
            stroke: '#cc6666',
            width: 1.3,
            opacity: 0.7,
          },
          supergalactic: {
            show: false,
            stroke: '#cc66cc',
            width: 1.3,
            opacity: 0.7,
          },
        },
        background: {
          // Background style
          fill: '#ffffff', // ***Area fill
          opacity: 1,
          stroke: 'rgba(0,0,0,0.5)', // ***Outline
          width: 1.5,
        },
      },
    };
  },
  mutations,
  actions,
  getters,
};
