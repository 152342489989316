export default {
  selectedOption(state) {
    return state.selectedOption;
  },
  selectedFrameSize(state) {
    return state.selectedOption.frameSize;
  },
  imageTitle(state) {
    return state.selectedOption.title;
  },
  mapDataUrl(state) {
    return state.map.mapDataUrl;
  },
  getMap(state) {
    return state.map.map;
  },
  getMapFrame(state) {
    return state.map.mapFrame;
  },
  getCenter(state) {
    return state.map.center;
  },
  getZoom(state) {
    return state.map.zoom;
  },
  getExtent(state) {
    return state.map.extent;
  },
  getAlternativeFont(state) {
    return state.selectedOption.alternativeFont;
  },
};
