export default {
  setPrice(state, payload) {
    state.price = payload;
  },
  setProductDetails(state, payload) {
    state.productDetails = payload;
  },
  setNumOfMaps(state, payload) {
    state.numOfMaps = payload;
    state.productDetails.mapType = payload;
  },
  setProductName(state, payload) {
    state.productDetails.productName = payload;
  },
  setShippingDetails(state, payload) {
    state.userDetails.shippingDetails = payload;
  },
  setBillingDetails(state, payload) {
    state.userDetails.billingDetails = payload;
  },
  setUserEmail(state, payload) {
    state.userDetails.email = payload;
  },
  setSend_news_and_Offers(state, payload) {
    state.userDetails.send_news_and_Offers = payload;
  },
  setGiftingMessageCh(state, payload) {
    state.productDetails.giftingMsg = payload;
  },
  setGiftWrappingCh(state, payload) {
    state.productDetails.giftWrap = payload;
  },
  setFrameColorCh(state, payload) {
    state.productDetails.frameColor = payload;
  },
  setPrintingTypeCh(state, payload) {
    state.productDetails.printingType = payload.name;
  },
  setImages(state, payload) {
    state.blobImage = payload;
  },
  setPreviewImg(state, payload) {
    state.previewImg = payload;
  },
};
