<template>
  <div>
    <div
      v-for="(option, index) in options"
      :key="index"
      class="flex items-center mb-3"
    >
      <input
        :id="option.val"
        class="inputCheck"
        type="radio"
        :name="name"
        :value="option.val"
        v-model="selectedOpt"
        :checked="option.val == defaultSelect"
        @change="$emit('updateSelect', selectedOpt)"
      />
      <label
        class="inline-block text-gray-800 form-check-label"
        :for="option.val"
      >
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      defaultSelect: {
        require: true,
      },
      name: {
        type: String,
        require: true,
      },
      options: {
        type: Array,
        default: () => [{ val: 'default', label: 'Default' }],
      },
    },
    data() {
      return {
        selectedOpt: null,
      };
    },
    mounted() {
      this.$emit('updateSelect', this.defaultSelect);
    },
    watch: {
      defaultSelect(newVal) {
        if (newVal) {
          this.selectedOpt = newVal;
        }
      },
    },
  };
</script>

<style></style>
