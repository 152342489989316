export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export function validNonScriptText(text) {
  let validNonScriptTextPattern = new RegExp(
    '^<(?:/(?:S(?:CRIPT>?|cript>?)|script>?)|S(?:(?:CRIPT>?|cRiPt>?)|cript>?)|script>?)$',
  );
  if (!validNonScriptTextPattern.test(text)) {
    return true;
  }
  return false;
}
