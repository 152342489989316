export default {
  setFrameSize(state, payload) {
    state.selectedOption.frameSize = payload;
  },
  setTitle(state, payload) {
    state.details.text[payload.index - 1].main = payload.title;
  },
  setSubTitle(state, payload) {
    state.details.text[payload.index - 1].sub = payload.sub;
  },
  setFrameColor(state, payload) {
    state.selectedOption.frameColor.color = payload;
  },
  setGiftingMessage(state, payload) {
    state.selectedOption.giftingMessage = payload;
  },
  setGiftWrapping(state, payload) {
    state.selectedOption.giftWrapping = payload;
  },
  setPrintingType(state, payload) {
    state.selectedOption.printingType = payload.name;
  },
  setMapDataUrl(state, payload) {
    state.map.mapDataUrl = payload;
  },
  setMapObj(state, payload) {
    state.map.map = payload;
  },
  setMapFrame(state, payload) {
    state.map.mapFrame = payload;
  },
  setCenter(state, payload) {
    state.map.center = payload;
  },
  setZoom(state, payload) {
    state.map.zoom = payload;
  },
  setExtent(state, payload) {
    state.map.extent = payload;
  },
  setBg(state, payload) {
    state.selectedOption.bg = payload;
    state.selectedOption.mapColor = state.selectedOption.bg.color;
  },
  setDetailsHeaderText(state, payload) {
    state.details.header = payload;
  },
  setDetailsSubHeaderText(state, payload) {
    state.details.subHeader = payload;
  },
  setImage(state, payload) {
    state.details.text[payload.index - 1].image = payload.image;
  },
  setLeftFooter(state, payload) {
    state.details.leftFooter = payload;
  },
  setMainFooter(state, payload) {
    state.details.mainFooter = payload;
  },
  setRightFooter(state, payload) {
    state.details.rightFooter = payload;
  },
  setAlternativeFont(state, payload) {
    state.details.alternativeFont = payload;
  },
  resetDetails(state) {
    state.details.alternativeFont = false;
    state.details.header = 'Your header goes here';
    state.details.subHeader = 'Your subheader goes here';
    state.details.text = [
      {
        main: 'Your map 1 title',
        sub: 'Your map 1 subtext',
        image: 'https://www.w3schools.com/tags/img_girl.jpg',
      },
      {
        main: 'Your map 2 title',
        sub: 'Your map 2 subtext',
        image: 'https://www.w3schools.com/tags/img_girl.jpg',
      },
      {
        main: 'Your map 3 title',
        sub: 'Your map 3 subtext',
        image: 'https://www.w3schools.com/tags/img_girl.jpg',
      },
      {
        main: 'Your map 4 title',
        sub: 'Your map 4 subtext',
        image: 'https://www.w3schools.com/tags/img_girl.jpg',
      },
      {
        main: 'Your map 5 title',
        sub: 'Your map 5 subtext',
        image: 'https://www.w3schools.com/tags/img_girl.jpg',
      },
    ];
  },
};
