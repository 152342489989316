import { createStore } from 'vuex';
import momentMapModule from './products/momentMap/index.js';
import celestialMapModule from './products/celestialMap/index.js';
import storyMapModule from './products/storyMap/index.js';
import checkoutModule from './checkout/index.js';
import journeyMapModule from './products/journeyMap/index.js';
import mapModule from './map/index.js';
import theMapOfLoveModule from './products/theMapOfLove/index.js';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    mapObj: mapModule,
    journeyMap: journeyMapModule,
    momentMap: momentMapModule,
    celestialMap: celestialMapModule,
    storyMap: storyMapModule,
    checkout: checkoutModule,
    theMapOfLove: theMapOfLoveModule,
  },
});
