<template>
  <app-header-home />
  <div class="container px-4 pt-20 mx-auto">
    <h1 class="text-xl font-bold text-center">REFUND POLICY</h1>
    <p class="pt-3 pb-2">Last updated date: June 05, 2022</p>
    <ol class="pt-6">
      <li class="pb-8">
        <h1 class="text-xl font-bold">1. GENERAL</h1>
        <p class="pt-3 pb-0 pl-6">
          a. This website with the URL of www.therarewindow.com
          (<strong>"Website/Site"</strong>) is operated by Rarewindow Private
          Limited (<strong>"We/Our/Us"</strong>).
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. We are committed to providing Our customers with the highest
          quality Products. However, on rare occasions, Products may be found to
          be faulty or deficient. In such cases, We offer return and refund
          facilities in accordance with this Refund Policy (<b>"Policy"</b>).
        </p>
        <p class="pt-3 pb-0 pl-6">
          c. You are advised to read Our Terms and Conditions along with this
          Policy at the following webpage:
          <router-link class="italic cursor-pointer" to="/terms-and-condition"
            >www.therarewindow.com/terms-and-condition.</router-link
          >
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. By using this website, You agree to be bound by the terms contained
          in this Policy without modification. If You do not agree to the terms
          contained in this Policy, You are advised not to transact on this
          website.
        </p>
        <p class="pt-3 pb-0 pl-6">
          e. We offer a 2 days refund Policy for the eligible Products.
        </p>
        <p class="pt-3 pb-3 pl-6">
          f. Please read this Policy before making a purchase on this Website,
          so that You understand Your rights as well as what You can expect from
          Us if You are not happy with Your purchase.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">2. DEFINITIONS</h1>
        <p class="pt-3 pb-0 pl-6">
          a. <b>"Business Days"</b> - means a day that is not a Saturday,
          Sunday, public holiday, or bank holiday in India or in the state where
          our office is located.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. <b>"Customer"</b> - means a person who buys any goods or availing
          services for consideration but does not include a person who purchases
          such goods or avail services to resell them.
        </p>
        <p class="pt-3 pb-0 pl-6">
          c. <b>"Date of Transaction"</b> - means the date of purchase of any
          product or service, which includes the date of any renewal processed
          in accordance with the terms and conditions of the applicable product
          or service agreement.
        </p>
        <p class="pt-3 pb-3 pl-6">
          d. <b>"Website"</b> - means this website with the URL:
          <a href="/" class="cursor-pointer">www.therarewindow.com.</a>
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">3. REFUNDS RULES</h1>
        <p class="pt-3 pb-0 pl-6">
          a. Every effort is made so as to service the orders placed, as per the
          specifications and timelines mentioned with respect to a Products. If
          due to any unforeseen circumstances or limitations from Our side, the
          order is not shipped or delivered then such order stands cancelled,
          and the amount paid by You is refunded.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. We will not process a return if You have placed the order for the
          wrong product model, colour, or incorrect product or service.
        </p>
        <p class="pt-3 pb-0 pl-6">
          c. Return or refund shall only be considered once the Customer
          concerned produces relevant documents and proof.
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. Return and refund shall be subject to testing and verifying the
          alleged fault by an authorized person on behalf of Us.
        </p>
        <p class="pt-3 pb-0 pl-6">
          e. If following the testing process, the product is found to be in
          good working order without defects, We will return the product to you,
          and the carriage costs of this return will be Your responsibility.
        </p>
        <p class="pt-3 pb-0 pl-6">
          f. The product will be eligible for replacement only if the same
          product/model is available in stock. In case the same product/model is
          out of stock, a refund shall be provided.
        </p>
        <p class="pt-3 pb-0 pl-6">
          g. Once qualified, the refunds are applied to the original payment
          option.
        </p>
        <p class="pt-3 pb-0 pl-6">
          h. Please be informed that the products purchased using the Cash on
          Delivery option will be refunded to the Bank account with the details
          provided by you to Us.
        </p>
        <p class="pt-3 pb-0 pl-6">
          i. Customers who wish to make a warranty claim must comply with the
          manufacturer's instructions and warranty procedure. If the details of
          the product manufacturer are not available, we will help you with such
          details to directly connect with the manufacturer to resolve your
          issues.
        </p>
        <p class="pt-3 pb-0 pl-6">
          j. Please note that the Digital Products are non-returnable unless
          such Digital Product is materially non-compliant with its
          specifications or the physical media on which it is supplied is
          defective.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">
          4. PRODUCTS DAMAGED DURING THE TRANSIT
        </h1>
        <p class="pt-3 pb-0 pl-6">
          a. Replacement can be made if the Customer establishes that the
          product was delivered in defective condition or has physical damage
          within 48 hours of receipt of the product.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. We will organize to repair the damaged product or collect it and
          replace it with an equivalent product, or provide a refund, provided
          that You contact us within 48 hours of receipt of the product.
        </p>
        <p class="pt-3 pb-0 pl-6">
          c. Any damaged product must be returned in the condition it was in
          when you received it, together with any packaging and other items
          which You received with the damaged product.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">
          5. ORDER NOT CONFIRMED BUT AMOUNT DEDUCTED
        </h1>
        <p class="pt-3 pb-0 pl-6">
          a. If the amount has been deducted and the order is not confirmed,
          please do contact Your respective bank. It takes 7 (seven) Business
          Days to reverse back the amount by the respective bank.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. If the issue has not been resolved within 7 (seven) Business Days,
          you can contact Our customer care support as follows:
          support@therarewindow.com.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">6. EXEMPTIONS</h1>
        <p class="pt-3 pb-0 pl-6">
          a. Notwithstanding the other provisions of this Policy, We may refuse
          to provide repair, replacement, or refund for a product or service you
          purchased if:
        </p>
        <div class="pt-3 pb-0 pl-14">
          <p class="pt-2 pb-2">
            I. the product purchased is sale items or promotional items
            (discounts, giveaways, etc.).
          </p>
          <p class="pt-2 pb-2">II. if the product has been used or opened.</p>
          <p class="pt-2 pb-2">
            III. the products are not in the same condition as when they were
            delivered.
          </p>
          <p class="pt-2 pb-2">
            IV. misused the product in a way that caused the problem.
          </p>
          <p class="pt-2 pb-2">
            V. You knew or were made aware of the problem(s) with the product or
            service before you purchased it.
          </p>
          <p class="pt-2 pb-2">VI. Gifts or free services.</p>
          <p class="pt-2 pb-2">
            VII. the product is broken, tampered or damaged.
          </p>
          <p class="pt-2 pb-2">
            VIII. the product is returned after the return window is closed or
            the Refund Period expires.
          </p>
          <p class="pt-2">
            IX. Any other exceptions apply under the Consumer Protection Act,
            2019.
          </p>
        </div>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">7. RULES OF RETURNING THE PRODUCT</h1>
        <p class="pt-3 pb-0 pl-6">
          a. The request for cancellation of the products can be made in the
          following manner:
        </p>
        <div class="pt-3 pb-0 pl-14">
          <p>
            Rarewindow takes utmost care while packaging and shipping all its
            products. However, if you receive a damaged or incorrect product
            from Rarewindow, you may request for a refund or exchange of the
            product. Since the products are custom made we don't require you to
            ship them back to us in case they are damaged.
          </p>
          <p class="pt-7 pb-7">International Orders</p>
          <p>
            Please note that International deliveries are not eligible for
            replacement and we shall be happy to refund the amount of your order
            in the event you receive a damaged product from us. Customs delays
            are common and we cannot control or prevent this type of delay.
            Rarewindow does not take responsibility for lost and delayed
            packages due to customers that type incorrect, insufficient, or
            wrong shipping addresses. However, we will assist you in conveying
            the correct address to our delivery partners.
          </p>
          <p class="pb-4 pt-7">Domestic Orders</p>
          <p class="pb-4">
            You may follow the procedure below in case you receive a damaged
            product from us:
          </p>
          <p class="pb-4">
            Step 1: Make a video and take pictures clearly capturing the precise
            nature and extent of the damage to the product and email them to us
            at support@therarewindow.com within 48 hours from receiving the
            product is delivered.
          </p>
          <p class="pb-4">
            If you have received an incorrect product, please provide details of
            the product you ordered and the product you received and attach
            pictures of the product received.
          </p>
          <p class="pb-4">
            Step 2: Attach the details of the order including sender’s and
            receiver’s name, your email id, and the order id sent to you from
            Rarewindow to the message along with details such as if you would
            like a replacement or refund for your order.
          </p>
          <p class="pb-4">
            Step 3: We will endeavour to process your request for refund or
            replacement within 7 days from when we receive a request from you.
          </p>
          <p class="pb-4 pt-14">
            Refund, if so requested by you, will be provided within 48-72
            business hours, after we have processed and approved your request
            and it may take 4-7 additional business days for the amount to
            reflect in your account.
          </p>
          <p class="pb-4">
            We shall endeavour to ship replacement products within 24-48
            business hours after we have processed and approved your request
          </p>
          <p class="pb-4">
            If we approve a refund against your product, we will refund the
            invoiced rupee value for the damaged / defective / incorrect product
            upon you providing the details of the order including sender’s and
            receiver’s name, your email id, and the order id sent to you from
            Pine & Lime.
          </p>
          <p class="pb-4">
            Please note that we will be able to replace any damaged / defective
            / incorrect product subject to the availability of such product with
            us. In the event that a replacement is not available, we will refund
            you the full amount.
          </p>
          <p class="pt-8 pb-4">
            A Note about Quality: Sometimes your print may differ slightly than
            what is presented in the map editor. For example, some colors may
            come out slightly darker/lighter and bolder/dull than what is
            displayed on the map editor as the medium of display is digital.
            Coloring can change between devices and display settings. By
            ordering a product with therarewindow.com, you are agreeing that you
            acknowledge this and requests for products attempting to be returned
            or refunded for such reasons may not be qualified. We guarantee a
            high-quality product, however, nuances in colors/emojis/icons/text
            alignment are not in our control as every individual may have a
            different device they view their prospective order on.
          </p>
        </div>
        <p class="pt-3 pb-0 pl-6">
          b. You are eligible to return the product only if You return the
          products in the same condition in which You received them.
        </p>
        <p class="pt-3 pb-0 pl-6">
          c. Pack the product properly and label the product with the order
          number, return address and Your address.
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. The product shall be returned in its original packaging.
        </p>
        <p class="pt-3 pb-0 pl-6">
          e. We do not accept liability for packages damaged during the return
          transit. It is the customer's responsibility to pack the product
          properly to prevent any damage during transit.
        </p>
        <p class="pt-3 pb-0 pl-6">
          f. Our technical support staff or customer support staff, as
          appropriate, will advise You of which method of delivery to use to
          return the products. Authorized product returns must be sent to the
          address communicated by Our authorized personnel.
        </p>
        <p class="pt-3 pb-0 pl-6">
          g. The order returned must accompany all accessories (including
          freebies) which were serviced along with the order or else the cost of
          the accessories shall be recovered from the refund amount.
        </p>
        <p class="pt-3 pb-0 pl-6">
          h. On receipt of the returned product, We will test it to identify the
          fault You have notified Us.
        </p>
        <p class="pt-3 pb-0 pl-6">
          i. A copy of the receipt or invoice shall be attached along with the
          returned product.
        </p>
        <p class="pt-3 pb-0 pl-6">
          j. You will receive an email or SMS notification at Your email address
          or mobile number provided to Us.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">8. SHIPPING COST</h1>
        <p class="pt-3 pb-0 pl-6">
          a. We provide all support to return the product. However, any cost
          incurred on the packaging or returning of the product shall be borne
          by the customer itself.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. If the customer is sending back the product, the risk of loss lies
          with the customer. Proof of postage is not proof of delivery, and You
          are therefore strongly advised to send Your package by recorded
          delivery, registered post, or courier.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">9. PRODUCTS CONTAINING YOUR DATA</h1>
        <p class="pt-3 pb-0 pl-6">
          a. In some cases, Your data may be stored in the device you are
          returning to us. It is advised to take a backup and remove all Your
          data before returning the product. We are not responsible for any loss
          or misuse of Your data.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. The privacy of your data supplied to Us during the return and
          refund procedure is also governed by our privacy policy, which can be
          accessed under the following link:
          <router-link to="/privacy-policy" class="italic"
            >therarewindow.com/privacy-policy</router-link
          >.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">10. RESPONSE TIME</h1>
        <p class="pt-3 pb-0 pl-9">
          a. Refunds are normally processed within 7 Business Days after the
          completion of quality checks of the product returned.
        </p>
        <p class="pt-3 pb-0 pl-9">
          b. Refunds are normally processed within 7 Business Days after
          checking the veracity of the refund request.
        </p>
        <p class="pt-3 pb-0 pl-9">
          c. The period of refund may also depend on various banking and payment
          channels, and We will not be liable for any errors or delays in a
          refund due to banks or third-party service providers.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">11. CANCELLATION OF RETURN REQUEST</h1>
        <p class="pt-3 pb-0 pl-9">
          A request for return or refund once made can be cancelled by
          contacting customer care at
          <a href="mailto:support@therarewindow.com" class="cursor-pointer"
            >support@therarewindow.com</a
          >.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">
          12. REFUSAL OF RETURN OR REFUND REQUEST
        </h1>
        <p class="pt-3 pb-0 pl-9">
          We reserve the right to refuse or cancel any return request if such a
          request is not in compliance with this Policy or applicable laws.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">13. CHANGES TO THIS POLICY</h1>
        <p class="pt-3 pb-0 pl-9">
          a. Please note that We may from time to time change the terms of this
          Policy and every time You wish to use this website, please check the
          Policy to ensure You understand the terms and conditions that apply at
          that time.
        </p>
        <p class="pt-3 pb-0 pl-9">
          b. If you do not wish to accept the revised Policy, you should not
          continue to use the Services. If you continue to use the Services
          after the date on which the changes, come into effect, Your use of the
          Services indicates your agreement to be bound by the new Refund
          Policy.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">14. FORCE MAJEURE</h1>
        <p class="pt-3 pb-0 pl-9">
          We shall not be considered in breach of guarantee or terms of service
          and shall not be liable to the Customer for any cessation,
          interruption, or delay in the performance of its obligations by reason
          beyond Our control including natural disasters, pandemics, fire, an
          act of God or public enemy, famine, plague, the action of the court or
          public authority, change in law, explosion, war, terrorism, armed
          conflict, labour strike, lockout, boycott or similar event beyond our
          reasonable control, whether foreseen or unforeseen.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">15. CONTACT US</h1>
        <p class="pt-3 pb-0 pl-9">
          For any feedback, concern, or query, You may please reach out to Us on
          the contact details below.
        </p>
        <p class="pt-3 pb-0 pl-9">
          Customer Care:
          <a href="mailto:support@therarewindow.com" class="cursor-pointer"
            >support@therarewindow.com</a
          >
        </p>
      </li>
    </ol>
  </div>
  <app-footer />
</template>

<script>
  import AppHeaderHome from '@/components/AppHeaderHome.vue';
  import AppFooter from '@/components/AppFooter.vue';
  export default {
    components: {
      AppHeaderHome,
      AppFooter,
    },
    mounted() {
      window.scrollTo(0, 0);
      if (
        document.cookie.includes('csrftoken') ||
        document.cookie.includes('sessionid')
      ) {
        const date = new Date(
          new Date().setTime(new Date().getUTCSeconds() + 1),
        );
        document.cookie = `csrftoken=;expires=${date};`;
        document.cookie = `sessionid=;expires=${date};`;
      }
    },
  };
</script>
