<template>
  <span class="">
    <img
      v-if="logoColor == 'white'"
      src="../../assets/logo6.svg"
      alt="logo6.svg"
      class="w-auto md:h-9"
    />
    <img
      v-else
      src="../../assets/logo6Dark.svg"
      alt="logo6Dark.svg"
      class="w-auto md:h-9"
    />
  </span>
</template>

<script>
  export default {
    name: 'AppLogo',
    props: {
      logoColor: {
        type: String,
        default: 'black',
      },
    },
  };
</script>

<style></style>
