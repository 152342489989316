<template>
  <div class="px-5 mb-4">
    <div class="mb-3 font-semibold">Choose background:</div>
    <select-option :options="options" @selectedOptionHandler="updateMap" />
  </div>
</template>

<script>
  import SelectOption from '../ui/SelectOption.vue';
  export default {
    components: { SelectOption },
    data() {
      return {
        options: [
          {
            id: 0,
            image: '',
            color: '#ffff',
            name: 'White',
            contentBgColor: '#ffff',
            contentColor: '#000000',
          },
          {
            id: 1,
            image: '',
            color: '#E9F2FF',
            name: 'Blue',
            contentBgColor: '#E9F2FF',
            contentColor: '#000000',
          },
          {
            id: 2,
            image: '',
            color: '#E9FFF7',
            name: 'Green',
            contentBgColor: '#E9FFF7',
            contentColor: '#000000',
          },
          {
            id: 3,
            image: '',
            color: '#FFF9E9',
            name: 'Yellow',
            contentBgColor: '#FFF9E9',
            contentColor: '#000000',
          },
          {
            id: 4,
            image: '',
            color: '#F9E9FF',
            name: 'Purple',
            contentBgColor: '#F9E9FF',
            contentColor: '#000000',
          },
          {
            id: 5,
            image: '',
            color: '#FFE9F6',
            name: 'Pink',
            contentBgColor: '#FFE9F6',
            contentColor: '#000000',
          },
          {
            id: 6,
            image: '',
            color: '#e5f0cf',
            name: 'Green',
            contentBgColor: '#e5f0cf',
            contentColor: '#000000',
          },
          {
            id: 7,
            image: '',
            color: '#caf5ff',
            name: 'Blue',
            contentBgColor: '#caf5ff',
            contentColor: '#000000',
          },
          {
            id: 8,
            image: '',
            color: '#f0ebeb',
            name: 'Grey',
            contentBgColor: '#f0ebeb',
            contentColor: '#000000',
          },
        ],
      };
    },
    methods: {
      updateMap(selected) {
        this.$emit('updateBg', {
          color: selected.color,
          image: selected.image,
        });
      },
    },
  };
</script>

<style></style>
