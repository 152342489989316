<template>
  <router-view />
</template>
<script>
  import { useHead } from 'unhead';
  export default {
    setup() {
      useHead({
        title: 'The Rare Window',
        meta: [
          {
            name: 'title',
            content: 'The Rare Window',
          },
        ],
      });
    },
  };
</script>
<style lang="scss"></style>
