import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      selectedOption: {
        productName: 'time capsule map',
        title: 'Your title goes here',
        subText: 'Subtext about your memory',
        basePrice: 200,
        frameSize: '8x12',
        numOfMaps: 1,
        alternativeFont: false,
        printingType: 'Single Level',
        Location: '',
        frameColor: {
          color: '#000000',
          name: 'Title',
        },
        giftingMessage: '',
        giftWrapping: false,
      },
    };
  },
  mutations,
  actions,
  getters,
};
