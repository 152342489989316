<template>
  <div>
    <app-header-home @showProducts="modalChoose = !modalChoose" />
    <app-loader :show="loader" />
    <div class="container px-4 pt-20 mx-auto">
      <base-text type="h1" class="text-center"> Blogs </base-text>
      <div class="grid gap-4 lg:grid-cols-2">
        <div class="border-2 rounded-xl m-2 p-3 lg:p-5 h-auto sm:grid-cols-2">
          <base-text type="h3">
            Why is a Personalized Star Map the Ultimate Home Decor Piece?
          </base-text>
          <p>
            A personalized star map is a unique and meaningful home decor piece
            that captures the beauty and wonder of the night sky. It not only
            gives your imagination a boost but also turns out to be an excellent
            source of happiness for you. You feel happy and motivated whenever
            you have a glance at the star map.
          </p>
          <div class="flex justify-end">
            <base-button class="w-full m-2" link to="/home-decor-piece"
              >Continue reading
            </base-button>
          </div>
        </div>
        <div class="border-2 rounded-xl m-2 p-3 lg:p-5 h-auto sm:grid-cols-2">
          <base-text type="h3">
            The Power of Words: How Personalized Wall Art Can Inspire and
            Motivate
          </base-text>
          <p class="py-2">
            Personalized wall art can be a powerful tool for inspiring and
            motivating people. The words we surround ourselves with have a
            profound effect on our thoughts, emotions, and actions. When we see
            uplifting and inspiring words every day, we are more likely to feel
            motivated to take action towards our goals and dreams.
          </p>
          <div class="flex justify-end">
            <base-button class="w-full m-2" link to="/the-power-of-words"
              >Continue reading
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </div>
</template>
<script>
  import AppHeaderHome from '../components/AppHeaderHome.vue';
  import AppLoader from '../components/AppLoader.vue';
  import BaseText from '../components/ui/BaseText.vue';
  import AppFooter from '../components/AppFooter.vue';
  import BaseButton from '../components/ui/BaseButton.vue';
  import { useHead } from 'unhead';
  export default {
    components: {
      AppHeaderHome,
      AppLoader,
      BaseText,
      AppFooter,
      BaseButton,
    },
    data() {
      return {
        modalChoose: false,
        loader: false,
      };
    },
    mounted() {
      this.deleteCookies();
      window.scrollTo(0, 0);
      useHead({
        title:
          'Blog Posts | Get Inspired, Informed, and Entertained | The Rare Window',
        meta: [
          {
            name: 'title',
            content:
              'Blog Posts | Get Inspired, Informed, and Entertained | The Rare Window',
          },
          {
            name: 'description',
            content:
              'Discover insightful and engaging blog posts on a wide range of topics. Get informed, and entertained with our thought-provoking content. Click here to explore now!',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: 'https://therarewindow.com/blog',
          },
        ],
      });
    },
    methods: {
      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },
    },
  };
</script>
