<template>
  <div class="px-5 mb-4">
    <div class="font-semibold">Frame color:</div>
    <select-option :options="options" @selectedOptionHandler="updateFrame" />
  </div>
</template>

<script>
  import SelectOption from '../ui/SelectOption.vue';
  export default {
    components: { SelectOption },
    data() {
      return {
        options: [
          {
            id: 0,
            image: '',
            color: '#000000',
            name: 'Black',
          },
          // {
          //   id: 1,
          //   image: '',
          //   color: '#ffffff',
          //   name: 'White',
          // },
          {
            id: 1,
            image: '',
            color: '#833F00',
            name: 'Brown',
          },
        ],
      };
    },
    methods: {
      updateFrame(selected) {
        this.$emit('updateFrameColor', selected);
      },
    },
  };
</script>

<style></style>
