import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      selectedOption: {
        productName: 'Journey Map',
        basePrice: 200,
        frameSize: '12x8',
        bg: {
          color: '#ffffff',
          image: '',
        },
        mapColor: '',
        Location: '',
        frameColor: {
          color: '#000000',
          name: 'Title',
        },
        numOfMaps: 5,
        printingType: 'Single Level',
        giftingMessage: '',
        giftWrapping: false,
      },
      details: {
        alternativeFont: false,
        header: 'Your header goes here',
        subHeader: 'Your subheader goes here',
        leftFooter: 'Your left footer goes here',
        mainFooter: 'Your main footer goes here',
        rightFooter: 'Your right footer goes here',
        text: [
          {
            main: 'Your map 1 title',
            sub: 'Your map 1 subtext',
            image: 'https://www.w3schools.com/tags/img_girl.jpg',
          },
          {
            main: 'Your map 2 title',
            sub: 'Your map 2 subtext',
            image: 'https://www.w3schools.com/tags/img_girl.jpg',
          },
          {
            main: 'Your map 3 title',
            sub: 'Your map 3 subtext',
            image: 'https://www.w3schools.com/tags/img_girl.jpg',
          },
          {
            main: 'Your map 4 title',
            sub: 'Your map 4 subtext',
            image: 'https://www.w3schools.com/tags/img_girl.jpg',
          },
          {
            main: 'Your map 5 title',
            sub: 'Your map 5 subtext',
            image: 'https://www.w3schools.com/tags/img_girl.jpg',
          },
        ],
      },
    };
  },
  mutations,
  actions,
  getters,
};
