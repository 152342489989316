<template>
  <div>
    <h1
      v-if="type == 'h1'"
      class="mb-4 text-5xl font-bold md:text-6xl"
      :class="addClass"
    >
      <slot></slot>
    </h1>
    <h2
      v-else-if="type == 'h2'"
      class="mb-4 text-3xl font-bold md:text-ext-h2"
      :class="addClass"
    >
      <slot></slot>
    </h2>
    <h3
      v-else-if="type == 'h3'"
      class="mb-4 text-2xl font-bold md:text-4xl"
      :class="addClass"
    >
      <slot></slot>
    </h3>
    <h4
      v-else-if="type == 'h4'"
      class="mb-4 text-xl md:text-2xl"
      :class="addClass"
    >
      <slot></slot>
    </h4>
    <h5
      v-else-if="type == 'h5'"
      class="mb-4 text-lg md:text-xl"
      :class="addClass"
    >
      <slot></slot>
    </h5>
    <h6
      v-else-if="type == 'h6'"
      class="mb-4 font-bold uppercase text-ext-gray"
      :class="addClass"
    >
      <slot></slot>
    </h6>
    <p
      v-else-if="type == 'p'"
      class="mb-4 text-base md:text-xl"
      :class="addClass"
    >
      <slot></slot>
    </p>
    <div v-else class="" :class="addClass">
      <div class="">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'light',
        required: false,
      },
      addClass: {
        type: String,
        default: null,
        required: false,
      },
    },
    data() {
      return {};
    },
  };
</script>

<style></style>
