<template>
  <div class="relative px-5 mb-8">
    <div class="mb-2 font-semibold">Search Locality / City:</div>
    <input
      type="text"
      class="pr-10 form-control"
      :class="focus && 'errorInput'"
      placeholder="Enter your location"
      v-model="query"
      @input="search"
    />
    <span v-if="loading" class="absolute right-7 top-12">
      <svg
        class="w-5 h-5 text-black animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>
    <span v-else class="absolute cursor-pointer right-7 top-11" @click="search">
      <svg
        class="ml-2"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 11H2.75V13H3.75V11ZM18.75 13H19.75V11H18.75V13ZM3.75 13H18.75V11H3.75V13Z"
          fill="#172327"
        />
        <path
          d="M14.2071 4.54289L13.5 3.83579L12.0858 5.25L12.7929 5.95711L14.2071 4.54289ZM20.25 12L20.9571 12.7071C21.3476 12.3166 21.3476 11.6834 20.9571 11.2929L20.25 12ZM12.7929 18.0429L12.0858 18.75L13.5 20.1642L14.2071 19.4571L12.7929 18.0429ZM12.7929 5.95711L19.5429 12.7071L20.9571 11.2929L14.2071 4.54289L12.7929 5.95711ZM19.5429 11.2929L12.7929 18.0429L14.2071 19.4571L20.9571 12.7071L19.5429 11.2929Z"
          fill="#172327"
        />
      </svg>
    </span>
    <ul
      v-if="result"
      class="absolute z-20 mt-1 bg-white shadow-md top-20 rounded-xl inset-x-5"
    >
      <li
        v-for="location in result"
        :key="location.place_id"
        class="p-2 cursor-pointer hover:bg-ext-yellow rounded-xl"
        @click="selectedLocation(location)"
      >
        {{ location.display_name }}
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  export default {
    data() {
      return {
        result: [],
        query: '',
        loading: false,
        focus: false,
      };
    },
    methods: {
      ...mapMutations('mapObj', ['setCenter', 'setZoom']),
      async search() {
        this.loading = true;
        await fetch(
          `https://nominatim.openstreetmap.org/search?q=${this.query}&limit=5&format=json`,
        )
          .then((res) => res.json())
          .then((data) => (this.result = data));
        this.loading = false;
      },
      selectedLocation(location) {
        this.result = [];
        this.query = location.display_name;
        this.$emit('updateCenter', {
          lon: location.lon,
          lat: location.lat,
          locationName: location.display_name,
        });
        this.focus = false;
      },
      makeFocus() {
        this.focus = true;
      },
    },
    watch: {
      query(newVal) {
        if (newVal == '') {
          this.$emit('updateCenter', null);
          this.focus = true;
        }
      },
    },
  };
</script>

<style></style>
