<template>
  <button
    v-if="!link"
    :class="
      mode == 'outlineBtn' ? '' : 'bg-ext-yellow border-ext-yellow text-dark'
    "
    class="flex justify-center w-full px-5 py-3 font-semibold border rounded-3xl sm:w-auto"
  >
    <slot></slot>
    <svg
      class="ml-2"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 11H2.75V13H3.75V11ZM18.75 13H19.75V11H18.75V13ZM3.75 13H18.75V11H3.75V13Z"
        fill="#172327"
      />
      <path
        d="M14.2071 4.54289L13.5 3.83579L12.0858 5.25L12.7929 5.95711L14.2071 4.54289ZM20.25 12L20.9571 12.7071C21.3476 12.3166 21.3476 11.6834 20.9571 11.2929L20.25 12ZM12.7929 18.0429L12.0858 18.75L13.5 20.1642L14.2071 19.4571L12.7929 18.0429ZM12.7929 5.95711L19.5429 12.7071L20.9571 11.2929L14.2071 4.54289L12.7929 5.95711ZM19.5429 11.2929L12.7929 18.0429L14.2071 19.4571L20.9571 12.7071L19.5429 11.2929Z"
        fill="#172327"
      />
    </svg>
  </button>
  <router-link
    v-else
    :to="to"
    :class="
      mode == 'outlineBtn' ? '' : 'bg-ext-yellow border-ext-yellow text-dark'
    "
    class="flex justify-center w-full px-5 py-3 font-semibold border rounded-3xl sm:w-max"
  >
    <slot></slot>
    <svg
      class="ml-2"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <template v-if="!white">
        <path
          d="M3.75 11H2.75V13H3.75V11ZM18.75 13H19.75V11H18.75V13ZM3.75 13H18.75V11H3.75V13Z"
          fill="#172327"
        />
        <path
          d="M14.2071 4.54289L13.5 3.83579L12.0858 5.25L12.7929 5.95711L14.2071 4.54289ZM20.25 12L20.9571 12.7071C21.3476 12.3166 21.3476 11.6834 20.9571 11.2929L20.25 12ZM12.7929 18.0429L12.0858 18.75L13.5 20.1642L14.2071 19.4571L12.7929 18.0429ZM12.7929 5.95711L19.5429 12.7071L20.9571 11.2929L14.2071 4.54289L12.7929 5.95711ZM19.5429 11.2929L12.7929 18.0429L14.2071 19.4571L20.9571 12.7071L19.5429 11.2929Z"
          fill="#172327"
        />
      </template>
      <template v-else>
        <path
          d="M3.75 11H2.75V13H3.75V11ZM18.75 13H19.75V11H18.75V13ZM3.75 13H18.75V11H3.75V13Z"
          fill="#ffffff"
        />
        <path
          d="M14.2071 4.54289L13.5 3.83579L12.0858 5.25L12.7929 5.95711L14.2071 4.54289ZM20.25 12L20.9571 12.7071C21.3476 12.3166 21.3476 11.6834 20.9571 11.2929L20.25 12ZM12.7929 18.0429L12.0858 18.75L13.5 20.1642L14.2071 19.4571L12.7929 18.0429ZM12.7929 5.95711L19.5429 12.7071L20.9571 11.2929L14.2071 4.54289L12.7929 5.95711ZM19.5429 11.2929L12.7929 18.0429L14.2071 19.4571L20.9571 12.7071L19.5429 11.2929Z"
          fill="#ffffff"
        />
      </template>
    </svg>
  </router-link>
</template>

<script>
  export default {
    props: {
      mode: {
        type: String,
        required: false,
        default: null,
      },
      link: {
        type: Boolean,
        required: false,
        default: false,
      },
      to: {
        type: String,
        required: false,
        default: '/',
      },
      white: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {};
    },
  };
</script>

<style></style>
