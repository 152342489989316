<template>
  <div class="px-5 mb-4">
    <div class="mb-3 font-semibold">Star Map styling:</div>
    <select-option :options="options" @selectedOptionHandler="updateMap" />
  </div>
</template>

<script>
  import SelectOption from '../ui/SelectOption.vue';
  export default {
    components: { SelectOption },
    data() {
      return {
        options: [
          {
            id: 0,
            image: 'starMapStyle/style1.png',
            color: '#000000',
            name: '',
            contentBgColor: '#FFFFFF',
            contentColor: '#000000',
          },
          {
            id: 1,
            image: 'starMapStyle/style2.png',
            color: '#eeeeee',
            name: '',
            contentBgColor: '#0f1111',
            contentColor: '#eeeeee',
          },
          {
            id: 2,
            image: 'starMapStyle/style3.png',
            color: '#eeeeee',
            name: '',
            contentBgColor: '#2c3950',
            contentColor: '#eeeeee',
          },
        ],
      };
    },
    methods: {
      updateMap(selected) {
        this.$emit('updateStarMapStyle', selected);
      },
    },
  };
</script>

<style></style>
