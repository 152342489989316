<template>
  <base-container mode="light" addClass=" relative z-10">
    <div
      class="grid gap-8 px-6 pb-4 border-b border-gray-400 md:grid-cols-12 xl:px-0"
    >
      <div class="col-span-4 lg:col-span-3">
        <div class="w-24 mb-10 md:mb-24"><app-logo /></div>

        <base-text type="h6" add-class="-mb-0">RECOGNISED BY:</base-text>
        <img
          src="../assets/images/startup.webp"
          alt="startup.jpg"
          class="mb-1"
        />
        <p>Startup India ID: DIPP97778</p>
      </div>

      <div class="lg:col-span-4"></div>

      <div class="col-span-4 lg:col-span-3">
        <base-text type="h6">CONTACT & SUPPORT</base-text>
        <ul>
          <li class="mb-3">
            <a href="mailto:support@therarewindow.com" class="underline"
              >support@therarewindow.com</a
            >
          </li>
          <li class="mb-3">
            <a
              href="https://instagram.com/the_rare_window"
              target="_blank"
              class="underline"
              >On Instagram</a
            >
          </li>
          <li class="mb-3">
            <a
              href="https://www.shiprocket.in/shipment-tracking/"
              target="_blank"
              class="underline"
              >Track Your Order</a
            >
          </li>
          <li class="mb-3">
            <router-link to="feedback" class="underline">Feedback</router-link>
          </li>
          <li class="mb-3">
            <router-link to="blog" class="underline">Blog</router-link>
          </li>
          <li class="mb-3">
            <router-link to="faq" class="underline">FAQ</router-link>
          </li>
        </ul>
      </div>

      <div class="col-span-3 lg:col-span-2">
        <base-text type="h6">OUR PRODUCTS</base-text>
        <ul>
          <li class="mb-3">
            <router-link to="story-map">Story Map</router-link>
          </li>
          <li class="mb-3">
            <router-link to="time-capsule-map">Time Capsule Map</router-link>
          </li>
          <li class="mb-3">
            <router-link to="celestial-map">Celestial Map</router-link>
          </li>
          <li class="mb-3">
            <router-link to="journey-map">Journey Map</router-link>
          </li>
          <li class="mb-3">
            <router-link to="theMapOfLove">The Map of Love</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="flex flex-col-reverse items-center justify-between px-6 mt-5 xl:px-0 md:flex-row"
    >
      <div class="text-xs text-center md:text-left">
        <p>Contributors: © MapTiler | © OpenStreetMap | © d3-celestial</p>
        <p>© {{ year }} Rarewindow Private Limited | All rights reserved</p>
      </div>
      <div class="p-2"></div>
      <ul class="text-center md:text-right">
        <li class="inline mr-2 lg:mr-5">
          <router-link :to="{ name: 'termsAndCondition' }" class="underline"
            >Terms and Condition</router-link
          >
        </li>
        <li class="inline mr-2 lg:mr-5">
          <router-link :to="{ name: 'privacyPolicy' }" class="underline"
            >Privacy policy</router-link
          >
        </li>
        <li class="inline mr-2 lg:mr-5">
          <router-link :to="{ name: 'refundPolicy' }" class="underline"
            >Refund policy</router-link
          >
        </li>
        <li class="inline">
          <router-link :to="{ name: 'shippingPolicy' }" class="underline"
            >Shipping policy</router-link
          >
        </li>
      </ul>
    </div>
  </base-container>
</template>

<script>
  import BaseContainer from './ui/BaseContainer.vue';
  import BaseText from './ui/BaseText.vue';
  import AppLogo from './ui/AppLogo.vue';
  export default {
    components: { BaseContainer, BaseText, AppLogo },
    data() {
      return {
        year: new Date().getFullYear(),
      };
    },
  };
</script>

<style></style>
