<template>
  <div>
    <app-header-home @showProducts="modalChoose = !modalChoose" />
    <app-loader :show="loader" />
    <!-- Hero Section -->
    <base-container mode="dark">
      <div class="items-center justify-between pt-20 md:flex">
        <div class="relative flex-1 px-8 xl:pr-8">
          <img
            src="../assets/images/pattern1.svg"
            alt="pattern1.svg"
            class="absolute -top-12 -left-5"
          />
          <base-text type="h1" add-class="text-white">
            Encase Unique Memories That Stay For A Lifetime
          </base-text>
          <base-text type="h4" add-class="mb-8 text-ext-light-gray">
            B2B gifting solutions with a touch of personalization and a whole
            lot of creativity.
          </base-text>
          <base-button>Contact Us</base-button>

          <div class="flex items-center my-10 md:mt-24">
            <img
              src="../assets/images/08.webp"
              alt="08.jpg"
              class="border-2 rounded-full"
            />
            <img
              src="../assets/images/09.webp"
              alt="09.jpg"
              class="-ml-8 border-2 rounded-full"
            />
            <img
              src="../assets/images/10.jpg"
              alt="10.jpg"
              class="-ml-8 border-2 rounded-full"
            />
            <div class="ml-3">
              <div class="flex">
                <img src="../assets/images/star.svg" alt="star.svg" />
                <img src="../assets/images/star.svg" alt="star.svg" />
                <img src="../assets/images/star.svg" alt="star.svg" />
                <img src="../assets/images/star.svg" alt="star.svg" />
                <img src="../assets/images/star.svg" alt="star.svg" />
              </div>
              <p class="mt-2 text-xs sm:text-base">4.8 from 100+ customers</p>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="relative px-8 md:p-0">
            <img
              src="../assets/images/b2b.gif"
              class="relative w-11/12 m-4 rounded-2xl"
              alt="b2b.gif"
            />
          </div>
        </div>
      </div>
    </base-container>

    <img
      src="../assets/images/divider.svg"
      alt="divider.svg"
      class="absolute z-10 w-full"
    />

    <base-container mode="" add-class="md:pb-16 md:pt-20">
      <div
        class="flex flex-col-reverse items-center justify-between md:flex-row"
      >
        <!-- Contact Form -->

        <div
          class="relative grid gap-8 p-4 pb-24 mx-4 bg-ext-light-pink rounded-2xl lg:p-8 lg:grid-cols-2 xl:mx-0 lg:pb-8"
        >
          <div class="relative">
            <base-text type="h2"
              >Let us know <br />
              your requirements!</base-text
            >
          </div>
          <div>
            <base-text type="h4" add-class="font-bold"
              >Some basic details</base-text
            >
            <div class="relative">
              <img
                src="../assets/images/pattern4.svg"
                alt="pattern4.svg"
                class="absolute -top-14 -right-6"
              />
              <div class="mb-6">
                <label>Name</label>
                <input
                  type="text"
                  class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                  placeholder="Enter your name"
                  :class="{ error: v$.form.name.$errors.length }"
                  v-model="v$.form.name.$model"
                />
                <div
                  v-for="(error, index) of v$.form.name.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="mb-6">
                <label>Work Email</label>
                <input
                  type="text"
                  class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                  placeholder="Enter your email"
                  :class="{ error: v$.form.email.$errors.length }"
                  v-model="v$.form.email.$model"
                />
                <div
                  v-for="(error, index) of v$.form.email.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="mb-6">
                <label>Phone no.</label>
                <input
                  type="text"
                  class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                  placeholder="Enter Phone no."
                  :class="{ error: v$.form.phone.$errors.length }"
                  v-model="v$.form.phone.$model"
                />
                <div
                  v-for="(error, index) of v$.form.phone.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="mb-6">
                <label>Product code (Optional)</label>
                <select
                  class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                  v-model="form.productsCode"
                >
                  <option value="">Select Product Code</option>
                  <option v-for="product in products" :key="product.Product_id">
                    {{ product.product_code }}
                  </option>
                  <option value="Custom Design">Custom Design</option>
                </select>
              </div>

              <div class="mb-6">
                <label>Approx quantity (Optional)</label>
                <input
                  type="number"
                  class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                  v-model="form.quantity"
                  placeholder="Quantity"
                />
              </div>

              <base-button class="w-full mr-2" @click.prevent="submitReq"
                >Submit now</base-button
              >
            </div>
            <img
              src="../assets/images/pattern5.svg"
              alt="pattern5.svg"
              class="absolute -bottom-12 -left-5"
            />
          </div>
        </div>
      </div>
    </base-container>
    <!-- Know more about our products -->
    <base-container mode="light" add-class="md:pb-16 md:pt-20">
      <base-text type="h2" addClass="mx-auto max-w-lg text-center pb-5"
        >Explore Corporate Gifting Solutions
      </base-text>
      <div class="grid gap-3 px-5 md:grid-cols-12">
        <div
          v-for="product in products"
          :key="product.Product_id"
          class="col-span-3 p-2 border-2 rounded-xl"
        >
          <img
            :src="product?.product_image"
            alt="product-image"
            class="object-cover w-full h-56 mb-2 rounded-xl"
          />
          <h3 class="font-bold">{{ product?.product_code }}</h3>
          <p class="my-2 text-sm">
            {{ product?.description }}
          </p>
          <div class="flex justify-between mb-2 text-sm">
            <div class="text-ext-gray">Product code:</div>
            <div class="text-dark">{{ product?.product_code }}</div>
          </div>
          <div class="flex justify-between mb-2 text-sm">
            <div class="text-ext-gray">Size:</div>
            <div class="text-dark">{{ product?.frameSize }}"</div>
          </div>
        </div>
      </div>
    </base-container>

    <!-- <img
      src="../assets/images/dividerBottomLight.svg"
      alt=""
      class="absolute z-10 w-full"
    /> -->

    <img
      src="../assets/images/dividerTop.svg"
      alt="dividerTop.svg"
      class="relative z-10 w-full"
    />

    <!-- Testimonials -->
    <base-container mode="dark" add-class="relative md:pb-16 md:pt-20">
      <img
        src="../assets/images/pattern2.png"
        alt="pattern2.png"
        class="absolute right-0 top-2"
      />
      <UserReview />
      <img
        src="../assets/images/pattern3.png"
        alt="pattern3.png"
        class="absolute left-0 bottom-2"
      />
    </base-container>

    <img
      src="../assets/images/divider.svg"
      alt="divider.svg"
      class="absolute z-10 w-full"
    />

    <!-- How it works -->
    <base-container add-class="mx-8 md:pb-16 md:pt-20 xl:mx-0">
      <base-text type="h2" add-class="text-center"> How it works </base-text>
      <base-text type="p" add-class="mx-auto mb-8 text-center md:w-1/2">
        Tell us a date or time of significance to you and your loved one; we
        will eternalize it with a custom map - a one-of-a-kind souvenir.
      </base-text>
      <div class="grid gap-8 lg:grid-cols-3 mt-14">
        <div>
          <img
            src="../assets/images/icon_customize.svg"
            alt="icon_customize.svg"
            class="mb-3"
          />
          <base-text type="h3"> Choose a map </base-text>
          <base-text type="p" add-class="opacity-60">
            We have 5 amazing personalized maps to choose from.
          </base-text>
        </div>
        <div>
          <img
            src="../assets/images/icon_personal.svg"
            alt="icon_personal.svg"
            class="mb-3"
          />
          <base-text type="h3"> Personalize </base-text>
          <base-text type="p" add-class="opacity-60">
            Add a touch of personalization as per your liking.
          </base-text>
        </div>
        <div>
          <img
            src="../assets/images/icon_variety.svg"
            alt="icon_variety.svg"
            class="mb-3"
          />
          <base-text type="h3"> Pick a format </base-text>
          <base-text type="p" add-class="opacity-60">
            Choose from a digital or physical version of the map.
          </base-text>
        </div>
        <div></div>
      </div>
    </base-container>

    <img
      src="../assets/images/dividerTop.svg"
      alt="dividerTop.svg"
      class="relative z-10 w-full"
    />

    <!-- Why choose us -->
    <base-container mode="dark" add-class="relative md:pb-16 md:pt-20">
      <div class="flex flex-col items-center justify-between lg:flex-row">
        <div class="flex-1">
          <div class="relative lg:w-5/6">
            <img
              src="../assets/images/pattern7.webp"
              class="absolute right-0 block w-1/4 -top-8"
              alt="pattern7.png"
            />
            <img
              src="../assets/images/why_choose_us.svg"
              class="relative w-11/12 m-4 rounded-2xl"
              alt="why_choose_us.svg"
            />
            <img
              src="../assets/images/pattern6.svg"
              class="absolute left-0 w-1/4 -bottom-2"
              alt="pattern6.svg"
            />
          </div>
        </div>
        <div class="flex-1 mx-8 xl:mx-0">
          <base-text type="h2" add-class="mb-6"> Why choose us? </base-text>
          <div class="flex items-start mb-6">
            <img
              src="../assets/images/check.svg"
              class="mr-2"
              alt="check.svg"
            />
            <base-text type="p">
              <strong>Personalize Gifts Redefined:</strong>
              <span class="text-ext-gray"
                >Personalized gifts have a way of touching one’s heart. They are
                thoughtful, adorable, and memorable, just like your story!</span
              >
            </base-text>
          </div>
          <div class="flex items-start mb-6">
            <img
              src="../assets/images/check.svg"
              class="mr-2"
              alt="check.svg"
            />
            <base-text type="p">
              <strong>Unique Gifts Like no Other: </strong>
              <span class="text-ext-gray"
                >A memory is unique, your gift should be too! Customized gifts
                are unique, irreplaceable and special!
              </span>
            </base-text>
          </div>
          <div class="flex items-start mb-6">
            <img
              src="../assets/images/check.svg"
              class="mr-2"
              alt="check.svg"
            />
            <base-text type="p">
              <strong>Creative Gifts For Your Special One:</strong>
              <span class="text-ext-gray"
                >Surprise your loved one with an out-of-the-box gift that takes
                them back in time to a memory they love!
              </span>
            </base-text>
          </div>
          <div class="flex items-start mb-6">
            <img
              src="../assets/images/check.svg"
              class="mr-2"
              alt="check.svg"
            />
            <base-text type="p">
              <strong>Capture Memories & Live Them Every Day: </strong>
              <span class="text-ext-gray"
                >Personalized maps perfectly capture the lively details of your
                memory and help you relive them every day!</span
              >
            </base-text>
          </div>
          <div class="flex flex-wrap">
            <base-button class="my-2 mr-2">Contact Us</base-button>
            <a
              href="https://instagram.com/the_rare_window"
              target="_blank"
              class="flex justify-center w-full px-5 py-3 my-2 font-semibold border rounded-3xl sm:w-max"
            >
              <img
                src="../assets/images/insta.svg"
                class="mr-2"
                alt="insta.svg"
              />
              We are on Instagram
            </a>
            <!-- <base-button
              mode="outlineBtn"
              white
              class="my-2"
              to="https://instagram.com/the_rare_window"
              link
              ><img src="../assets/images/insta.svg" class="mr-2" /> We are on
              Instagram</base-button
            > -->
          </div>
        </div>
      </div>
    </base-container>

    <img
      src="../assets/images/divider.svg"
      alt="divider.svg"
      class="absolute z-10 w-full"
    />

    <!-- Our story -->
    <base-container id="ourStory" add-class="md:pb-16 md:pt-20">
      <div class="flex flex-col items-center justify-between lg:flex-row">
        <div class="flex-1">
          <img
            src="../assets/images/ourStory.webp"
            class="relative xl:-left-10"
            alt="ourStory.png"
          />
        </div>
        <div class="flex-1 mx-8 xl:mx-0">
          <base-text type="h2"> Our story </base-text>
          <base-text type="p" add-class="mb-6">
            It all started with a memory, a love story that was destined to be
            etched in the sands of time and in our very first maps. Rahul &
            Megha walked along the banks of the Brahmaputra river on a starry
            night, and the gentle sea breeze whispered into their ears. To mark
            their love and preserve this perfect memory, they conceptualized
            love maps as we know them today - giving birth to The Rare Window.
          </base-text>
          <div class="flex flex-wrap">
            <base-button class="my-2 mr-2">Contact Us</base-button>
            <base-button
              mode="outlineBtn"
              to="#"
              link
              class="my-2 border-dark"
              @click="modalTeam = !modalTeam"
              >Know the team</base-button
            >
          </div>
        </div>
      </div>
    </base-container>

    <app-footer />

    <!-- Choose product modal -->
    <base-modal
      :show-modal="modalChoose"
      class="text-center"
      @closeModal="modalChoose = !modalChoose"
    >
      <products-in-modal />
    </base-modal>

    <!-- Our Team modal -->
    <base-modal
      :show-modal="modalTeam"
      class="text-center"
      @closeModal="modalTeam = !modalTeam"
    >
      <base-text type="h2">Meet the team</base-text>
      <div class="my-5">
        <div class="inline-flex mx-auto">
          <div
            v-for="(member, index) in teamMembers"
            :key="index"
            class="py-4 mx-3 md:mx-10"
          >
            <img
              :src="require(`../assets/images/${member.img}`)"
              :alt="`member${index + 1}`"
              class="mx-auto rounded-full"
            />
            <base-text type="h4" class="mt-3 font-semibold">{{
              member.name
            }}</base-text>
            <p class="my-3">{{ member.position }}</p>
          </div>
        </div>
        <p class="mx-auto my-3 text-ext-gray lg:max-w-xl">
          We started The Rare Window to create products that bring that warm
          fuzzy feeling inside when you see them, and let you love the places
          where you spend your time in just a little bit more.
        </p>
        <base-button
          class="mx-auto"
          @click="
            modalChoose = !modalChoose;
            modalTeam = !modalTeam;
          "
        >
          Create now
        </base-button>
      </div>
    </base-modal>
  </div>
</template>

<script>
  import AppHeaderHome from '../components/AppHeaderHome.vue';
  import BaseContainer from '../components/ui/BaseContainer.vue';
  import BaseText from '../components/ui/BaseText.vue';
  import BaseButton from '../components/ui/BaseButton.vue';
  import AppLoader from '../components/AppLoader.vue';
  import AppFooter from '../components/AppFooter.vue';
  import UserReview from '../components/UserReview.vue';
  import ProductsInModal from '../components/ProductsInModal.vue';
  import BaseModal from '../components/ui/BaseModal.vue';
  import { useHead } from 'unhead';

  import useVuelidate from '@vuelidate/core';
  import {
    required,
    email,
    numeric,
    minLength,
    maxLength,
  } from '@vuelidate/validators';
  import { validName } from '../Helper/Helper.js';

  export default {
    name: 'HomeView',
    components: {
      AppHeaderHome,
      AppLoader,
      BaseContainer,
      BaseText,
      BaseButton,
      UserReview,
      AppFooter,
      BaseModal,
      ProductsInModal,
    },
    data() {
      return {
        products: null,
        loader: false,
        form: {
          name: '',
          email: '',
          phone: null,
          productsCode: '',
          quantity: null,
        },
        slickSettings: {
          arrows: true,
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
              },
            },
          ],
        },
        modalChoose: false,
        modalTeam: false,
        teamMembers: [
          {
            img: 'ProfilePicAkansha.jpg',
            name: 'Akansha Loya',
            position: 'Founder',
            linkedin: '#',
          },
          {
            img: 'ProfilePicAkshay.jpg',
            name: 'Akshay Loya',
            position: 'Co-founder',
            linkedin: '#',
          },
        ],
      };
    },

    validations() {
      return {
        form: {
          email: {
            required,
            email,
          },
          name: {
            required,
            name_validation: {
              $validator: validName,
              $message:
                'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
            },
          },
          phone: {
            required,
            numeric,
            min: minLength(10),
            max: maxLength(10),
          },
        },
      };
    },
    setup() {
      return { v$: useVuelidate() };
    },
    created() {
      // document.title = 'B2B Gifting Solutions';
      this.getProducts();
    },
    mounted() {
      useHead({
        title: 'B2B Gifting Solutions',
        meta: [
          {
            name: 'title',
            content: 'The Best B2B Gifting Solutions | The Rare Window ',
          },
          {
            name: 'description',
            content:
              'Unique and affordable B2B gifting solutions with a personal touch. Explore the best personalized corporate gifts online.',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: 'https://therarewindow.com/b2b',
          },
        ],
      });
      window.scrollTo(0, 0);
      this.deleteCookies();
    },
    methods: {
      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },
      async getProducts() {
        this.deleteCookies();
        await fetch('https://therarewindow.com/api/getB2BProduct/').then(
          (res) => {
            if (res.ok) {
              res.json().then((res) => (this.products = res.data));
            }
          },
        );
      },
      async submitReq() {
        this.deleteCookies();
        const result = await this.v$.$validate();
        if (!result) {
          return;
        } else {
          this.loader = true;
          var myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');

          let payload = JSON.stringify({
            name: this.form.name,
            email: this.form.email,
            phone_no: this.form.phone,
            product_code: this.form.productsCode,
            quantity: this.form.quantity,
          });
          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: payload,
          };
          await fetch(
            'https://therarewindow.com/api/b2bRequirementRequest/',
            requestOptions,
          )
            .then((res) => {
              if (res.ok) {
                res.json().then((data) => {
                  console.log(data);
                  this.v$.$reset();
                  this.form = {
                    name: '',
                    email: '',
                    phone: '',
                    product_code: '',
                    quantity: '',
                  };
                  this.$toast.open({
                    message:
                      'We appreciate you contacting us. One of our colleagues will get back in touch with you soon!Have a great day!',
                    type: 'success',
                    duration: 5000,
                    dismissible: true,
                    position: 'top',
                  });
                  this.loader = false;
                });
              } else {
                res.json().then((err) => console.log(err));
                this.$toast.open({
                  message: 'Request not submited due to some error.',
                  type: 'error',
                  duration: 5000,
                  dismissible: true,
                });
                this.loader = false;
              }
            })
            .catch((error) => console.log('error: ', error));
        }
      },
    },
  };
</script>
