<template>
  <div
    v-if="showModal"
    class="fixed inset-0 z-50 flex items-center justify-center p-2 bg-ext-yellow bg-opacity-90"
  >
    <div
      class="relative p-5 pt-12 overflow-hidden md:p-12 rounded-2xl"
      :class="
        white
          ? 'bg-ext-light-pink text-dark ' + widthClass
          : 'text-white bg-dark ' + widthClass
      "
    >
      <img
        v-if="!white"
        src="../../assets/images/patternModalTL.png"
        alt="patternModalTL.png"
        class="absolute top-0 left-0"
      />
      <img
        v-else
        src="../../assets/images/patternCyanRound.svg"
        alt="patternCyanRound.svg"
        class="absolute w-24 -top-5 -left-5"
      />
      <img
        v-if="!white"
        src="../../assets/images/patternModalBR.png"
        alt="patternModalBR.png"
        class="absolute bottom-0 right-0"
      />
      <img
        v-else
        src="../../assets/images/pattern7.webp"
        alt="pattern7.png"
        class="absolute w-24 -bottom-5 -right-5"
      />
      <img
        src="../../assets/images/icon_close.svg"
        alt="icon_close.svg"
        class="absolute top-0 right-0 z-20 cursor-pointer"
        @click="$emit('closeModal')"
      />
      <div class="z-10 relative container max-h-[80vh] overflow-auto">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      showModal: {
        type: Boolean,
        default: false,
        require: false,
      },
      widthClass: {
        type: String,
      },
      white: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style></style>
