import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Map, Marker } from 'maplibre-gl';

export default {
  async createImg(context) {
    return new Promise((resolve, reject) => {
      let frameWidth = 0;
      let frameHeight = 0;
      let selectedOptions = context.rootGetters['momentMap/selectedOption'];
      if (context.rootGetters['momentMap/selectedFrameSize'] == '8x12') {
        frameWidth = 768;
        frameHeight = 1152;
      } else if (context.rootGetters['momentMap/selectedFrameSize'] == '8x8') {
        frameWidth = 768;
        frameHeight = 768;
      }
      let map = context.rootGetters['mapObj/getMap'];
      const extent = context.rootGetters['mapObj/getExtent'];
      const mapMarker = context.rootGetters['mapObj/getMapMarker'];
      const mapStyle = context.rootGetters['mapObj/getMapStyle'];

      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [frameWidth, frameHeight],
      });
      let docWidth = doc.internal.pageSize.getWidth();
      let docHeight = doc.internal.pageSize.getHeight();

      let frame = document.createElement('div');
      frame.style.height = docHeight + 'px';
      frame.style.width = docWidth + 'px';
      frame.className = 'p-16 printFrame';
      document.body.appendChild(frame);

      let parent = document.createElement('div');
      parent.className = 'relative flex flex-col w-full h-full';
      frame.appendChild(parent);

      let mapwrap = document.createElement('div');
      mapwrap.className = 'border border-black map-wrap ';
      parent.appendChild(mapwrap);

      let img = document.createElement('img');
      mapwrap.appendChild(img);

      let textStrip = document.createElement('div');
      textStrip.className = 'h-40 textStrip';
      parent.appendChild(textStrip);

      let textH2 = document.createElement('h2');
      textH2.appendChild(document.createTextNode(selectedOptions.title));
      textH2.className = !selectedOptions.alternativeFont
        ? 'printContent relative -top-4 font-bold text-3xl mb-1 font-ext-ScriptPTbold'
        : 'printContent relative -top-4 font-bold text-2xl mb-1 font-bold mb-1';
      textStrip.appendChild(textH2);

      let subText = document.createElement('small');
      subText.appendChild(document.createTextNode(selectedOptions.subText));
      subText.className = 'relative text-lg printContent -top-2';
      textStrip.appendChild(subText);

      const hidden = document.createElement('div');
      hidden.className = 'hidden-map';
      document.body.appendChild(hidden);
      const container = document.createElement('div');
      container.style.width = docWidth * 1.3 + 'px';
      container.style.height = docHeight * 1.3 - 200 + 'px';
      hidden.appendChild(container);

      const rendermap = new Map({
        container: container,
        style: mapStyle[0].mapTileApi,
        center: map.getCenter(),
        zoom: map.getZoom(),
        attributionControl: false,
        zoomControl: false,
      });
      rendermap.fitBounds(extent[0]);

      let markerIcon = document.createElement('img');
      markerIcon.src = require(`../../../assets/images/${mapMarker[0].image}`);
      if (mapMarker[0].type == 'character') {
        markerIcon.className = 'w-1/2 h-auto';
      } else {
        markerIcon.className = 'w-1/5 h-auto';
      }

      rendermap.once('idle', () => {
        new Marker(markerIcon)
          .setLngLat(rendermap.getCenter())
          .addTo(rendermap);
        html2canvas(
          document.getElementsByClassName('hidden-map')[0].children[0],
          {
            useCORS: true,
            backgroundColor: null,
            allowTaint: true,
          },
        ).then((mapCanvas) => {
          img.src = mapCanvas.toDataURL('image/png');
          rendermap.remove();
          hidden.parentNode?.removeChild(hidden);

          let imgPayload = {
            final: null,
            base: null,
            map: null,
          };
          html2canvas(frame, {
            useCORS: true,
            allowTaint: true,
            scale: 2,
          }).then(
            (finalCanvas) => {
              let previewImg = finalCanvas.toDataURL('image/png');
              context.commit('checkout/setPreviewImg', previewImg, {
                root: true,
              });
              imgPayload.final = previewImg;

              img.style.visibility = 'hidden';
              html2canvas(frame, {
                useCORS: true,
                allowTaint: true,
                scale: 2,
              }).then(
                (baseCanvas) => {
                  img.style.visibility = 'visible';
                  imgPayload.base = baseCanvas.toDataURL('image/png', 1.0);

                  let content = document.querySelectorAll('.printContent');
                  for (let x = 0; x < content.length; x++) {
                    content[x].style.visibility = 'hidden';
                  }
                  html2canvas(frame, {
                    useCORS: true,
                    allowTaint: true,
                    scale: 2,
                  }).then(
                    (mapCanvas) => {
                      imgPayload.map = mapCanvas.toDataURL('image/png', 1.0);
                      document.body.removeChild(frame);
                      context.commit('checkout/setImages', imgPayload, {
                        root: true,
                      });
                      resolve(imgPayload);
                    },
                    (error) => {
                      reject(error);
                    },
                  );
                },
                (error) => {
                  reject(error);
                },
              );
            },
            (error) => {
              reject(error);
            },
          );
        });
      });
    });
  },
};
