<template>
  <div
    class="absolute inset-0 starMap"
    :class="mapShape?.name == 'Square' && 'border border-black'"
    ref="starMapContainer"
    :id="mapId"
  >
    <map-shape :shape="mapShape" />
    <!-- <div class="fixed top-0 left-0 z-20 w-1/2 text-sm">Test: {{ config }}</div>  -->
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  // import celestial from "d3-celestial";
  import MapShape from '@/components/map/MapShape.vue';
  // const Celestial = celestial.Celestial();
  export default {
    components: {
      MapShape,
    },
    props: {
      mapShape: {
        type: Object,
      },
      location: {
        type: Object,
      },
      date: {
        type: Date,
      },
      time: {
        type: String,
      },
      color: {
        type: String,
      },
      mapId: {
        type: String,
      },
      propername: {
        type: Boolean,
      },
      lines: {
        type: Boolean,
      },
    },
    data() {
      return {
        lat: 35,
        lng: -105,
        hours_offset: 0,
        timezone: 'America/Denver',
        config: {
          container: this.mapId,
          width: 0,
          projection: 'stereographic', // Map projection used: airy, aitoff, armadillo, august, azimuthalEqualArea, azimuthalEquidistant, baker, berghaus, boggs, bonne, bromley, collignon, craig, craster, cylindricalEqualArea, cylindricalStereographic, eckert1, eckert2, eckert3, eckert4, eckert5, eckert6, eisenlohr, equirectangular, fahey, foucaut, ginzburg4, ginzburg5, ginzburg6, ginzburg8, ginzburg9, gringorten, hammer, hatano, healpix, hill, homolosine, kavrayskiy7, lagrange, larrivee, laskowski, loximuthal, mercator, miller, mollweide, mtFlatPolarParabolic, mtFlatPolarQuartic, mtFlatPolarSinusoidal, naturalEarth, nellHammer, orthographic, patterson, polyconic, rectangularPolyconic, robinson, sinusoidal, stereographic, times, twoPointEquidistant, vanDerGrinten, vanDerGrinten2, vanDerGrinten3, vanDerGrinten4, wagner4, wagner6, wagner7, wiechel, winkel3
          // transform: 'equatorial', // Coordinate transformation: equatorial (default), ecliptic, galactic, supergalactic
          controls: false, // zoom controls

          // geopos: [this.lat, this.lng],
          orientationfixed: true,
          follow: 'zenith',
          disableAnimations: false,
          zoomlevel: 3,
          interactive: false,
          stars: {
            colors: false,
            size: 3, // ***change star size
            limit: 6,
            exponent: -0.26,
            designation: false,
            style: { fill: '#000000', opacity: 1 }, // *** Change star color.
            propername: false,
            propernameType: 'name',
            propernameStyle: {
              fill: '#172327',
              font: `10px Helvetica, Arial, sans-serif`,
              align: 'center',
              baseline: 'center',
            },
            propernameLimit: 2.0,
          },
          dsos: {
            show: false,
            size: 6,
            designation: false,
            propername: false,
            data: 'dsos.6.json',
          },
          constellations: {
            names: false, // Show constellation names
            namesType: 'en', // Type of name Latin (iau, default), 3 letter designation (desig) or other language (see list below)
            nameStyle: {
              fill: '#000000',
              align: 'center',
              baseline: 'middle',
              font: [
                '14px Helvetica, Arial, sans-serif', // Style for constellations
                '12px Helvetica, Arial, sans-serif', // Different fonts for diff.
                '11px Helvetica, Arial, sans-serif',
              ],
            }, // ranked constellations
            lines: true, // Show constellation lines, style below
            lineStyle: { stroke: '#000000', width: 1, opacity: 0.6 }, // ***Line width and color
            bounds: false, // Show constellation boundaries, style below
            boundStyle: {
              stroke: '#cccc00',
              width: 0.5,
              opacity: 0.8,
              dash: [2, 4],
            },
          },
          planets: {
            show: false,
            which: [
              'sol',
              'mer',
              'ven',
              'ter',
              'lun',
              'mar',
              'jup',
              'sat',
              'ura',
              'nep',
            ],
            // Font styles for planetary symbols
            symbolType: 'disk',
            symbols: {
              // Character and color for each symbol in 'which', simple circle \u25cf
              sol: {
                symbol: '\u2609',
                letter: 'Su',
                fill: '#ffff00',
                size: '24',
              },
              mer: { symbol: '\u263F', letter: 'Me', fill: '#cccccc' },
              ven: { symbol: '\u2640', letter: 'V', fill: '#eeeecc' },
              ter: { symbol: '\u2295', letter: 'T', fill: '#00ccff' },
              lun: {
                symbol: '\u25CF',
                letter: 'L',
                fill: '#ffffff',
                size: '24',
              }, // overridden by generated crecent, except letter & size
              mar: { symbol: '\u2642', letter: 'Ma', fill: '#ff6600' },
              cer: { symbol: '\u26B3', letter: 'C', fill: '#cccccc' },
              ves: { symbol: '\u26B6', letter: 'Ma', fill: '#cccccc' },
              jup: { symbol: '\u2643', letter: 'J', fill: '#ffaa33' },
              sat: { symbol: '\u2644', letter: 'Sa', fill: '#ffdd66' },
              ura: { symbol: '\u2645', letter: 'U', fill: '#66ccff' },
              nep: { symbol: '\u2646', letter: 'N', fill: '#6666ff' },
              plu: { symbol: '\u2647', letter: 'P', fill: '#aaaaaa' },
              eri: { symbol: '\u26AA', letter: 'E', fill: '#eeeeee' },
            },
            names: true,
            nameStyle: {
              fill: '#cccccc',
              font: "17px 'Lucida Sans Unicode', 'DejaVu Sans'",
              align: 'right',
              baseline: 'top',
            },
            namesType: 'desig',
          },
          mw: {
            show: false,
          },
          lines: {
            // Display & styles for graticule & some planes
            graticule: {
              show: true,
              stroke: '#cccccc',
              width: 2,
              opacity: 0.8,
              // grid values: "outline", "center", or [lat,...] specific position
              lon: {
                pos: [''],
                fill: '#eee',
                font: '10px Helvetica, Arial, sans-serif',
              },
              // grid values: "outline", "center", or [lon,...] specific position
              lat: {
                pos: [''],
                fill: '#eee',
                font: '10px Helvetica, Arial, sans-serif',
              },
            },
            equatorial: {
              show: true,
              stroke: '#aaaaaa',
              width: 1.3,
              opacity: 0.7,
            },
            ecliptic: {
              show: false,
              stroke: '#66cc66',
              width: 1.3,
              opacity: 0.7,
            },
            galactic: {
              show: false,
              stroke: '#cc6666',
              width: 1.3,
              opacity: 0.7,
            },
            supergalactic: {
              show: false,
              stroke: '#cc66cc',
              width: 1.3,
              opacity: 0.7,
            },
          },
          background: {
            // Background style
            fill: '#ffffff', // ***Area fill
            opacity: 1,
            stroke: 'rgba(0,0,0,0.5)', // ***Outline
            width: 1.5,
          },
        },
      };
    },
    computed: {
      ...mapGetters('celestialMap', ['getCelestial', 'getDesign']),
      ...mapGetters('mapObj', ['getCenter']),
    },
    created() {
      if (this.getDesign[0].starBg == '#FFFFFF') {
        this.config.background.fill = this.getDesign[0].starBg;
        this.config.stars.propernameStyle.fill =
          this.$route.name == 'theMapOfLove' ? '#b73152' : '#0f1111';
        this.config.stars.style.fill =
          this.$route.name == 'theMapOfLove' ? '#b73152' : '#0f1111';
        this.config.constellations.lineStyle.stroke =
          this.$route.name == 'theMapOfLove' ? '#b73152' : '#0f1111';
        this.config.constellations.nameStyle.fill =
          this.$route.name == 'theMapOfLove' ? '#b73152' : '#0f1111';
        if (this.$route.name == 'theMapOfLove') {
          this.config.lines.graticule.stroke = '#b73152';
          this.config.constellations.lineStyle.width = 2;
          this.config.stars.size = 4;
          if (window.screen.width <= 550) {
            this.config.lines.graticule.width = 0.3;
            this.config.constellations.lineStyle.width = 0.5;
            this.config.stars.size = 1;
          }
        }
      } else {
        this.config.background.fill = this.getDesign[0].starBg;
        this.config.stars.propernameStyle.fill = '#eeeeee';
        this.config.stars.style.fill = '#eeeeee';
        this.config.constellations.lineStyle.stroke = '#eeeeee';
        this.config.constellations.nameStyle.fill = '#eeeeee';
      }
      this.config.stars.propername = this.getDesign[0].propername;
      this.config.stars.designation = this.getDesign[0].propername;
      this.config.constellations.names = this.getDesign[0].propername;
    },
    mounted() {
      // this.lat = location.lat;
      // this.lng = location.lon;
      this.$nextTick(() => {
        setTimeout(() => {
          this.createCelestial();
        }, 500);
        // this.now();
      });
      this.setStarMapDiv(this.$refs.starMapContainer);
    },
    unmounted() {
      this.getCelestial.clear();
    },
    methods: {
      ...mapMutations('celestialMap', ['setStarMapDiv']),
      createCelestial() {
        this.getCelestial.display(this.config);
        this.getCelestial.location([
          this.getCenter[0].lat,
          this.getCenter[0].lon,
        ]);
        this.getCelestial.date(this.date);
        if (this.time) {
          this.setTime(this.time);
        }
      },
      setTime(newVal) {
        let date = this.date;

        newVal = newVal.split(':');

        date.setHours(date.getHours() + parseInt(newVal[0])); // include offset

        date.setMinutes(date.getMinutes() + parseInt(newVal[1]));
        this.getCelestial.date(date);
      },
    },
    watch: {
      location: {
        handler(newVal) {
          if (newVal) {
            this.getCelestial.location([newVal.lat, newVal.lon]);
          }
        },
        deep: true,
      },
      date(newVal) {
        if (newVal) {
          this.getCelestial.date(newVal);
        }
      },
      time(newVal) {
        if (newVal) {
          this.setTime(newVal);
        }
      },
      color(newVal) {
        this.config.background = { fill: newVal };
        if (newVal == '#FFFFFF') {
          this.config.stars.propernameStyle.fill = '#0f1111';
          this.config.stars.style.fill = '#0f1111';
          this.config.constellations.lineStyle.stroke = '#0f1111';
          this.config.constellations.nameStyle.fill = '#0f1111';
        } else {
          this.config.stars.propernameStyle.fill = '#eeeeee';
          this.config.stars.style.fill = '#eeeeee';
          this.config.constellations.lineStyle.stroke = '#eeeeee';
          this.config.constellations.nameStyle.fill = '#eeeeee';
        }
        setTimeout(() => {
          this.getCelestial.reload(this.config);
        }, 1000);
      },
      propername(newVal) {
        this.config.stars.propername = newVal;
        this.config.stars.designation = newVal;
        this.config.constellations.names = newVal;
        setTimeout(() => {
          this.getCelestial.reload(this.config);
        }, 1000);
      },
    },
  };
</script>

<style scoped>
  .starMap {
    height: auto !important;
  }
</style>
