export default {
  mapDataUrl(state) {
    return state.mapDataUrl;
  },
  getMap(state) {
    return state.map;
  },
  getMapFrame(state) {
    return state.mapFrame;
  },
  getCenter(state) {
    return state.center;
  },
  getLocationName(state) {
    return state.locationName;
  },
  getZoom(state) {
    return state.zoom;
  },
  getExtent(state) {
    return state.extent;
  },
  getMapMarker(state) {
    return state.mapMarker;
  },
  getMapStyle(state) {
    return state.mapstyle;
  },
  getMarkerLabel(state) {
    return state.markerLabel;
  },
  getCountry(state) {
    return state.country;
  },
};
