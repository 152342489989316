<template>
  <div class="min-h-screen bg-ext-light-pink">
    <AppHeader />
    <app-loader :show="loader" />
    <div class="absolute bottom-0 w-full lg:flex top-16">
      <div class="self-center flex-grow hidden lg:block overflow-auto h-full">
        <h1 class="mb-4 text-2xl font-bold md:text-3xl flex justify-center">
          Location Map Gift
        </h1>
        <StoryPreview :num-of-map="selectedOption.numOfMaps" />
      </div>
      <div
        class="flex-shrink bg-white relative xl:w-[512px] lg:w-[380px] sideBar"
      >
        <ul
          class="fixed z-10 grid w-full grid-cols-5 mb-6 text-sm font-semibold text-center bg-white lg:grid-cols-4 sm:text-base shadow-ext-bottom-2 lg:relative lg:w-auto"
        >
          <li
            class="py-2 cursor-pointer lg:hidden"
            :class="
              previewSelected
                ? 'border-b-2 border-ext-yellow bg-ext-yellow text-white'
                : 'bg-dark text-white'
            "
            @click="selectedTab = 'preview'"
          >
            <img
              src="../assets/images/eyeIcon.svg"
              alt=""
              class="mx-auto mb-2"
            />
            Preview*
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              structureSelected || lastActiveTab == 'structure'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              :src="
                tabStructureValidated
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/iconStructure.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Structure
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              designSelected || lastActiveTab == 'design'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              :src="
                tabDesignValidated
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/tabDesign.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Design
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              detailsSelected || lastActiveTab == 'details'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              :src="
                tabDetailsValidated
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/tabDetails.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Details
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              formatSelected || lastActiveTab == 'format'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              src="../assets/images/tabFormat.svg"
              alt=""
              class="mx-auto mb-1"
            />
            Format
          </li>
        </ul>
        <div
          ref="options"
          class="absolute inset-x-0 bottom-0 pb-16 overflow-y-auto top-24"
          :class="previewSelected ? 'hidden' : ''"
        >
          <div v-show="structureSelected">
            <map-type />
            <number-of-maps @updated-num="(num) => setNumberOfMaps(num)" />
            <map-shape @update-map-shape="(shape) => setMapShape(shape)" />
            <choose-bg @update-bg="(bg) => setBg(bg)" />
          </div>
          <div v-show="designSelected">
            <base-card
              class="m-5"
              v-for="index in getNumOfMap"
              :key="index"
              :open="index == 1 ? true : false"
              @update-open="resizeMap(index - 1)"
            >
              <template #icon
                ><img src="@/assets/images/marker.svg"
              /></template>
              <template #title>Map {{ index }} Design</template>
              <template #body>
                <div
                  v-show="mobileView"
                  class="relative w-full mb-4 -mt-5 h-44"
                >
                  <map-component
                    :api="getMapStyle[index - 1].mapTileApi"
                    :marker="getMapMarker[index - 1]"
                    :markerIndex="getMapMarker[index - 1].index"
                    :markerType="getMapMarker[index - 1].type"
                    :center="getCenter[index - 1]"
                    :index="index - 1"
                    showNav
                    ref="mapComponent"
                  />
                </div>
                <location-input
                  ref="locationInput"
                  @update-center="
                    (center) => {
                      if (center) {
                        updateLocation(center, index);
                        locationAdded[index - 1] = true;
                      } else {
                        locationAdded[index - 1] = false;
                      }
                    }
                  "
                />
                <map-styling
                  @update-style="(style) => updateMapStyle(style, index)"
                />
                <map-marker
                  @update-marker="(marker) => updateMapMarker(marker, index)"
                />
              </template>
            </base-card>
          </div>
          <div v-show="detailsSelected">
            <div class="px-5 mb-8">
              <div class="pb-5">
                <input
                  id="af"
                  type="checkbox"
                  class="inputCheckSq"
                  v-model="alternativeFont"
                  @change="setAlternativeFont(alternativeFont)"
                />
                <label for="af"> Use alternative font</label>
              </div>
              <div class="mb-2 font-semibold">Header*</div>
              <input
                type="text"
                class="form-control"
                :class="
                  focus == 'header' && headerText == '' ? 'errorInput' : ''
                "
                placeholder="Your title"
                maxlength="40"
                v-model="headerText"
                @input="setDetailsHeaderText(headerText)"
              />
              <small class="text-xs text-ext-gray">Max 40 characters</small>
            </div>
            <base-card
              class="m-5"
              v-for="index in getNumOfMap"
              :key="index"
              :class="
                focusDetails[index - 1] == 'details' + [index - 1] &&
                (title[index - 1] == '' || subTitle[index - 1] == ''
                  ? 'errorInput rounded-lg'
                  : '')
              "
              :open="index == 1 ? true : false"
            >
              <template #icon
                ><img src="@/assets/images/marker.svg"
              /></template>
              <template #title>Map {{ index }} Design</template>
              <template #body>
                <div class="px-5 mb-8">
                  <div class="mb-2 font-semibold">Title*</div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your title"
                    maxlength="25"
                    v-model="title[index - 1]"
                    @input="setTitle({ index: index, title: title[index - 1] })"
                  />
                  <small class="text-xs text-ext-gray">Max 25 characters</small>
                </div>
                <div class="px-5 mb-8">
                  <div class="mb-2 font-semibold">Subtext*</div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Subtext about your memory"
                    maxlength="25"
                    v-model="subTitle[index - 1]"
                    @input="
                      setSubTitle({ index: index, sub: subTitle[index - 1] })
                    "
                  />
                  <small class="text-xs text-ext-gray">Max 25 characters</small>
                </div>
              </template>
            </base-card>
            <div class="px-5 mb-8">
              <div class="mb-2 font-semibold">Footer*</div>
              <input
                type="text"
                class="form-control"
                :class="
                  focus == 'footer' && footerText == '' ? 'errorInput' : ''
                "
                placeholder="Subtext about your memory"
                maxlength="40"
                v-model="footerText"
                @input="setDetailsFooterText(footerText)"
              />
              <small class="text-xs text-ext-gray">Max 40 characters</small>
            </div>
          </div>
          <div v-show="formatSelected">
            <frame-color
              v-if="printType != 'Digital Copy'"
              @update-frame-color="
                (color) => {
                  setFrameColor(color.color);
                  checkPrice('storyMap');
                }
              "
            />
            <printing-type
              @selected-printing-type="(type) => updatePrintType(type)"
            />
            <div class="px-5 mb-8" v-if="printType != 'Digital Copy'">
              <div class="mb-2 font-semibold">Gifting message</div>
              <input
                type="text"
                class="form-control"
                maxlength="100"
                placeholder="Write your message"
                v-model="giftingMessage"
                @change="setGiftingMessage(giftingMessage)"
              />
              <div class="my-2 text-xs text-ext-gray">
                Extra charges would apply for gifting
              </div>
              <input
                type="checkbox"
                class="mr-2 inputCheckSq"
                v-model="giftWrapping"
                @change="
                  setGiftWrapping(giftWrapping);
                  checkPrice('storyMap');
                "
              />
              Add gift wrapping
            </div>
          </div>
        </div>
        <div
          class="fixed inset-x-0 bottom-0 z-10 flex items-center justify-between p-2 bg-white shadow-sm sm:p-4 lg:absolute"
        >
          <span class="text-2xl font-semibold"
            ><span v-if="formatSelected">₹ {{ getPrice }}</span></span
          >
          <div class="flex items-center">
            <button
              v-if="!structureSelected && !previewSelected"
              class="mr-2 btn-outline"
              @click="back"
            >
              <img
                src="@/assets/images/ArrowRight.svg"
                alt="arrow"
                class="mr-1 rotate-180"
              />
              Previous
            </button>
            <button
              v-if="!previewSelected"
              class="btn-primary"
              :class="formatSelected && getPrice == 0 ? 'hidden' : ''"
              @click="next"
            >
              {{ ctaBtnText }}
              <img
                src="@/assets/images/ArrowRight.svg"
                alt="arrow"
                class="ml-1"
              />
            </button>
            <button
              v-else
              class="btn-primary"
              @click="selectedTab = 'structure'"
            >
              Customize
              <img
                src="@/assets/images/ArrowRight.svg"
                alt="arrow"
                class="ml-1"
              />
            </button>
          </div>
        </div>
        <template v-if="previewSelected">
          <div
            class="self-center flex-grow block pt-20 pb-20 lg:hidden md:px-8 overflow-auto h-full pb-24"
          >
            <h1
              class="mb-2 md:mb-4 text-2xl md:text-3xl font-bold flex justify-center"
            >
              Location Map Gift
            </h1>
            <StoryPreview :num-of-map="selectedOption.numOfMaps" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  // import { mapState } from "vuex";
  import MapType from '../components/productOptions/MapType.vue';
  import NumberOfMaps from '../components/productOptions/NumberOfMaps.vue';
  import MapShape from '../components/productOptions/MapShape.vue';
  import ChooseBg from '../components/productOptions/ChooseBg.vue';
  import LocationInput from '../components/productOptions/LocationInput.vue';
  import AppHeader from '../components/AppHeader.vue';
  import AppLoader from '../components/AppLoader.vue';
  import MapStyling from '../components/productOptions/MapStyling.vue';
  import MapMarker from '../components/productOptions/MapMarker.vue';
  import FrameColor from '../components/productOptions/FrameColor.vue';
  import PrintingType from '../components/productOptions/PrintingType.vue';
  import StoryPreview from '../components/StoryPreview.vue';
  import { mapGetters, mapMutations, mapActions } from 'vuex';
  import BaseCard from '@/components/ui/BaseCard.vue';
  import MapComponent from '@/components/map/MapComponent.vue';
  import { useHead } from 'unhead';

  export default {
    components: {
      AppHeader,
      AppLoader,
      BaseCard,
      MapType,
      NumberOfMaps,
      MapShape,
      ChooseBg,
      LocationInput,
      MapStyling,
      MapMarker,
      FrameColor,
      PrintingType,
      StoryPreview,
      MapComponent,
    },
    data() {
      return {
        selectedTab: 'preview',
        title: ['', '', ''],
        subTitle: ['', '', ''],
        locationAdded: [false, false, false],
        giftingMessage: '',
        giftWrapping: false,
        printType: null,
        price: null,
        alternativeFont: false,
        headerText: '',
        footerText: '',
        loader: false,
        focus: '',
        focusDetails: ['', null, null],
        lastActiveTab: 'structure',
        tabStructureValidated: false,
        tabDesignValidated: false,
        tabDetailsValidated: false,
      };
    },
    computed: {
      ...mapGetters('storyMap', ['selectedOption', 'getNumOfMap']),
      ...mapGetters('checkout', ['getPrice', 'getImages', 'getFinalImage']),
      ...mapGetters('mapObj', [
        'mapDataUrl',
        'getMapMarker',
        'getMapStyle',
        'getCenter',
        'getCountry',
      ]),
      structureSelected() {
        return this.selectedTab === 'structure';
      },
      designSelected() {
        return this.selectedTab === 'design';
      },
      detailsSelected() {
        return this.selectedTab === 'details';
      },
      formatSelected() {
        return this.selectedTab === 'format';
      },
      previewSelected() {
        return this.selectedTab === 'preview';
      },
      mobileView() {
        return window.screen.width < 1024;
      },
      ctaBtnText() {
        return this.structureSelected ||
          this.designSelected ||
          this.detailsSelected
          ? 'Next'
          : 'Checkout';
      },
    },
    async created() {
      // document.title = 'Story Map';
      this.mobileView
        ? (this.selectedTab = 'preview')
        : (this.selectedTab = 'structure');
      if (this.$route.query.edit != 'true') {
        this.resetMap();
      }
      if (!this.getCountry) await this.fetchCountry();
      setTimeout(() => {
        if (this.getCountry != 'India') {
          const option = {
            id: 2,
            image: 'printType/printDigitalCopy.svg',
            name: 'Digital Copy',
          };
          this.updatePrintType(option);
        }
      }, 1000);
      this.deleteCookies();
    },
    mounted() {
      useHead({
        title:
          'Buy Map Gifts Online | Create Your Own Map Art | The Rare Window',
        meta: [
          {
            name: 'title',
            content: 'Best Personalized Gifts - Story Maps | The Rare Window ',
          },
          {
            name: 'description',
            content:
              'Tell your love story by creating your own map art from The Rare Window. Purchase a map for your partner, it was the best gift ever. Check Now!',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: 'https://therarewindow.com/story-map',
          },
        ],
      });
      this.deleteCookies();
    },
    beforeUnmount() {
      this.setCountry('');
    },
    methods: {
      ...mapMutations('storyMap', [
        'setImageTitle',
        'setImageSubTitle',
        'setGiftingMessage',
        'setGiftWrapping',
        'setNumberOfMaps',
        'setBg',
        'setMapShape',
        'setTitle',
        'setSubTitle',
        'setDetailsHeaderText',
        'setDetailsFooterText',
        'setFrameColor',
        'setPrintingType',
        'setAlternativeFont',
      ]),
      ...mapMutations('mapObj', [
        'setMapStyle',
        'setMapMarker',
        'setCenter',
        'resetMap',
        'setCountry',
      ]),
      ...mapMutations('checkout', ['setProductDetails', 'setNumOfMaps']),
      ...mapActions('storyMap', ['createImg']),
      ...mapActions('checkout', ['checkPrice']),
      ...mapActions('mapObj', ['fetchCountry']),

      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },

      resizeMap(index) {
        this.deleteCookies();
        this.$refs.mapComponent[index].resizeMap();
      },

      updateMapStyle(style, index) {
        this.deleteCookies();
        this.setMapStyle({
          name: style.name,
          api: style.mapTileApi,
          key: index,
        });
      },
      updateMapMarker(marker, index) {
        this.deleteCookies();
        this.setMapMarker({
          name: marker.name,
          index: marker.id,
          type: marker.type,
          image: marker.image,
          key: index,
        });
      },
      updateLocation(center, key) {
        this.deleteCookies();
        this.setCenter({
          center: { lon: center.lon, lat: center.lat },
          key: key,
        });
      },
      updatePrintType(type) {
        this.deleteCookies();
        this.setPrintingType(type);
        this.printType = type.name;
        if (this.printType == 'Digital Copy') {
          this.giftWrapping = false;
          this.setGiftWrapping(this.giftWrapping);
          this.checkPrice('storyMap');
        } else {
          this.checkPrice('storyMap');
        }
      },
      back() {
        this.deleteCookies();
        if (this.structureSelected) {
          this.selectedTab = 'preview';
        }
        if (this.designSelected) {
          this.selectedTab = 'structure';
        }
        if (this.detailsSelected) {
          this.selectedTab = 'design';
        }
        if (this.formatSelected) {
          this.selectedTab = 'details';
        }
      },
      async next() {
        this.deleteCookies();
        if (this.structureSelected) {
          this.selectedTab = 'design';
          this.tabStructureValidated = true;
          this.$refs.options.scrollTop = 0;
        } else if (this.designSelected) {
          let count = 0;
          for (let i = 0; i < this.getNumOfMap; i++) {
            if (!this.locationAdded[i]) {
              count++;
              this.$refs.locationInput[i].makeFocus();
              this.$toast.open({
                message: `Please select location ${i + 1}.`,
                type: 'error',
                duration: 5000,
                dismissible: true,
                position: 'top',
              });
              this.tabDesignValidated = false;
            }
          }
          if (count == 0) {
            this.selectedTab = 'details';
            this.$refs.options.scrollTop = 0;
            this.tabDesignValidated = true;
          }
        } else if (this.detailsSelected) {
          if (this.headerText) {
            let count = 0;
            for (let i = 0; i < this.getNumOfMap; i++) {
              if (this.title[i] == '' || this.subTitle[i] == '') {
                count++;
                this.focusDetails[i] = 'details' + i;
                this.$toast.open({
                  message: `Please add map ${i + 1} design details.`,
                  type: 'error',
                  duration: 5000,
                  dismissible: true,
                  position: 'top',
                });
              }
            }
            if (count == 0) {
              if (this.footerText) {
                this.selectedTab = 'format';
                this.$refs.options.scrollTop = 0;
                this.tabDetailsValidated = true;
              } else {
                this.focus = 'footer';
                this.$toast.open({
                  message: `Please add footer text.`,
                  type: 'error',
                  duration: 5000,
                  dismissible: true,
                  position: 'top',
                });
                this.tabDetailsValidated = false;
              }
            }
          } else {
            this.focus = 'header';
            this.$toast.open({
              message: `Please add header text.`,
              type: 'error',
              duration: 5000,
              dismissible: true,
              position: 'top',
            });
          }
        } else if (this.formatSelected) {
          this.$refs.options.scrollTop = 0;
          this.loader = true;
          this.checkout();
          await this.createImg();
        }
      },
      checkout() {
        this.deleteCookies();
        this.setProductDetails({
          mapType: this.getNumOfMap,
          productName: this.selectedOption.productName,
          frameSize: '8x12', //this.selectedOption.frameSize
          frameColor: this.selectedOption.frameColor.color,
          printingType: this.selectedOption.printingType,
          giftingMsg: this.giftingMessage,
          giftWrap: this.giftWrapping,
          blobImage: this.getImages,
        });
        this.setNumOfMaps(this.getNumOfMap);
      },
    },
    watch: {
      formatSelected(newVal) {
        if (newVal) {
          this.deleteCookies();
          this.checkPrice('storyMap');
        }
      },
      designSelected(newVal) {
        this.deleteCookies();
        if (newVal == true) {
          this.$refs.mapComponent[0].resizeMap();
        }
      },
      getFinalImage(newVal) {
        this.deleteCookies();
        if (newVal) {
          this.$router.push('/checkout');
        }
      },
      selectedTab(newVal) {
        this.deleteCookies();
        if (newVal != 'preview') {
          this.lastActiveTab = newVal;
        }
      },
    },
  };
</script>

<style scoped>
  .sideBar {
    height: calc(100vh - 65px);
  }
</style>
