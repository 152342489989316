export default {
  getUserDetails(state) {
    return state.userDetails;
  },
  getProductDetails(state) {
    return state.productDetails;
  },
  getPrice(state) {
    return state.price;
  },
  getImages(state) {
    return state.blobImage;
  },
  getFinalImage(state) {
    return state.blobImage.final;
  },
  getPreviewImg(state) {
    return state.previewImg;
  },
  getNumOfMaps(state) {
    return state.numOfMaps;
  },
};
