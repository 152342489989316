import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      productName: 'story map',
      title: 'Your title goes here',
      subText: 'Subtext about your memory',
      basePrice: 200,
      mapShape: { name: 'Square', color: '' },
      numOfMaps: 3,
      frameSize: '8x12',
      bg: {
        color: '#ffffff',
        image: '',
      },
      details: {
        header: 'Your header goes here',
        footer: 'Your footer goes here',
        alternativeFont: false,
        text: [
          { main: 'Your map 1 title', sub: 'Your map 1 subtext' },
          { main: 'Your map 2 title', sub: 'Your map 2 subtext' },
          { main: 'Your map 3 title', sub: 'Your map 3 subtext' },
        ],
      },
      printingType: '',
      Location: '',
      frameColor: {
        color: '#000000',
        name: 'Title',
      },
      giftingMessage: '',
      giftWrapping: false,
    };
  },
  mutations,
  actions,
  getters,
};
