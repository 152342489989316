import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      price: 899,
      fileLocation: '',
      numOfMaps: 1,
      productDetails: {
        mapType: 1,
        productName: '',
        frameSize: '',
        frameColor: '',
        printingType: '',
        giftingMsg: '',
        giftWrap: false,
      },
      blobImage: {
        final: null,
        base: null,
        map: null,
      },
      previewImg: null,
      userDetails: {
        email: '',
        send_news_and_Offers: false,
        shippingDetails: {
          country: '',
          firstName: '',
          lastName: '',
          address: '',
          apartment: '',
          pin: '',
          city: '',
          state: '',
          phone: '',
        },
        billingDetails: {
          country: '',
          firstName: '',
          lastName: '',
          address: '',
          apartment: '',
          pin: '',
          city: '',
          state: '',
          phone: '',
        },
      },
    };
  },
  mutations,
  actions,
  getters,
};
