export default {
  selectedOption(state) {
    return state;
  },
  imageTitle(state) {
    return state.title;
  },
  getDetailsText(state) {
    return state.details;
  },
  getBg(state) {
    return state.bg;
  },
  getNumOfMap(state) {
    return state.numOfMaps;
  },
  getMapShape(state) {
    return state.mapShape;
  },
  getAlternativeFont(state) {
    return state.details.alternativeFont;
  },
};
