import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
export default {
  namespaced: true,
  state() {
    return {
      selectedOption: {
        productName: 'map of love',
        basePrice: 200,
        frameSize: '8x12',
        bg: {
          color: '#ffffff',
          image: '',
        },
        mapColor: '',
        Location: '',
        frameColor: {
          color: '#000000',
          name: 'Title',
        },
        numOfMaps: 4,
        printingType: 'Single Level',
        giftingMessage: '',
        giftWrapping: false,
      },
      mapShape: { name: 'Square', color: '' },
      design: {
        starBg: '#FFFFFF',
        date: new Date(),
        time: null,
        propername: false,
      },
      detail: {
        name1: 'Name 1',
        name2: 'Name 2',
        event: 'Event Name',
        date: 'Date',
        time: 'Time',
        location: 'Location',
        quote: 'A quote for that special moment',
        leftImage: 'https://www.w3schools.com/tags/img_girl.jpg',
        centerImage: 'https://www.w3schools.com/tags/img_girl.jpg',
        rightImage: 'https://www.w3schools.com/tags/img_girl.jpg',
      },
    };
  },
  mutations,
  actions,
  getters,
};
