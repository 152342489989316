export default {
  setNumberOfMaps(state, payload) {
    state.numOfMaps = payload;
    // if (payload == 1) {
    //   state.frameSize = '8x12';
    // } else {
    //   state.frameSize = '12x8';
    // }
  },
  setBg(state, payload) {
    state.bg = payload;
    state.mapShape.color = state.bg.color;
  },
  setDetailsHeaderText(state, payload) {
    state.details.header = payload;
  },
  setDetailsFooterText(state, payload) {
    state.details.footer = payload;
  },
  setTitle(state, payload) {
    state.details.text[payload.index - 1].main = payload.title;
  },
  setSubTitle(state, payload) {
    state.details.text[payload.index - 1].sub = payload.sub;
  },
  setImageTitle(state, payload) {
    state.title = payload;
  },
  setImageSubTitle(state, payload) {
    state.subText = payload;
  },
  setFrameColor(state, payload) {
    state.frameColor.color = payload;
  },
  setGiftingMessage(state, payload) {
    state.setGiftingMessage = payload;
  },
  setGiftWrapping(state, payload) {
    state.giftWrapping = payload;
  },
  setPrintingType(state, payload) {
    state.printingType = payload.name;
  },
  setMapShape(state, payload) {
    state.mapShape.name = payload.name;
    state.mapShape.color = state.bg.color;
  },
  setAlternativeFont(state, payload) {
    state.details.alternativeFont = payload;
  },
};
