<template>
  <div class="">
    <div
      class="flex items-center p-5 bg-gray-100 rounded-t-lg"
      @click="
        {
          show = !show;
          if (show) {
            $emit('updateOpen');
          }
        }
      "
    >
      <span class="mr-2"><slot name="icon"></slot></span>
      <slot name="title"></slot>
      <img
        src="@/assets/images/iconUp.svg"
        alt="up"
        class="ml-auto transition ease-in-out"
        :class="!show ? 'rotate-180' : ''"
      />
    </div>
    <!-- <div class="p-5"> -->
    <div v-show="show" class="pt-5 mb-8 border border-gray-100 rounded-b-lg">
      <slot name="body"></slot>
    </div>

    <!-- </div> -->
  </div>
</template>

<script>
  export default {
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: this.open,
      };
    },
  };
</script>

<style></style>
