<template>
  <div class="absolute -inset-[2px] z-[1] pointer-events-none">
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1417.32 1417.32"
      style="enable-background: new 0 0 1417.32 1417.32"
      xml:space="preserve"
      height="100%"
      max-height="100%"
      width="100%"
      max-width="100%"
    >
      <path
        v-if="shape.name == 'Circle'"
        :fill="shape.color"
        d="M-0,-0l-0,1417.32l1417.32,-0l0,-1417.32l-1417.32,-0Zm708.66,1400.47c-382.062,-0 -691.785,-309.734 -691.785,-691.808c-0,-382.074 309.723,-691.808 691.785,-691.808c382.062,0 691.785,309.734 691.785,691.808c0,382.074 -309.723,691.808 -691.785,691.808Z"
      ></path>
      <path
        v-if="shape.name == 'Circle'"
        d="M709,16.883c-381.99,-0 -692.117,310.127 -692.117,692.117c-0,381.99 310.127,692.117 692.117,692.117c381.99,0 692.117,-310.127 692.117,-692.117c0,-381.99 -310.127,-692.117 -692.117,-692.117Zm0,1c381.438,-0 691.117,309.679 691.117,691.117c0,381.438 -309.679,691.117 -691.117,691.117c-381.438,0 -691.117,-309.679 -691.117,-691.117c-0,-381.438 309.679,-691.117 691.117,-691.117Z"
        style="fill: none; fill-rule: nonzero; stroke: #000; stroke-width: 1px"
      />

      <path
        v-if="shape.name == 'Heart'"
        :fill="shape.color"
        d="M0,0l0,1418l1418,0l0,-1418l-1418,0Zm1387.3,620.065c-92.1,394.108 -637.327,766.115 -678.709,787.209c-41.266,-21.094 -586.493,-393.101 -678.709,-787.209c-17.982,-77 -40.344,-297.312 44.494,-427.798c10.029,-15.446 53.601,-76.308 134.29,-124.606c115.27,-69.047 332.323,-114.579 499.925,129.448c167.718,-244.142 384.656,-198.495 499.926,-129.448c80.689,48.298 124.261,109.275 134.289,124.606c84.839,130.371 62.477,350.798 44.494,427.798Z"
      ></path>
      <path
        v-if="shape.name == 'Heart'"
        d="M1387.3,620.065c-92.1,394.108 -637.327,766.115 -678.709,787.209c-41.266,-21.094 -586.493,-393.101 -678.709,-787.209c-17.982,-77 -40.344,-297.312 44.494,-427.798c10.029,-15.446 53.601,-76.308 134.29,-124.606c115.27,-69.047 332.323,-114.579 499.925,129.448c167.718,-244.142 384.656,-198.495 499.926,-129.448c80.689,48.298 124.261,109.275 134.289,124.606c84.839,130.371 62.477,350.798 44.494,427.798Z"
        style="fill: none; fill-rule: nonzero; stroke: #000; stroke-width: 1px"
      />
    </svg>
  </div>
</template>

<script>
  export default {
    props: {
      shape: {
        type: Object,
        default: () => ({
          name: '',
          color: '',
        }),
      },
    },
  };
</script>

<style></style>
