export default {
  selectedOption(state) {
    return state.selectedOption;
  },
  selectedFrameSize(state) {
    return state.selectedOption.frameSize;
  },
  getNumOfMap(state) {
    return state.selectedOption.numOfMaps;
  },
  getDetails(state) {
    return state.details;
  },
  getAlternativeFont(state) {
    return state.details.alternativeFont;
  },
};
