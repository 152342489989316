export default {
  setMapStyle(state, payload) {
    state.mapstyle[payload.key - 1].name = payload.name;
    state.mapstyle[payload.key - 1].mapTileApi = payload.api;
  },
  setMapMarker(state, payload) {
    state.mapMarker[payload.key - 1].name = payload.name;
    state.mapMarker[payload.key - 1].index = payload.index;
    state.mapMarker[payload.key - 1].type = payload.type;
    state.mapMarker[payload.key - 1].image = payload.image;
  },
  setMapDataUrl(state, payload) {
    state.mapDataUrl = payload;
  },
  setMapObj(state, payload) {
    state.map = payload;
  },
  setMapFrame(state, payload) {
    state.mapFrame = payload;
  },
  setCenter(state, payload) {
    state.center[payload.key - 1].lon = payload.center.lon;
    state.center[payload.key - 1].lat = payload.center.lat;
  },
  setLocationName(state, payload) {
    state.locationName[payload.key - 1] = payload.locationName;
  },
  setZoom(state, payload) {
    state.zoom = payload;
  },
  setExtent(state, payload) {
    state.extent[payload.key] = payload.ext;
  },
  setMarkerLabel(state, payload) {
    state.markerLabel[payload.key] = payload.markerLabel;
  },
  resetMap(state) {
    state.map = null;
    state.mapstyle = [
      {
        name: '',
        mapTileApi:
          'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
        contentBgColor: '#ffffff',
        contentColor: '#000000',
      },
      {
        name: '',
        mapTileApi:
          'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
        contentBgColor: '#ffffff',
        contentColor: '#000000',
      },
      {
        name: '',
        mapTileApi:
          'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
        contentBgColor: '#ffffff',
        contentColor: '#000000',
      },
      {
        name: '',
        mapTileApi:
          'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
        contentBgColor: '#ffffff',
        contentColor: '#000000',
      },
      {
        name: '',
        mapTileApi:
          'https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/style.json?key=lOUgqb5qcG1eDUoQSBbD',
        contentBgColor: '#ffffff',
        contentColor: '#000000',
      },
    ];
    state.mapMarker = [
      {
        name: '',
        index: '1',
        type: 'marker',
        image: 'marker.png',
      },
      {
        name: '',
        index: '1',
        type: 'marker',
        image: 'marker.png',
      },
      {
        name: '',
        index: '1',
        type: 'marker',
        image: 'marker.png',
      },
      {
        name: '',
        index: '1',
        type: 'marker',
        image: 'marker.png',
      },
      {
        name: '',
        index: '1',
        type: 'marker',
        image: 'marker.png',
      },
    ];
    state.mapFrame = null;
    state.mapDataUrl = null;
    state.center = [
      {
        lon: 77.1025,
        lat: 28.7041,
      },
      {
        lon: 77.1025,
        lat: 28.7041,
      },
      {
        lon: 77.1025,
        lat: 28.7041,
      },
      {
        lon: 77.1025,
        lat: 28.7041,
      },
      {
        lon: 77.1025,
        lat: 28.7041,
      },
    ];
    state.zoom = 10;
    state.extent = [null, null, null, null, null];
    state.markerLabel = ['', '', '', '', ''];
  },
  setCountry(state, country) {
    state.country = country;
  },
};
