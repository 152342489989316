<template>
  <app-header-home />
  <div class="container px-4 pt-20 mx-auto">
    <h1 class="text-xl font-bold text-center">PRIVACY POLICY</h1>
    <p class="pt-6 pb-1">Effective date: June 05, 2022</p>
    <p class="pb-2">Last updated on: June 05, 2022</p>

    <ol class="pt-6">
      <li class="pb-8">
        <h1 class="text-xl font-bold">1. GENERAL</h1>
        <p class="pt-3 pb-0 pl-6">
          a. This Website with the URL of www.therarewindow.com ("Website/Site")
          is operated by Rarewindow Private Limited ("We/Our/Us"). We are
          committed to protecting and respecting your privacy. We do collect
          your personal information and process your personal data in accordance
          with the IT Act, 2000 (21 of 2000) and other national and state laws
          which relate the processing of personal data. Please read the
          following carefully to understand our views and practices regarding
          your personal data.
        </p>

        <p class="pt-3 pb-0 pl-6">
          b. We collect your personal information in order to provide and
          continually improve our products and services.
        </p>

        <p class="pt-3 pb-0 pl-6">
          c. Our privacy policy is subject to change at any time without notice.
          To make sure you are aware of any changes, please review this policy
          periodically.
        </p>

        <p class="pt-3 pb-0 pl-6">
          d. All partner firms and any third-party working with or for Us, and
          who have access to personal information, will be expected to read and
          comply with this policy.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">2. HOW WE COLLECT THE INFORMATION</h1>
        <p class="pt-3 pb-0 pl-6">
          a. From you directly and through this Site: We may collect information
          through the Website when you visit. The data we collect depends on the
          context of your interactions with our Website.
        </p>

        <p class="pt-3 pb-0 pl-6">
          b. Through business interaction: We may collect information through
          business interaction with you or your employees.
        </p>

        <p class="pt-3 pb-0 pl-6">
          c. From other sources: We may receive information from other sources,
          such as public databases; joint marketing partners; social media
          platforms; or other third parties such as:
        </p>
        <div class="pt-3 pb-0 pl-14">
          <p class="pt-2 pb-2">
            I. Updated delivery and address information from our carriers or
            other third parties, which we use to correct our records and deliver
            your next purchase or communication more easily.
          </p>
          <p class="pt-2 pb-2">
            II. Information about your interactions with the products and
            services offered by our subsidiaries.
          </p>
        </div>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">3. INFORMATION WE COLLECT</h1>
        <p class="pt-3 pb-0 pl-6">
          a. We collect information primarily to provide better services to all
          of our customers.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. We collect the following information from you when you use or
          signup on our Website:
        </p>
        <div class="pt-3 pb-0 pl-14">
          <p class="pt-2 pb-2">
            Full Name, Email ID, Phone number, Address and Images and videos
            uploaded by the user
          </p>
        </div>

        <p class="pt-3 pb-0 pl-6">
          c. When you visit our Site, some information is automatically
          collected. This may include information such as the Operating Systems
          (OS) running on your device, Internet Protocol (IP) address, access
          times, browser type, and language, and the website you visited before
          our Site. We also collect information about how you use Our products
          or services.
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. We automatically collect purchase or content use history, which we
          sometimes aggregate with similar information from other customers to
          create features such as Best Seller, Top Rated, etc...
        </p>
        <p class="pt-3 pb-0 pl-6">
          e. The full Uniform Resource Locators (URL) clickstream to, through
          and from our website (including date and time); cookie number;
          products and/or content you viewed or searched for; page response
          times; download errors; length of visits to certain pages; page
          interaction information (such as scrolling, clicks, and mouse-overs).
        </p>
        <p class="pt-3 pb-0 pl-6">
          f. We automatically collect information using "Cookies". Cookies are
          small data files stored on your hard drive the websites. Among other
          things, cookies help us improve our Site, our marketing activities,
          and your experience. We use cookies to see which areas and features
          are popular and to count visits to our Site.
        </p>
        <p class="pt-3 pb-0 pl-6">
          g. Most Web browsers are set to accept cookies by default. If you
          prefer, you can choose to set your browser to remove cookies and to
          reject cookies. If you set your browser to reject cookies, some
          features will be unavailable. For more information on how to reject
          cookies, see your browser's instructions on changing your cookie
          settings.
        </p>
        <p class="pt-3 pb-0 pl-6">
          h. By using this Website, you are agreeing that We may advertise your
          feedback on the Website and marketing materials.
        </p>
        <p class="pt-3 pb-0 pl-6">
          i. We will retain your information as long as we require this to
          provide you with the goods and services and for such period as
          mandated by the concerned laws.
        </p>
        <p class="pt-3 pb-0 pl-6">
          j. If you opt to receive marketing correspondence from us, subscribe
          to our mailing list or newsletters, enter into any of our competitions
          or provide us with your details at networking events, we may use your
          personal data for our legitimate interests in order to provide you
          with details about our goods, services, business updates and events.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">4. HOW WE USE INFORMATION</h1>
        <p class="pt-3 pb-0 pl-6">
          a. We use the information we collect primarily to provide, maintain,
          protect and improve our current products and services.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. We use the information collected through this website as described
          in this policy and we may use your information to:
        </p>
        <div class="pt-3 pb-0 pl-14">
          <p class="pt-2 pb-2">
            I. Improve our services, Site and how we operate our businesses;
          </p>
          <p class="pt-2 pb-2">
            II. Understand and enhance your experience using our Site, products
            and services;
          </p>
          <p class="pt-2 pb-2">
            III. Personalize our products or services and make recommendations;
          </p>
          <p class="pt-2 pb-2">
            IV. Provide and deliver products and services you request;
          </p>
          <p class="pt-2 pb-2">
            V. Process, manage, complete, and account for transactions;
          </p>
          <p class="pt-2 pb-2">
            VI. Provide customer support and respond to your requests, comments,
            and inquiries;
          </p>
          <p class="pt-2 pb-2">
            VII. Create and manage the online accounts you manage on our
            Website;
          </p>
          <p class="pt-2 pb-2">
            VIII. Send you related information, including confirmations,
            invoices, technical notices, updates, security alerts and support
            and administrative messages;
          </p>
          <p class="pt-2 pb-2">
            Communicate with you about promotions, upcoming events and news
            about products and services;
          </p>
          <p class="pt-2 pb-2">
            X. We may process your personal information without your knowledge
            or consent where required by applicable law or regulation for the
            purposes of verification of identity or for prevention, detection or
            investigation, including of cyber incidents, prosecution and
            punishment of offences;
          </p>
          <p class="pt-2 pb-2">
            XI. Protect, investigate and deter against fraudulent, unauthorized
            or illegal activity.
          </p>
        </div>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">5. DATA TRANSFER</h1>
        <p class="pt-3 pb-0 pl-6">
          a. Information about our user is an important part of our business and
          we take due care to protect the same.
        </p>

        <p class="pt-3 pb-0 pl-6">
          b. We share your data with your consent or to complete any transaction
          or provide any product or service you have requested or authorized. We
          also share data with our affiliates and subsidiaries, with vendors
          working on our behalf.
        </p>

        <p class="pt-3 pb-0 pl-6">
          c. We may employ other companies and individuals to perform functions
          on our behalf. The functions include fulfilling orders for products or
          services, delivering packages, sending postal mail and e-mail,
          removing repetitive information from customer lists, providing
          marketing assistance, providing search results and links (including
          paid listings and links), processing payments, transmitting content,
          scoring credit risk, and providing customer service.
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. These third-party service providers have access to personal
          information needed to perform their functions but may not use it for
          other purposes. Further, they must process the personal information in
          accordance with this Privacy Policy and as permitted by applicable
          data protection laws.
        </p>
        <p class="pt-3 pb-0 pl-6">
          e. We release account and other personal information when we believe
          is appropriate to comply with the law, enforce or apply our conditions
          of use, and other agreements, protect the rights, property or safety
          of Us, our users or others. This includes exchanging information with
          other companies and organizations for fraud protection and credit risk
          reduction.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">6. COOKIES</h1>
        <p class="pt-3 pb-0 pl-6">
          a. To optimize our web presence, we use cookies. These are small text
          files stored on your computer's main memory. These cookies are deleted
          after you close the browser. Other cookies remain on your computer
          (long-term cookies) and permit its recognition on your next visit.
          This allows us to improve your access to our site. This helps us to
          learn more about your interests, and provide you with essential
          features and services and for additional purposes, including:
        </p>

        <div class="pt-3 pb-0 pl-14">
          <p class="pt-2 pb-2">
            I. Keeping track of items stored in your shopping basket.
          </p>
          <p class="pt-2 pb-2">
            II. Conducting research and diagnostics to improve the content,
            products, and services.
          </p>
          <p class="pt-2 pb-2">III. Preventing fraudulent activity.</p>
          <p class="pt-2 pb-2">IV. Improving security.</p>
        </div>

        <p class="pt-3 pb-0 pl-6">
          b. Our cookies allow you to take advantage of some of our essential
          features. For instance, if you block or otherwise reject our cookies,
          you will not be able to add items to your shopping basket, proceed to
          checkout, or use any products or services that require you to sign in.
        </p>

        <p class="pt-3 pb-0 pl-6">
          c. Approved third parties also may set cookies when you interact with
          Our services.
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. Third parties include search engines, providers of measurement and
          analytics services, social media networks, and advertising companies.
        </p>
        <p class="pt-3 pb-0 pl-6">
          e. Third parties use cookies in the process of delivering content,
          including ads relevant to your interests, to measure the effectiveness
          of their ads, and to perform services on behalf of Us.
        </p>
        <p class="pt-3 pb-0 pl-6">
          f. You can prevent the storage of cookies by choosing a "disable
          cookies" option in your browser settings. But this can limit the
          functionality of our services.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">7. DATA SECURITY</h1>
        <p class="pt-3 pb-0 pl-6">
          a. We take due care to protect customer data. Technical measures are
          in place to prevent unauthorized or unlawful access to data and
          against accidental loss or destruction of, or damage to, data. The
          employees who are dealing with the data have been trained to protect
          the data from any illegal or unauthorized usage.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. We work to protect the security of your information during
          transmission by using Secure Sockets Locker (SSL) software, which
          encrypts information you input. SSL allows sensitive information such
          as credit card numbers, UID's and login credentials to be transmitted
          securely.
        </p>
        <p class="pt-3 pb-0 pl-6">
          c. We follow the Payment Card Industry Data Security Standard (PCI
          DSS) when handling branded credit cards from the major card schemes.
        </p>
        <p class="pt-3 pb-0 pl-6">
          d. We maintain physical, electronic, and procedural safeguards in
          connection with the collection, storage, and disclosure of personal
          customer information.
        </p>
        <p class="pt-3 pb-0 pl-6">
          e. We take reasonable steps to help protect your personal information
          in an effort to prevent the loss, misuse, and unauthorized access,
          disclosure alteration and destruction. It is your responsibility to
          protect your user names and passwords to help prevent anyone from
          accessing or abusing your accounts and services. You should not use or
          reuse the same passwords you use with other accounts as your password
          for our services.
        </p>
        <p class="pt-3 pb-0 pl-6">
          f. It is important for you to protect against unauthorized access to
          your password and your computers, devices, and applications. Be sure
          to sign off when you finish using a shared computer.
        </p>
        <p class="pt-3 pb-0 pl-6">
          g. Information you provide to us is shared on our secure servers. We
          have implemented appropriate physical, technical and organizational
          measures designed to secure your information against accidental loss
          and unauthorized access, use, alteration or disclosure. In addition,
          we limit access to personal data to those employees, agents,
          contractors, and other third parties that have a legitimate business
          need for such access.
        </p>
        <p class="pt-3 pb-0 pl-6">
          h. Information collected from you will be stored for such period as
          required to complete the transaction entered into with you or such
          period as mandated under the applicable laws.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">8. LINKS TO THIRD PARTY SITE/APPS</h1>
        <p class="pt-3 pb-0 pl-6">
          Our Site may, from time to time, contain links to and from other
          websites of third parties. Please note that if you follow a link to
          any of these websites, such websites will apply different terms to the
          collection and privacy of your personal data and we do not accept any
          responsibility or liability for these policies. When you leave our
          Site, we encourage you to read the privacy policy of every website you
          visit.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">9. SHARING OF PERSONAL INFORMATION</h1>
        <p class="pt-3 pb-0 pl-6">
          a. We do not share your personal data with third parties without your
          prior consent other than:
        </p>
        <div class="pt-3 pb-0 pl-14">
          <p class="pt-2 pb-2">
            I. With third parties who work on our behalf provided such third
            parties adhere to the data protection principles set out in the IT
            Act, 2000 (21 of 2000) and other applicable legislation, or enter
            into a written agreement with Us requiring that the third party
            provide at least the same level of privacy protection as is required
            by such principles;
          </p>
          <p class="pt-2 pb-2">
            II. To comply with laws or to respond to lawful requests and legal
            process;
          </p>
          <p class="pt-2 pb-2">
            III. To protect the rights and property of Us, our agents,
            customers, and others including to enforce our agreements, policies
            and terms of use;
          </p>
          <p class="pt-2 pb-2">
            IV. In an emergency, including to protect the personal safety of any
            person; and
          </p>
          <p class="pt-2 pb-2">
            V. For the purpose of a business deal (or negotiation of a business
            deal) involving the sale or transfer of all or a part of our
            business or assets (business deals may include, for example, any
            merger, financing, acquisition, divestiture or bankruptcy
            transaction or proceeding).
          </p>
        </div>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">10. CHILDREN</h1>
        <p class="pt-3 pb-0 pl-6">
          If you are under 18, or the age of majority in the jurisdiction in
          which you reside, you may only use Our Website with the consent of
          your parent or legal guardian. In any case, We will not be liable for
          any cause of action that arose due to non-compliance with this
          section.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">
          11. YOUR INFORMATION CHOICES AND CHANGES
        </h1>
        <p class="pt-3 pb-0 pl-6">
          a. You can also make choices about the collection and processing of
          your data by Us. You can access your personal data and opt-out of
          certain services provided by the Us. In some cases, your ability to
          control and access to your date will be subject to applicable laws.
        </p>
        <p class="pt-3 pb-0 pl-6">
          b. You may opt-out of receiving promotional emails from Us by
          following the instructions in those emails. If you opt-out, we may
          still send you non-promotional emails, such as emails about our
          ongoing business relationship. You may also send requests about you
          got preferences, changes and deletions to your information including
          requests to opt-out of sharing your personal information with third
          parties by sending an email to the email address provided at the
          bottom of this document.
        </p>
      </li>

      <li class="pb-8">
        <h1 class="text-xl font-bold">12. CHANGES TO THIS POLICY</h1>
        <p class="pt-3 pb-0 pl-6">
          We may change this policy from time to time. If we make any changes to
          this policy, we will change the "Last Updated" date above. You agree
          that your continued use of our services after such changes have been
          published to our services will constitute your acceptance of such
          revised policy.
        </p>
      </li>
      <li class="pb-8">
        <h1 class="text-xl font-bold">13. NEWSLETTER</h1>
        <p class="pt-3 pb-0 pl-6">
          a. Following subscription to the newsletter, your e-mail address is
          used for our advertising purposes until you cancel the newsletter
          again. Cancellation is possible at any time. The following consent has
          been expressly granted by you separately, or possibly in the course of
          an ordering process: (I am accepting to receive newsletter from this
          website), you may revoke your consent at any time with future effect.
          If you no longer want to receive the newsletter, then unsubscribe by
          clicking on unsubscribe option given in the email footer.
        </p>
        <p class="pt-3 pb-0 pl-6">
          If you have any concern about privacy or grievances with Us, please
          contact us with a thorough description and we will try to resolve the
          issue for you.
        </p>
      </li>
    </ol>

    <div class="container px-4 mx-auto">
      <h1 class="text-xl font-bold">Contact Details:</h1>
      <p class="pt-2 pb-1">Name: Akshay Loya</p>
      <p class="pb-2">Contact Details: +91 8600097779</p>
      <p class="pb-2">
        Email ID: <a class="cursor-pointer">support@therarewindow.com</a>
      </p>

      <p class="pb-2">
        Address: 306, Splendnour County, Kalewadi Road, Rahatani, Pune - 411017,
      </p>
      <p class="pb-2">Maharashtra, India</p>
    </div>
  </div>
  <app-footer />
</template>

<script>
  import AppHeaderHome from '@/components/AppHeaderHome.vue';
  import AppFooter from '@/components/AppFooter.vue';
  export default {
    components: {
      AppHeaderHome,
      AppFooter,
    },
    mounted() {
      window.scrollTo(0, 0);
      if (
        document.cookie.includes('csrftoken') ||
        document.cookie.includes('sessionid')
      ) {
        const date = new Date(
          new Date().setTime(new Date().getUTCSeconds() + 1),
        );
        document.cookie = `csrftoken=;expires=${date};`;
        document.cookie = `sessionid=;expires=${date};`;
      }
    },
  };
</script>
