<template>
  <h1 class="mb-2 md:mb-4 text-2xl md:text-3xl font-bold flex justify-center">
    Journey Map
  </h1>
  <span class="mx-auto" :style="{ backgroundColor: selectedOption.bg.color }">
    <div
      ref="product"
      class="md:w-10/12 w-11/12 frame12x8 text-black preview"
      :style="{
        outlineColor: selectedOption.frameColor.color,
        background: selectedOption.bg.color,
      }"
    >
      <div class="relative grid h-full grid-flow-col p-1 grid-rows-8">
        <img
          src="@/assets/images/preview.svg"
          alt="preview.svg"
          class="absolute z-30 h-full pointer-events-none opacity-20"
        />
        <div
          class="grid content-center text-center font-semibold text-base md:text-lg lg:text-xl mb-1"
        >
          <!-- :class="
            !getAlternativeFont
              ? 'font-ext-GreatVibes text-lg md:text-xl lg:text-2xl'
              : 'font-semibold text-base md:text-lg lg:xl mb-1'
          " -->
          {{ getDetails.header }}
        </div>
        <div class="flex justify-between px-2">
          <div class="w-1/3 sm:w-2/5">
            <div class="flex items-center">
              <div class="relative">
                <div class="w-full h-full absolute">
                  <!-- <div id="map1" class="w-full h-full"> -->
                  <map-component
                    :api="getMapStyle[0].mapTileApi"
                    :marker="getMapMarker[0]"
                    :markerIndex="getMapMarker[0].index"
                    :markerType="getMapMarker[0].type"
                    :center="getCenter[0]"
                    :markerLabel="getMarkerLabel[0]"
                    :numOfMapOnFrame="numOfMap"
                    :index="0"
                    :mapShape="getMapShape"
                    :showNav="!mobileView"
                    ref="mapComponent"
                    class="w-full h-full"
                  />
                  <!-- </div> -->
                </div>
                <div class="isolate pointer-events-none">
                  <svg
                    id="left-heart-1"
                    width="100%"
                    max-width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 105 210"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      :style="{ fill: selectedOption.bg.color }"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M105
                    0H0v210.101h105v-1.511l-.009.005-.034-.017c-1.535-.785-7.735-4.82-16.381-11.339-8.649-6.521-19.75-15.53-31.086-26.267C34.82
                    149.5 11.194 121.109 4.357
                    91.89l.07-.016-.07.016c-1.334-5.71-2.829-16.73-2.419-28.74.41-12.009
                    2.725-25.02 9.021-34.703 1.49-2.294 7.953-11.322
                    19.922-18.485C39.43 4.84 51.756.59 65.056 2.146c13.28 1.555
                    27.522 8.9 39.935 26.928l.009-.013V0Z"
                      fill="none"
                    />
                  </svg>
                </div>
              </div>
              <div class="relative">
                <div class="w-full h-full absolute">
                  <map-component
                    :api="getMapStyle[1].mapTileApi"
                    :marker="getMapMarker[1]"
                    :markerIndex="getMapMarker[1].index"
                    :markerType="getMapMarker[1].type"
                    :center="getCenter[1]"
                    :markerLabel="getMarkerLabel[1]"
                    :numOfMapOnFrame="numOfMap"
                    :index="1"
                    :mapShape="getMapShape"
                    :showNav="!mobileView"
                    ref="mapComponent"
                    class="w-full h-full"
                  />
                </div>
                <div class="isolate pointer-events-none">
                  <svg
                    id="left-heart-2"
                    width="100%"
                    max-width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 105 210"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      :style="{ fill: selectedOption.bg.color }"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 0h105v210.101H0v-1.511l.009.005.034-.017c1.535-.785
                      7.735-4.82 16.381-11.339 8.649-6.521 19.75-15.53
                      31.086-26.267 22.67-21.472 46.296-49.863
                      53.133-79.082l-.071-.016.071.016c1.334-5.71 2.829-16.73
                      2.419-28.74-.41-12.009-2.725-25.02-9.021-34.703-1.49-2.294-7.953-11.322-19.922-18.485C65.57
                      4.84 53.244.59 39.944 2.146 26.664 3.701 12.422 11.046.01
                      29.074L0 29.061V0Z"
                      fill="none"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="flex isolate -mt-3 sm:-mt-4 lg:-mt-8 px-1 sm:px-4 lg:px-6 w-full justify-between uppercase text-[8px] sm:text-xs md:text-sm leading-[10px] sm:leading-none md:leading-none text-center"
            >
              <div class="flex-1 flex flex-col items-center">
                <img
                  :src="getDetails.text[0].image"
                  class="w-6 sm:w-12 h-6 sm:h-12 rounded-full bg-slate-300 bg-cover bg-center"
                  alt="image1"
                />
                <!-- <div
                class="w-6 sm:w-12 h-6 sm:h-12 rounded-full bg-slate-300 bg-cover bg-center "
              ></div> -->
                <div class="mt-1">
                  <div
                    class="max-w-[9ch] sm:max-w-[12ch] break-words mb-1 text-[8px] font-semibold md:text-[11px] 2xl:text-base"
                  >
                    <!-- :class="
                      !getAlternativeFont
                        ? 'font-ext-GreatVibes md:text-xs lg:text-base'
                        : 'font-semibold md:text-xs lg:text-base'
                    " -->
                    {{ getDetails.text[0].main }}
                  </div>
                  <p
                    class="max-w-[13ch] sm:max-w-[15ch] break-words 2xl:text-xs lg:text-[9px] text-[5px]"
                  >
                    {{ getDetails.text[0].sub }}
                  </p>
                </div>
              </div>
              <div class="flex-1 flex flex-col items-center">
                <img
                  :src="getDetails.text[1].image"
                  class="w-6 sm:w-12 h-6 sm:h-12 rounded-full bg-slate-300 bg-cover bg-center"
                  alt="image2"
                />
                <!-- <div
                class="w-6 sm:w-12 h-6 sm:h-12 rounded-full bg-slate-300 bg-cover bg-center "
              ></div> -->
                <div class="mt-1">
                  <div
                    class="max-w-[9ch] sm:max-w-[12ch] break-words mb-1 text-[8px] font-semibold md:text-[11px] 2xl:text-base"
                  >
                    <!-- :class="
                      !getAlternativeFont
                        ? 'font-ext-GreatVibes md:text-xs lg:text-base'
                        : 'font-semibold md:text-xs lg:text-base'
                    " -->
                    {{ getDetails.text[1].main }}
                  </div>
                  <p
                    class="max-w-[13ch] sm:max-w-[15ch] break-words 2xl:text-xs lg:text-[9px] text-[5px]"
                  >
                    {{ getDetails.text[1].sub }}
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="w-full mb-6 md:mb-14">
              <div
                class="mt-2 lg:mt-4 max-w-[20ch] mx-auto font-ext-GreatVibes text-center text-sm sm:text-2xl xl:text-3xl leading-none sm:leading-none xl:leading-none"
              >
                {{ getDetails.leftFooter }}
              </div>
            </div> -->
          </div>
          <div class="flex flex-col justify-between w-1/3 sm:w-1/5">
            <!-- <div class="relative md:mt-2">
              <p
                class="absolute top-0 left-1/2 -translate-x-1/2 w-full capitalize text-center font-normal text-[10px] leading-none sm:text-sm sm:leading-none md:text-base md:leading-none lg:text-xl lg:leading-none break-words max-w-[13ch] sm:max-w-[15ch] mx-auto"
              >
              {{ getDetails.subHeader }}
              </p>
            </div> -->
            <div
              class="mt-14 sm:mt-20 md:mt-32 lg:mt-[130px] xl:mt-40 2xl:mt-[212px] center-map"
            >
              <div class="relative w-2/4 sm:w-3/4 lg:w-11/12 xl:w-full mx-auto">
                <div class="w-full h-full absolute">
                  <!-- Code to add the map goes here -->
                  <map-component
                    :api="getMapStyle[2].mapTileApi"
                    :marker="getMapMarker[2]"
                    :markerIndex="getMapMarker[2].index"
                    :markerType="getMapMarker[2].type"
                    :center="getCenter[2]"
                    :markerLabel="getMarkerLabel[2]"
                    :numOfMapOnFrame="numOfMap"
                    :index="2"
                    :mapShape="getMapShape"
                    :showNav="!mobileView"
                    ref="mapComponent"
                    class="w-full h-full"
                  />
                </div>
                <div class="isolate pointer-events-none">
                  <svg
                    id="center-heart"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1417.32 1417.32"
                    xml:space="preserve"
                  >
                    <path
                      :style="{ fill: selectedOption.bg.color }"
                      fill="none"
                      d="M0 0v1418h1418V0H0Zm1387.3 620.065c-92.1
                        394.108-637.327 766.115-678.709
                        787.209-41.266-21.094-586.493-393.101-678.709-787.209-17.982-77-40.344-297.312
                        44.494-427.798 10.029-15.446 53.601-76.308
                        134.29-124.606C323.936-1.386 540.989-46.918 708.591
                        197.109 876.309-47.033 1093.247-1.386 1208.517
                        67.661c80.689 48.298 124.261 109.275 134.289 124.606
                        84.839 130.371 62.477 350.798 44.494 427.798Z"
                    />
                    <path
                      d="M1387.3 620.065c-92.1 394.108-637.327
                          766.115-678.709
                          787.209-41.266-21.094-586.493-393.101-678.709-787.209-17.982-77-40.344-297.312
                          44.494-427.798 10.029-15.446 53.601-76.308
                          134.29-124.606C323.936-1.386 540.989-46.918 708.591
                          197.109 876.309-47.033 1093.247-1.386 1208.517
                          67.661c80.689 48.298 124.261 109.275 134.289 124.606
                          84.839 130.371 62.477 350.798 44.494 427.798Z"
                      style="
                        fill: none;
                        fill-rule: nonzero;
                        stroke: #000;
                        stroke-width: 1px;
                      "
                    />
                  </svg>
                </div>
              </div>
              <div
                class="mt-1 uppercase text-[8px] sm:text-xs md:text-sm leading-[10px] sm:leading-none md:leading-none text-center"
              >
                <div class="flex flex-col items-center">
                  <!-- <div
                          class="w-6 sm:w-12 h-6 sm:h-12 rounded-full
                          bg-slate-300 bg-cover bg-center
                          drop-shadow-myDropShadow
                          md:drop-shadow-myDropShadow-2xl"></div> -->
                  <img
                    :src="getDetails.text[2].image"
                    class="w-6 sm:w-12 h-6 sm:h-12 rounded-full bg-slate-300 bg-cover bg-center"
                    alt="image3"
                  />
                  <div class="mt-1">
                    <div
                      class="max-w-[9ch] sm:max-w-[12ch] break-words mb-1 text-[8px] font-semibold md:text-[11px] 2xl:text-base"
                    >
                      <!-- :class="
                        !getAlternativeFont
                          ? 'font-ext-GreatVibes md:text-xs lg:text-base'
                          : 'font-semibold md:text-xs lg:text-base'
                      " -->
                      {{ getDetails.text[2].main }}
                    </div>
                    <p
                      class="max-w-[13ch] sm:max-w-[15ch] break-words 2xl:text-xs lg:text-[9px] text-[5px]"
                    >
                      {{ getDetails.text[2].sub }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="w-full">
                <div
                  class="mt-2 lg:mt-4 max-w-[20ch] mx-auto font-ext-GreatVibes text-center text-sm sm:text-2xl xl:text-3xl leading-none sm:leading-none xl:leading-none"
                >
                  {{ getDetails.mainFooter }}
                </div>
              </div> -->
            </div>
          </div>
          <div class="w-1/3 sm:w-2/5">
            <div class="flex items-center">
              <div class="relative">
                <div class="w-full h-full absolute">
                  <!-- Code to add the map goes here -->
                  <map-component
                    :api="getMapStyle[3].mapTileApi"
                    :marker="getMapMarker[3]"
                    :markerIndex="getMapMarker[3].index"
                    :markerType="getMapMarker[3].type"
                    :center="getCenter[3]"
                    :markerLabel="getMarkerLabel[3]"
                    :numOfMapOnFrame="numOfMap"
                    :index="3"
                    :mapShape="getMapShape"
                    :showNav="!mobileView"
                    ref="mapComponent"
                    class="w-full h-full"
                  />
                </div>
                <div class="isolate pointer-events-none">
                  <svg
                    id="right-heart-1"
                    width="100%"
                    max-width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 105 210"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      :style="{ fill: selectedOption.bg.color }"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M105
                            0H0v210.101h105v-1.511l-.009.005-.034-.017c-1.535-.785-7.735-4.82-16.381-11.339-8.649-6.521-19.75-15.53-31.086-26.267C34.82
                            149.5 11.194 121.109 4.357
                            91.89l.07-.016-.07.016c-1.334-5.71-2.829-16.73-2.419-28.74.41-12.009
                            2.725-25.02 9.021-34.703 1.49-2.294 7.953-11.322
                            19.922-18.485C39.43 4.84 51.756.59 65.056
                            2.146c13.28 1.555 27.522 8.9 39.935
                            26.928l.009-.013V0Z"
                      fill="none"
                    />
                  </svg>
                </div>
              </div>
              <div class="relative">
                <div class="w-full h-full absolute">
                  <div id="map4" class="w-full h-full">
                    <!-- Code to add the map goes here -->
                    <map-component
                      :api="getMapStyle[4].mapTileApi"
                      :marker="getMapMarker[4]"
                      :markerIndex="getMapMarker[4].index"
                      :markerType="getMapMarker[4].type"
                      :center="getCenter[4]"
                      :markerLabel="getMarkerLabel[4]"
                      :numOfMapOnFrame="numOfMap"
                      :index="4"
                      :mapShape="getMapShape"
                      :showNav="!mobileView"
                      ref="mapComponent"
                    />
                  </div>
                </div>
                <div class="isolate pointer-events-none">
                  <svg
                    id="right-heart-2"
                    width="100%"
                    max-width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 105 210"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      :style="{ fill: selectedOption.bg.color }"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0
                              0h105v210.101H0v-1.511l.009.005.034-.017c1.535-.785
                              7.735-4.82 16.381-11.339 8.649-6.521 19.75-15.53
                              31.086-26.267 22.67-21.472 46.296-49.863
                              53.133-79.082l-.071-.016.071.016c1.334-5.71
                              2.829-16.73
                              2.419-28.74-.41-12.009-2.725-25.02-9.021-34.703-1.49-2.294-7.953-11.322-19.922-18.485C65.57
                              4.84 53.244.59 39.944 2.146 26.664 3.701 12.422
                              11.046.01 29.074L0 29.061V0Z"
                      fill="none"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="flex isolate -mt-3 sm:-mt-4 lg:-mt-8 px-1 sm:px-4 lg:px-6 w-full justify-between uppercase text-[8px] sm:text-xs md:text-sm leading-[10px] sm:leading-none md:leading-none text-center"
            >
              <div class="flex-1 flex flex-col items-center">
                <img
                  :src="getDetails.text[3].image"
                  class="w-6 sm:w-12 h-6 sm:h-12 rounded-full bg-slate-300 bg-cover bg-center"
                  alt="image4"
                />
                <!-- <div
                            class="w-6 sm:w-12 h-6 sm:h-12 rounded-full
                            bg-slate-300 bg-cover bg-center
                            drop-shadow-myDropShadow
                            md:drop-shadow-myDropShadow-2xl"></div> -->
                <div class="mt-1">
                  <div
                    class="max-w-[9ch] sm:max-w-[12ch] break-words mb-1 text-[8px] font-semibold md:text-[11px] 2xl:text-base"
                  >
                    <!-- :class="
                      !getAlternativeFont
                        ? 'font-ext-GreatVibes md:text-xs lg:text-base'
                        : 'font-semibold md:text-xs lg:text-base'
                    " -->
                    {{ getDetails.text[3].main }}
                  </div>
                  <p
                    class="max-w-[13ch] sm:max-w-[15ch] break-words 2xl:text-xs lg:text-[9px] text-[5px]"
                  >
                    {{ getDetails.text[3].sub }}
                  </p>
                </div>
              </div>
              <div class="flex-1 flex flex-col items-center">
                <!-- <div
                            class="w-6 sm:w-12 h-6 sm:h-12 rounded-full
                            bg-slate-300 bg-cover bg-center
                            drop-shadow-myDropShadow
                            md:drop-shadow-myDropShadow-2xl"></div> -->
                <img
                  :src="getDetails.text[4].image"
                  class="w-6 sm:w-12 h-6 sm:h-12 rounded-full bg-slate-300 bg-cover bg-center"
                  alt="image5"
                />
                <div class="mt-1">
                  <div
                    class="max-w-[9ch] sm:max-w-[12ch] break-words mb-1 text-[8px] font-semibold md:text-[11px] 2xl:text-base"
                  >
                    <!-- :class="
                      !getAlternativeFont
                        ? 'font-ext-GreatVibes md:text-xs lg:text-base'
                        : 'font-semibold md:text-xs lg:text-base'
                    " -->
                    {{ getDetails.text[4].main }}
                  </div>
                  <p
                    class="max-w-[13ch] sm:max-w-[15ch] break-words 2xl:text-xs lg:text-[9px] text-[5px]"
                  >
                    {{ getDetails.text[4].sub }}
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="w-full mb-6 md:mb-14">
              <div
                class="mt-2 lg:mt-4 max-w-[20ch] mx-auto font-ext-GreatVibes text-center text-sm sm:text-2xl xl:text-3xl leading-none sm:leading-none xl:leading-none"
              >
                {{ getDetails.rightFooter }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <p class="mt-5 text-xs text-center opacity-60">
      *Preview is adjusted as per your screen size, actual print would be to the
      size.
    </p>
    <h2
      class="my-3 text-base font-bold md:text-xl text-center w-10/12 md:w-[500px] xl:w-[750px] mx-auto"
    >
      Create a Customer Journey Map
    </h2>
    <div class="text-sm w-10/12 md:w-[500px] xl:w-[700px] mx-auto">
      <p>
        A journey map is an ocular representation of the steps or stages that a
        customer goes through while interacting with a product or service. It
        offers a comprehensive overview of the entire customer experience, from
        the initial awareness stage to the final purchase decision and beyond.
      </p>
      <p class="my-2">
        To create a journey map, it's essential to put yourself in the
        customer's shoes and think about every touchpoint they have with your
        business. This includes interactions with your website, customer
        service, social media, and more.
      </p>
      <p class="my-2">
        The Rare Window, a boutique customer experience agency, specializes in
        creating custom journey
        <a href="https://therarewindow.com/story-map" class="underline"
          >location maps</a
        >
        for businesses. We comprehend the importance of a customer-centric
        approach and work meticulously with our clients to ensure their journey
        maps accurately represent their customers' experiences.
      </p>
      <p class="my-2">
        A
        <a href="https://therarewindow.com/story-map" class="underline"
          >journey map</a
        >
        is a powerful tool for businesses looking to improve their customer
        experience. With the help of The Rare Window, businesses can create a
        detailed and actionable journey map that can help them improve customer
        satisfaction, loyalty, and, ultimately, their bottom line.
      </p>
    </div>
  </span>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import MapComponent from './map/MapComponent.vue';

  export default {
    components: {
      MapComponent,
    },
    data() {
      return {
        capturing: false,
        setImage: null,
        blob: null,
      };
    },
    computed: {
      ...mapGetters('journeyMap', [
        'selectedOption',
        'selectedFrameSize',
        'getDetails',
        'getAlternativeFont',
      ]),
      ...mapGetters('mapObj', [
        'mapDataUrl',
        'getMapMarker',
        'getMapStyle',
        'getCenter',
        'getMarkerLabel',
      ]),
      mobileView() {
        return window.screen.width <= 480;
      },
    },
    mounted() {
      this.setMapFrame(this.$refs.product);
    },
    methods: {
      ...mapMutations('mapObj', ['setMapFrame']),
    },
  };
</script>

<style scoped>
  .map-wrap {
    position: relative;
    flex-grow: 2;
  }
  .map-wrap.Square {
    aspect-ratio: 1 / 1;
  }
  #textOnMap {
    position: relative;
    z-index: 10;
    text-align: center;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .preview {
    position: relative;
    margin: auto;
    outline: 6px solid transparent;
  }
  .frame12x8 {
    aspect-ratio: 12 / 8;
  }
  @media screen and (min-width: 480px) {
    .preview {
      outline: 12px solid transparent;
    }
  }
  @media screen and (device-width: 1440px) {
    .center-map {
      margin-top: 13rem;
    }
  }
</style>
