<template>
  <div>
    <base-text type="h2">Choose custom map</base-text>
    <base-text type="h5" class="max-w-lg mx-auto"
      >Select you favourite map, then design and customize according to your
      needs and preference</base-text
    >
    <div class="py-8 overflow-y-auto">
      <div class="flex mx-auto w-max">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="w-56 mx-3"
          :class="product.link == '#' ? 'opacity-50 pointer-events-none' : ''"
        >
          <router-link :to="product.link">
            <img
              :src="require(`../assets/images/${product.img}`)"
              class="rounded-lg"
              :alt="`product-img${index}`"
            />
          </router-link>
          <base-text type="h4" class="mt-3 font-semibold">{{
            product.title
          }}</base-text>
          <base-button class="mx-auto" link :to="product.link"
            >Create now</base-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseButton from '../components/ui/BaseButton.vue';
  import BaseText from '../components/ui/BaseText.vue';
  import { useHead } from 'unhead';
  export default {
    components: {
      BaseText,
      BaseButton,
    },
    data() {
      return {
        products: [
          {
            img: '1.webp',
            title: 'Story Map',
            link: '/story-map',
          },
          {
            img: '2.webp',
            title: 'Time Capsule Map',
            link: '/time-capsule-map',
          },
          {
            img: '3.webp',
            title: 'Celestial Map',
            link: '/celestial-map',
          },
          {
            img: '4.webp',
            title: 'The Map of Love',
            // link: '#',
            link: '/theMapOfLove',
          },
          {
            img: '5.webp',
            title: 'Journey Map',
            // link: '#',
            link: '/journey-map',
          },
        ],
      };
    },
    created() {
      useHead({
        title: 'Product',
        meta: [
          {
            name: 'title',
            content:
              'Personalized Gifts Online for Him & Her |The Rare Window ',
          },
          {
            name: 'description',
            content:
              'Give your loved ones a reason to smile with unique personalized gifts. Explore personalized gifts for couples, family & friends.',
          },
        ],
      });
    },
  };
</script>

<style></style>
