export default {
  setNumberOfMaps(state, payload) {
    state.numOfMaps = payload;
    // if (payload == 1) {
    //   state.frameSize = "8x12";
    // } else {
    //   state.frameSize = "12x8";
    // }
  },
  setBg(state, payload) {
    state.bg = payload;
    state.mapShape.color = state.bg.color;
  },
  setDate(state, payload) {
    let date = new Date(payload.date);
    state.design[payload.index - 1].date = date;
  },
  setTime(state, payload) {
    state.design[payload.index - 1].time = payload.time;
  },
  setStarMapStyle(state, payload) {
    state.design[payload.key - 1].starBg = payload.starBg;
    state.celestialConfig.background.fill = payload.starBg;
    state.celestialConfigReset.background.fill = payload.starBg;
    if (payload.starBg == '#FFFFFF') {
      state.celestialConfig.stars.propernameStyle.fill = '#0f1111';
      state.celestialConfig.stars.style.fill = '#0f1111';
      state.celestialConfig.constellations.lineStyle.stroke = '#0f1111';
      state.celestialConfig.constellations.nameStyle.fill = '#0f1111';
      state.celestialConfigReset.stars.propernameStyle.fill = '#0f1111';
      state.celestialConfigReset.stars.style.fill = '#0f1111';
      state.celestialConfigReset.constellations.lineStyle.stroke = '#0f1111';
      state.celestialConfigReset.constellations.nameStyle.fill = '#0f1111';
    } else {
      state.celestialConfig.stars.propernameStyle.fill = '#eeeeee';
      state.celestialConfig.stars.style.fill = '#eeeeee';
      state.celestialConfig.constellations.lineStyle.stroke = '#eeeeee';
      state.celestialConfig.constellations.nameStyle.fill = '#eeeeee';
      state.celestialConfigReset.stars.propernameStyle.fill = '#eeeeee';
      state.celestialConfigReset.stars.style.fill = '#eeeeee';
      state.celestialConfigReset.constellations.lineStyle.stroke = '#eeeeee';
      state.celestialConfigReset.constellations.nameStyle.fill = '#eeeeee';
    }
  },
  setStarMapStarNameVisibility(state, payload) {
    state.design[payload.key - 1].propername = payload.propername;
    state.celestialConfig.stars.propername = payload.propername;
    state.celestialConfigReset.stars.propername = payload.propername;
    state.celestialConfig.stars.designation = payload.propername;
    state.celestialConfigReset.stars.designation = payload.propername;
    state.celestialConfig.constellations.names = payload.propername;
    state.celestialConfigReset.constellations.names = payload.propername;
  },
  setDetailsHeaderText(state, payload) {
    state.details.header = payload;
  },
  setDetailsFooterText(state, payload) {
    state.details.footer = payload;
  },
  setTitle(state, payload) {
    state.details.text[payload.index - 1].main = payload.title;
  },
  setSubTitle(state, payload) {
    state.details.text[payload.index - 1].sub = payload.sub;
  },
  setImageTitle(state, payload) {
    state.title = payload;
  },
  setImageSubTitle(state, payload) {
    state.subText = payload;
  },
  setFrameColor(state, payload) {
    state.frameColor.color = payload;
  },
  setGiftingMessage(state, payload) {
    state.setGiftingMessage = payload;
  },
  setGiftWrapping(state, payload) {
    state.giftWrapping = payload;
  },
  setPrintingType(state, payload) {
    state.printingType = payload.name;
  },
  setMapShape(state, payload) {
    state.mapShape.name = payload.name;
    state.mapShape.color = state.bg.color;
  },
  setStarMapDiv(state, payload) {
    state.starMapDiv = payload;
  },
  setAlternativeFont(state, payload) {
    state.details.alternativeFont = payload;
  },
};
