import domtoimage from 'dom-to-image-more';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Map, Marker } from 'maplibre-gl';

export default {
  async createImg(context) {
    let isImageAdded = false;
    let frameWidth = 0;
    let frameHeight = 0;
    let orientation = '';
    let mapDetails = context.rootGetters['theMapOfLove/getDetail'];

    frameWidth = 1152;
    frameHeight = 768;
    orientation = 'landscape';

    const doc = new jsPDF({
      orientation: orientation,
      unit: 'px',
      format: [frameWidth, frameHeight],
    });

    let docWidth = doc.internal.pageSize.getWidth();
    let docHeight = doc.internal.pageSize.getHeight();

    let frame = document.createElement('div');
    frame.style.height = docHeight + 'px';
    frame.style.width = docWidth + 'px';
    frame.className = 'printFrame';
    frame.id = 'printFrame';
    document.body.appendChild(frame);

    let parent = document.createElement('div');
    parent.id = 'parent';
    parent.className =
      'bg-dark-teal backgroundImage-frame bg-cover bg-center h-full mx-auto p-3';

    let firstContent = document.createElement('div');
    firstContent.id = 'firstContent';
    firstContent.className =
      'z-20 relative pt-12 flex justify-center gap-1 xl:gap-0';

    let name1Content = document.createElement('div');
    name1Content.className = 'self-end drop-shadow-myDropShadow-xs mb-14';

    let name1InnerConetent = document.createElement('div');
    name1InnerConetent.className =
      'clipPath flex justify-start items-center bg-pink-800 px-4 py-2';

    let name1Text = document.createElement('p');
    name1Text.className =
      'font-ext-GreatVibes font-normal text-2xl text-center text-white leading-none w-[10ch] max-w-[15ch] translate-x-[-2px] translate-y-px';
    name1Text.innerText = mapDetails.name1;

    let centerMapSection = document.createElement('div');
    centerMapSection.className = 'flex isolate';

    let circleMap1 = document.createElement('div');
    circleMap1.id = 'circleMap1Print';
    circleMap1.className =
      'overflow-hidden w-80 h-80 rounded-full -mr-6 z-10 bg-slate-600 map-wrap';

    let circleMap2 = document.createElement('div');
    circleMap2.id = 'circleMap2Print';
    circleMap2.className =
      'overflow-hidden w-80 h-80 rounded-full bg-slate-500';

    let name2Content = document.createElement('div');
    name2Content.className = 'self-end drop-shadow-myDropShadow-xs mb-14';
    let name2InnerConetent = document.createElement('div');
    name2InnerConetent.className =
      'clipPath flex justify-start items-center bg-cyan-700 px-4 py-2';

    let name2Text = document.createElement('p');
    name2Text.className =
      'font-ext-GreatVibes font-normal text-2xl text-center text-white leading-none w-[10ch] max-w-[15ch] translate-x-[-2px] translate-y-px';
    name2Text.innerText = mapDetails.name2;

    name1InnerConetent.appendChild(name1Text);
    name1Content.appendChild(name1InnerConetent);
    firstContent.appendChild(name1Content);

    centerMapSection.appendChild(circleMap1);
    centerMapSection.appendChild(circleMap2);
    firstContent.appendChild(centerMapSection);

    name2InnerConetent.appendChild(name2Text);
    name2Content.appendChild(name2InnerConetent);
    firstContent.appendChild(name2Content);

    parent.appendChild(firstContent);

    let secondContent = document.createElement('div');
    secondContent.id = 'secondContent';
    secondContent.className =
      'z-10 relative -mt-10 pt-8 flex justify-around gap-36';

    // Left Section Start

    let leftContent = document.createElement('div');
    leftContent.className = 'flex items-end';

    let leftImage = document.getElementById('female-svg').cloneNode(true);
    leftImage.className = 'drop-shadow-human-lg z-10 w-[5.5rem] mr-[-3.15rem]';

    let leftparent = document.createElement('div');
    leftparent.className = 'relative self-start';

    let leftSvgParent = document.createElement('div');
    leftSvgParent.className = 'absolute pointer-events-none';

    let leftSvg = document.getElementById('leftHeart').cloneNode(true);

    let leftHeartMapParent = document.createElement('div');
    leftHeartMapParent.className = 'drop-shadow-heart-lg';
    let leftHeartMapShape = document.createElement('div');
    leftHeartMapShape.id = 'leftHeartMapPrint';
    leftHeartMapShape.className = 'aspect-square bg-slate-400 relative w-72';
    leftHeartMapShape.style = 'clip-path: url(#leftHeartClipPath)';

    let leftHeartMapImageParent = document.createElement('div');
    leftHeartMapImageParent.className =
      'absolute left-[30%] top-[18%] w-14 h-14 rounded-full overflow-hidden drop-shadow-myDropShadow-sm bg-slate-500';

    let leftHeartMapImage = document.createElement('img');
    leftHeartMapImage.className = 'object-cover object-center';
    leftHeartMapImage.alt = 'leftUserImage';
    leftHeartMapImage.src = mapDetails.leftImage;

    leftHeartMapImageParent.appendChild(leftHeartMapImage);

    leftHeartMapParent.appendChild(leftHeartMapShape);
    leftHeartMapParent.appendChild(leftHeartMapImageParent);
    leftparent.appendChild(leftHeartMapParent);

    leftSvgParent.appendChild(leftSvg);
    leftparent.appendChild(leftSvgParent);

    leftContent.appendChild(leftImage);
    leftContent.appendChild(leftparent);
    secondContent.appendChild(leftContent);

    // Center Section Start

    let centerContent = document.createElement('div');
    centerContent.className =
      'absolute top-10 -bottom-1 left-1/2 -translate-x-1/2 flex flex-col justify-between gap-0 items-center';
    let userInfo = document.createElement('div');
    userInfo.className =
      'text-base text-center font-bold leading-tight w-[22ch]';

    let eventName = document.createElement('p');
    eventName.className = 'uppercase';
    eventName.innerText = mapDetails.event;

    let dateTime = document.createElement('p');
    dateTime.innerText = `${mapDetails.date} | ${mapDetails.time}`;

    let location = document.createElement('p');
    location.className = 'uppercase';
    location.innerText = mapDetails.location;

    userInfo.appendChild(eventName);
    userInfo.appendChild(dateTime);
    userInfo.appendChild(location);

    let centerImageParent = document.createElement('div');
    centerImageParent.className =
      'w-20 h-20 bg-slate-500 rounded-full drop-shadow-myDropShadow-sm overflow-hidden';

    let centerImage = document.createElement('img');
    centerImage.className = 'object-cover object-center';
    centerImage.alt = 'centerUserImage';
    centerImage.src = mapDetails.centerImage;

    centerImageParent.appendChild(centerImage);

    let quoteParent = document.createElement('div');
    quoteParent.className =
      'font-ext-GreatVibes font-normal text-3xl text-center leading-none tracking-wide w-[18ch]';

    let quote = document.createElement('p');
    quote.innerText = mapDetails.quote;

    quoteParent.appendChild(quote);

    centerContent.appendChild(userInfo);
    centerContent.appendChild(centerImageParent);
    centerContent.appendChild(quoteParent);
    secondContent.appendChild(centerContent);

    // Right Section Start

    let rightContent = document.createElement('div');
    rightContent.className = 'flex items-end';

    let rightParent = document.createElement('div');
    rightParent.className = 'relative self-start';

    let rightSvgParent = document.createElement('div');
    rightSvgParent.className = 'absolute pointer-events-none';

    let rightSvg = document.getElementById('rightHeart').cloneNode(true);

    rightSvgParent.appendChild(rightSvg);
    rightParent.appendChild(rightSvgParent);

    let rightHeartMapParent = document.createElement('div');
    rightHeartMapParent.className = 'drop-shadow-heart-lg';

    let rightHeartMapShape = document.createElement('div');
    rightHeartMapShape.id = 'rightHeartMapPrint';
    rightHeartMapShape.className = 'w-72 aspect-square relative bg-slate-400';
    rightHeartMapShape.style = 'clip-path: url(#rightHeartClipPath)';

    let rightHeartImageParent = document.createElement('div');
    rightHeartImageParent.className =
      'absolute right-[35%] top-[18%] w-14 h-14 rounded-full overflow-hidden drop-shadow-myDropShadow-sm bg-slate-500';

    let rightHeartMapImage = document.createElement('img');
    rightHeartMapImage.alt = 'rightUserImage';
    rightHeartMapImage.src = mapDetails.rightImage;
    rightHeartMapImage.className = 'object-cover object-center';

    rightHeartImageParent.appendChild(rightHeartMapImage);

    rightHeartMapParent.appendChild(rightHeartMapShape);
    rightHeartMapParent.appendChild(rightHeartImageParent);

    rightParent.appendChild(rightHeartMapParent);

    let rightImage = document.getElementById('male-svg').cloneNode(true);
    rightImage.className = 'drop-shadow-human-lg z-10 ml-[-3.9rem] w-[5.5rem]';

    rightContent.appendChild(rightParent);
    rightContent.appendChild(rightImage);

    secondContent.appendChild(rightContent);
    parent.appendChild(secondContent);
    frame.appendChild(parent);

    let payload = {
      i: 0,
      docWidth: docWidth,
    };
    const data = await context.dispatch('captureMap', payload);
    if (data) return (isImageAdded = true);
    else return isImageAdded;
  },
  async captureMap(context, { i, docWidth }) {
    return new Promise((resolve, reject) => {
      const mapMarker = context.rootGetters['mapObj/getMapMarker'];
      const center = context.rootGetters['mapObj/getCenter'];
      const zoom = context.rootGetters['mapObj/getZoom'];
      const mapStyle = context.rootGetters['mapObj/getMapStyle'];
      let selectedOptions = context.rootGetters['theMapOfLove/selectedOption'];
      let celestial = context.rootGetters['celestialMap/getCelestial'];
      let config = {
        container: 'celestial-map1',
        width: 320,
        projection: 'stereographic',
        controls: false,
        orientationfixed: true,
        follow: 'zenith',
        disableAnimations: false,
        zoomlevel: 3,
        interactive: false,
        stars: {
          colors: false,
          size: 4,
          limit: 6,
          exponent: -0.26,
          designation: false,
          style: { fill: '#b73152', opacity: 1 },
          propername: false,
          propernameType: 'name',
          propernameStyle: {
            fill: '#b73152',
            font: `10px Helvetica, Arial, sans-serif`,
            align: 'center',
            baseline: 'center',
          },
          propernameLimit: 2.0,
        },
        dsos: {
          show: false,
          size: 6,
          designation: false,
          propername: false,
          data: 'dsos.6.json',
        },
        constellations: {
          names: false,
          namesType: 'en',
          nameStyle: {
            fill: '#b73152',
            align: 'center',
            baseline: 'middle',
            font: [
              '14px Helvetica, Arial, sans-serif',
              '12px Helvetica, Arial, sans-serif',
              '11px Helvetica, Arial, sans-serif',
            ],
          },
          lines: true,
          lineStyle: { stroke: '#b73152', width: 2, opacity: 0.6 },
          bounds: false,
          boundStyle: {
            stroke: '#cccc00',
            width: 0.5,
            opacity: 0.8,
            dash: [2, 4],
          },
        },
        planets: {
          show: false,
          which: [
            'sol',
            'mer',
            'ven',
            'ter',
            'lun',
            'mar',
            'jup',
            'sat',
            'ura',
            'nep',
          ],
          symbolType: 'disk',
          symbols: {
            sol: {
              symbol: '\u2609',
              letter: 'Su',
              fill: '#ffff00',
              size: '24',
            },
            mer: { symbol: '\u263F', letter: 'Me', fill: '#cccccc' },
            ven: { symbol: '\u2640', letter: 'V', fill: '#eeeecc' },
            ter: { symbol: '\u2295', letter: 'T', fill: '#00ccff' },
            lun: {
              symbol: '\u25CF',
              letter: 'L',
              fill: '#ffffff',
              size: '24',
            },
            mar: { symbol: '\u2642', letter: 'Ma', fill: '#ff6600' },
            cer: { symbol: '\u26B3', letter: 'C', fill: '#cccccc' },
            ves: { symbol: '\u26B6', letter: 'Ma', fill: '#cccccc' },
            jup: { symbol: '\u2643', letter: 'J', fill: '#ffaa33' },
            sat: { symbol: '\u2644', letter: 'Sa', fill: '#ffdd66' },
            ura: { symbol: '\u2645', letter: 'U', fill: '#66ccff' },
            nep: { symbol: '\u2646', letter: 'N', fill: '#6666ff' },
            plu: { symbol: '\u2647', letter: 'P', fill: '#aaaaaa' },
            eri: { symbol: '\u26AA', letter: 'E', fill: '#eeeeee' },
          },
          names: true,
          nameStyle: {
            fill: '#cccccc',
            font: "17px 'Lucida Sans Unicode', 'DejaVu Sans'",
            align: 'right',
            baseline: 'top',
          },
          namesType: 'desig',
        },
        mw: {
          show: false,
        },
        lines: {
          graticule: {
            show: true,
            stroke: '#b73152',
            width: 2,
            opacity: 0.8,
            lon: {
              pos: [''],
              fill: '#eee',
              font: '10px Helvetica, Arial, sans-serif',
            },
            lat: {
              pos: [''],
              fill: '#eee',
              font: '10px Helvetica, Arial, sans-serif',
            },
          },
          equatorial: {
            show: true,
            stroke: '#aaaaaa',
            width: 1.3,
            opacity: 0.7,
          },
          ecliptic: {
            show: false,
            stroke: '#66cc66',
            width: 1.3,
            opacity: 0.7,
          },
          galactic: {
            show: false,
            stroke: '#cc6666',
            width: 1.3,
            opacity: 0.7,
          },
          supergalactic: {
            show: false,
            stroke: '#cc66cc',
            width: 1.3,
            opacity: 0.7,
          },
        },
        background: {
          fill: '#ffffff',
          opacity: 1,
          stroke: 'rgba(0,0,0,0.5)',
          width: 1.5,
        },
      };
      let configReset =
        context.rootGetters['celestialMap/getCelestialConfigReset'];
      const hidden = document.createElement('div');
      hidden.className = 'hidden-map';
      document.body.appendChild(hidden);
      const container = document.createElement('div');
      if (i == 0 || i == 1) {
        container.style.width = '320px';
        container.style.height = '320px';
      }
      if (i == 2 || i == 3) {
        container.style.width = '288px';
        container.style.height = '288px';
      }
      hidden.appendChild(container);
      celestial.apply(config);
      celestial.resize({ width: 0 });
      let main_content = document.createElement('div');
      if (i == 0) {
        main_content.className = 'w-full h-full relative';
        document.getElementById('circleMap1Print').appendChild(main_content);
      }
      if (i == 1) {
        main_content.className = 'w-full h-full relative';
        document.getElementById('circleMap2Print').appendChild(main_content);
      }
      if (i == 2) {
        main_content.className = 'w-full h-full relative';
        document.getElementById('leftHeartMapPrint').appendChild(main_content);
      }
      if (i == 3) {
        main_content.className = 'w-full h-full relative';
        document.getElementById('rightHeartMapPrint').appendChild(main_content);
      }

      let img = document.createElement('img');
      if (i == 0) {
        img.className = 'w-full h-full border-none Print';
      } else if (i == 2 || i == 3) {
        img.className = 'w-full h-full border Print';
      } else {
        img.className = 'w-full border-none Print';
      }
      main_content.appendChild(img);
      const rendermap = new Map({
        container: container,
        style: mapStyle[i].mapTileApi,
        center: [center[i].lon, center[i].lat],
        zoom: zoom,
        attributionControl: false,
        zoomControl: true,
      });
      let markerIcon = document.createElement('img');
      markerIcon.src = require(`../../../assets/images/${mapMarker[i].image}`);
      if (mapMarker[i].type == 'character') {
        markerIcon.className = 'w-1/2 h-auto';
      } else {
        markerIcon.className = 'w-1/5 h-auto';
      }

      rendermap.once('idle', () => {
        new Marker(markerIcon)
          .setLngLat(rendermap.getCenter())
          .addTo(rendermap);
        html2canvas(
          document.getElementsByClassName('hidden-map')[0].children[0],
          {
            useCORS: true,
            backgroundColor: null,
            allowTaint: true,
          },
        ).then(
          (mapCanvas) => {
            if (i == 0) {
              if (window.screen.width <= 480) {
                const mapData = document.getElementById('celestial-map1');
                mapData.parentElement.className =
                  'overflow-hidden w-60 h-60 rounded-full -mr-6 z-10 bg-slate-600 map-wrap';
              }
              domtoimage
                .toPng(document.getElementById('celestial-map1'))
                .then(function (dataUrl) {
                  celestial.location([center[0].lat, center[0].lon]);
                  celestial.apply(configReset);
                  celestial.resize({ width: 0 });
                  img.src = dataUrl;
                });
            } else img.src = mapCanvas.toDataURL('image/png');
            rendermap.remove();
            hidden.parentNode?.removeChild(hidden);
            if (i < selectedOptions.numOfMaps - 1) {
              i++;
              let payload = {
                i: i,
                docWidth: docWidth,
              };
              context.dispatch('captureMap', payload);
            } else {
              setTimeout(() => {
                let imgPayload = {
                  final: null,
                  base: null,
                  map: null,
                };
                domtoimage.toPng(document.getElementById('printFrame')).then(
                  (finalCanvas) => {
                    let previewImg = finalCanvas;
                    context.commit('checkout/setPreviewImg', previewImg, {
                      root: true,
                    });
                    imgPayload.final = previewImg;

                    let maps = document.querySelectorAll('.Print');
                    for (let x = 0; x < maps.length; x++) {
                      maps[x].style.display = 'none';
                    }
                    domtoimage
                      .toPng(document.getElementById('printFrame'))
                      .then(
                        (baseCanvas) => {
                          imgPayload.base = baseCanvas;

                          for (let x = 0; x < maps.length; x++) {
                            maps[x].removeAttribute('style');
                          }
                          document.getElementById(
                            'printFrame',
                          ).style.backgroundColor = 'transparent';
                          domtoimage
                            .toPng(document.getElementById('printFrame'))
                            .then(
                              (mapCanvas) => {
                                imgPayload.map = mapCanvas;
                                document.body.removeChild(
                                  document.getElementById('printFrame'),
                                );
                                context.commit(
                                  'checkout/setImages',
                                  imgPayload,
                                  {
                                    root: true,
                                  },
                                );
                                resolve(imgPayload);
                              },
                              (error) => {
                                reject(error);
                              },
                            );
                        },
                        (error) => {
                          reject(error);
                        },
                      );
                  },
                  (error) => {
                    reject(error);
                  },
                );
              }, 500);
            }
          },
          (error) => {
            reject(error);
          },
        );
      });
    });
  },
};
