<template>
  <div class="flex flex-wrap pt-2">
    <span
      v-for="(opt, index) in options"
      :key="index"
      class="m-1 cursor-pointer"
      @click="selectedOptions(index, opt)"
    >
      <div
        class="relative w-16 h-16 mx-auto rounded-md"
        :class="selectedMarker == index ? 'selected' : ''"
      >
        <img
          v-if="selectedMarker == index"
          src="../../assets/images/checked.svg"
          alt="checked.svg"
          class="absolute z-10 -right-3 -top-3"
        />
        <img
          v-if="opt.image !== ''"
          :src="require(`../../assets/images/${opt.image}`)"
          :alt="`option-img${index}`"
          class="object-contain w-full h-full border-2 rounded-md"
        />
        <span
          v-else
          class="absolute inset-0 border-2 border-gray-200 rounded-md"
          :style="{ backgroundColor: opt.color }"
        ></span>
      </div>
      <div v-if="opt.name !== ''" class="mt-2 text-xs text-center">
        {{ opt.name }}
      </div>
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      options: {
        type: Array,
        require: true,
        default: () => [
          {
            id: 0,
            image: '',
            color: '#F7F7F7',
            name: '',
          },
        ],
      },
    },
    data() {
      return {
        selectedMarker: 0,
      };
    },
    mounted() {
      this.$emit('selectedOptionHandler', this.options[0]);
      if (this.$route.name == 'timeCapsuleMap') {
        const data = this.options.find(
          (x) => x.image == 'mapMarker/character/inLove/A.png',
        );
        if (data) {
          this.$emit('selectedOptionHandler', data);
          this.selectedMarker = data.id - 1;
        }
      }
      if (this.$route.name == 'storyMap') {
        const data = this.options.find(
          (x) => x.image == 'mapMarker/icons/heart/B.png',
        );
        if (data) {
          this.$emit('selectedOptionHandler', data);
          this.selectedMarker = 19;
        }
      }
      if (this.$route.name == 'journeyMap') {
        const data = this.options.find((x) => x.color == '#f0ebeb');
        if (data) {
          this.$emit('selectedOptionHandler', data);
          this.selectedMarker = data.id;
        }
      }
    },
    methods: {
      selectedOptions(index, option) {
        this.selectedMarker = index;
        this.$emit('selectedOptionHandler', option);
      },
    },
  };
</script>

<style></style>
