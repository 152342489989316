<template>
  <app-header />
  <div class="bottom-0 w-full top-16">
    <app-loader :show="loader" />
    <base-container add-class="pt-20 md:pb-16">
      <div
        class="relative grid gap-8 p-4 pb-24 mx-4 bg-ext-light-pink rounded-2xl lg:p-8 lg:grid-cols-2 xl:mx-0 lg:pb-8"
      >
        <div class="relative">
          <base-text type="h2"
            >Feel free to <br />
            add the product feedback!</base-text
          >
        </div>
        <div>
          <base-text type="h4" add-class="font-bold"
            >Some basic details</base-text
          >
          <div class="relative">
            <img
              src="../assets/images/pattern4.svg"
              alt=""
              class="absolute -top-14 -right-6"
            />
            <div class="mb-6">
              <label>Name:</label>
              <input
                type="text"
                class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                placeholder="Enter Your Name"
                :class="{ error: v$.form.name.$errors.length }"
                v-model="v$.form.name.$model"
              />
              <div v-for="(error, index) of v$.form.name.$errors" :key="index">
                <div class="errorMsg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="mb-6">
              <label>Email:</label>
              <input
                type="text"
                class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                placeholder="Enter Your Email"
                :class="{ error: v$.form.email.$errors.length }"
                v-model="v$.form.email.$model"
              />
              <div v-for="(error, index) of v$.form.email.$errors" :key="index">
                <div class="errorMsg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="mb-6">
              <label>Phone no.:</label>
              <input
                type="text"
                class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                placeholder="Enter Phone No."
                :class="{ error: v$.form.phone.$errors.length }"
                v-model="v$.form.phone.$model"
              />
              <div v-for="(error, index) of v$.form.phone.$errors" :key="index">
                <div class="errorMsg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-6">
              <label>Order ID:</label>
              <input
                type="text"
                class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                placeholder="Enter Your Order ID"
                :class="{ error: v$.form.order_id.$errors.length }"
                v-model="v$.form.order_id.$model"
              />
              <div
                v-for="(error, index) of v$.form.order_id.$errors"
                :key="index"
              >
                <div class="errorMsg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="mb-6">
              <label>Rating:</label>
              <star-rating
                v-model="v$.form.rating.$model"
                disableClick="true"
                :class="{ error: v$.form.rating.$errors.length }"
              ></star-rating>
              <div
                v-for="(error, index) of v$.form.rating.$errors"
                :key="index"
              >
                <div class="errorMsg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-6">
              <label>Message:</label>
              <textarea
                class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                placeholder="Enter Your Message"
                rows="3"
                :class="{ error: v$.form.msg.$errors.length }"
                v-model="v$.form.msg.$model"
              ></textarea>
              <div v-for="(error, index) of v$.form.msg.$errors" :key="index">
                <div class="errorMsg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="mb-6">
              <label>Product Images (Optional):</label>
              <input
                type="file"
                multiple
                class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                accept="image/*"
                ref="productImage"
                @change="onFileChange"
              />
            </div>
            <div class="mb-6">
              <label>Your Image (Optional):</label>
              <input
                type="file"
                multiple
                class="w-full p-3 bg-transparent border rounded-lg border-ext-light-gray"
                accept="image/*"
                ref="yourImage"
                @change="onUserImageChange"
              />
            </div>

            <base-button class="w-full mr-2" @click="modalShow"
              >Submit now</base-button
            >
          </div>
          <img
            src="../assets/images/pattern5.svg"
            alt=""
            class="absolute -bottom-12 -left-5"
          />
        </div>
      </div>
    </base-container>
    <base-modal
      white
      :show-modal="showModal"
      width-class="max-w-md"
      @closeModal="showModal = !showModal"
    >
      <div class="my-5">
        <h2 class="mb-5 text-3xl font-bold">
          Are you sure? You want to publish your feedback.
        </h2>
      </div>
      <div class="flex flex-row" id="buttons">
        <base-button class="btn-primary" @click="submitContact"
          >Yes</base-button
        >
        <base-button
          class="ml-3 border-dark"
          mode="outlineBtn"
          @click="showModal = !showModal"
        >
          No
        </base-button>
      </div>
    </base-modal>
  </div>
  <app-footer />
</template>
<script>
  import AppHeader from '@/components/AppHeader.vue';
  import AppFooter from '@/components/AppFooter.vue';
  import VueStarRating from 'vue3-star-ratings';

  import BaseContainer from '../components/ui/BaseContainer.vue';
  import BaseText from '../components/ui/BaseText.vue';
  import BaseButton from '../components/ui/BaseButton.vue';

  import useVuelidate from '@vuelidate/core';
  import {
    required,
    email,
    numeric,
    minLength,
    maxLength,
  } from '@vuelidate/validators';
  import BaseModal from '@/components/ui/BaseModal.vue';
  import AppLoader from '../components/AppLoader.vue';
  import { validName, validNonScriptText } from '../Helper/Helper.js';

  export default {
    name: 'FeedbackPage',
    components: {
      AppHeader,
      AppLoader,
      AppFooter,
      BaseContainer,
      BaseText,
      BaseButton,
      'star-rating': VueStarRating,
      BaseModal,
    },
    data() {
      return {
        loader: false,
        form: {
          name: '',
          email: '',
          phone: '',
          msg: '',
          order_id: '',
          rating: 0,
          files: null,
          userImage: null,
        },
        showModal: false,
      };
    },
    validations() {
      return {
        form: {
          email: {
            required,
            email,
          },
          name: {
            required,
            name_validation: {
              $validator: validName,
              $message:
                'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
            },
          },
          phone: {
            required,
            numeric,
            min: minLength(10),
            max: maxLength(10),
          },
          msg: {
            required,
            name_validation: {
              $validator: validNonScriptText,
              $message: 'Invalid text. please do not use "<script>" tag',
            },
          },
          order_id: {
            required,
            name_validation: {
              $validator: validNonScriptText,
              $message: 'Invalid text. please do not use "<script>" tag',
            },
          },
          rating: {
            required,
          },
        },
      };
    },
    setup() {
      return { v$: useVuelidate() };
    },
    mounted() {
      window.scrollTo(0, 0);
      this.deleteCookies();
    },
    methods: {
      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },
      async modalShow() {
        this.deleteCookies();
        const result = await this.v$.$validate();
        if (!result) {
          return;
        } else {
          this.showModal = true;
        }
      },
      async submitContact() {
        this.deleteCookies();
        const result = await this.v$.$validate();
        this.showModal = false;
        this.loader = true;
        if (!result) {
          return;
        } else {
          var myHeaders = new Headers();
          myHeaders.append('Content-Type', 'multipart/form-data');

          let formData = new FormData();
          formData.append('name', this.form.name);
          formData.append('Message', this.form.msg);
          formData.append('Rating', this.form.rating);
          formData.append('email', this.form.email);
          if (this.form.userImage)
            formData.append('user_image', this.form.userImage);
          formData.append('order_id', this.form.order_id);
          if (this.form.files) {
            for (const i of Object.keys(this.form.files)) {
              formData.append(
                `${i !== '0' ? `productMedia${i}` : 'productMedia'}`,
                this.form.files[i],
              );
            }
          }
          const requestOptions = {
            method: 'POST',
            body: formData,
          };
          await fetch(
            'https://therarewindow.com/api/createTestimonial/',
            requestOptions,
          )
            .then((res) => {
              if (res.ok) {
                res.json().then((data) => {
                  if (data.status === 200) {
                    this.loader = false;
                    this.$toast.open({
                      message:
                        'It is our pleasure to hear your valuable feedback, thank you!',
                      type: 'success',
                      duration: 5000,
                      dismissible: true,
                    });
                    this.v$.$reset();
                    this.form = {
                      name: '',
                      email: '',
                      phone: '',
                      msg: '',
                      orderId: '',
                      rating: 0,
                      files: null,
                      userImage: null,
                    };
                    this.$refs.productImage.value = null;
                    this.$refs.yourImage.value = null;
                  } else {
                    this.loader = false;
                    this.$toast.open({
                      message: data.messages?.user_image
                        ? data.messages?.user_image.toString()
                        : data.messages,
                      type: 'error',
                      duration: 5000,
                      dismissible: true,
                    });
                  }
                });
              } else {
                res.json().then((err) => {
                  this.loader = false;
                  console.log(err);
                  this.$toast.open({
                    message: 'Contact not submited due to some error.',
                    type: 'error',
                    duration: 5000,
                    dismissible: true,
                  });
                });
              }
            })
            .catch((error) => console.log('error: ', error));
        }
      },
      onFileChange(e) {
        this.deleteCookies();
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        this.form.files = files;
      },
      onUserImageChange(e) {
        this.deleteCookies();
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        this.form.userImage = files[0];
      },
    },
  };
</script>
<style>
  .vue3-star-ratings button {
    height: 32px !important;
    width: 32px !important;
  }
  #buttons button svg.ml-2 {
    display: none;
  }
</style>
