<template>
  <div class="min-h-screen bg-ext-light-pink">
    <AppHeader />
    <app-loader :show="loader" />
    <div class="absolute bottom-0 w-full lg:flex top-16">
      <div
        class="self-center flex-grow hidden lg:block overflow-auto h-full"
        v-if="!mobileView"
      >
        <TheMapOfLovePreview />
      </div>
      <div
        class="flex-shrink bg-white relative xl:w-[512px] lg:w-[380px] sideBar"
      >
        <ul
          class="fixed z-10 grid w-full grid-cols-4 mb-6 text-sm font-semibold text-center bg-white lg:grid-cols-3 sm:text-base shadow-ext-bottom-2 lg:relative lg:w-auto"
        >
          <li
            class="py-2 cursor-pointer lg:hidden"
            :class="
              previewSelected
                ? 'border-b-2 border-ext-yellow bg-ext-yellow text-white'
                : 'bg-dark text-white'
            "
            @click="selectedTab = 'preview'"
          >
            <img
              src="../assets/images/eyeIcon.svg"
              alt=""
              class="mx-auto mb-2"
            />
            Preview*
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              designSelected || lastActiveTab == 'design'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              :src="
                tabDesignValidated
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/tabDesign.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Design
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              detailsSelected || lastActiveTab == 'details'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              :src="
                tabDetailsValidated
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/tabDetails.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Details
          </li>
          <li
            class="py-2 cursor-pointer"
            :class="
              formatSelected || lastActiveTab == 'format'
                ? 'border-b-2 border-black'
                : 'opacity-50'
            "
            @click="previewSelected && (selectedTab = lastActiveTab)"
          >
            <img
              src="../assets/images/tabFormat.svg"
              alt=""
              class="mx-auto mb-1"
            />
            Format
          </li>
        </ul>
        <div
          ref="options"
          class="absolute inset-x-0 bottom-0 pb-16 overflow-y-auto top-24"
          :class="previewSelected ? 'hidden' : ''"
        >
          <div v-show="designSelected">
            <base-card
              class="m-5"
              v-for="index in getNumOfMap"
              :key="index"
              :open="index == 1 ? true : false"
              @update-open="resizeMap(index - 1)"
            >
              <template #icon
                ><img src="@/assets/images/marker.svg"
              /></template>
              <template #title>Map {{ index }} Design</template>
              <template #body>
                <div
                  v-show="mobileView && index != 1"
                  class="relative w-full mb-4 -mt-5 h-44"
                >
                  <map-component
                    :api="getMapStyle[index - 1].mapTileApi"
                    :marker="getMapMarker[index - 1]"
                    :markerIndex="getMapMarker[index - 1].index"
                    :markerType="getMapMarker[index - 1].type"
                    :center="getCenter[index - 1]"
                    :index="index - 1"
                    :markerLabel="getMarkerLabel[index - 1]"
                    showNav
                    ref="mapComponent"
                  />
                </div>
                <location-input
                  ref="locationInput"
                  @update-center="
                    (center) => {
                      if (center) {
                        updateLocation(center, index);
                        locationAdded[index - 1] = true;
                      } else {
                        locationAdded[index - 1] = false;
                      }
                    }
                  "
                />
                <div class="px-5 mb-8" v-if="index == 1">
                  <div class="mb-2 font-semibold">Date*</div>
                  <input
                    type="date"
                    class="form-control"
                    :class="focus == 'date' && date == null ? 'errorInput' : ''"
                    placeholder="Your date"
                    v-model="date"
                    @input="setDate({ index: index, date: date })"
                  />
                </div>
                <div class="px-5 mb-8" v-if="index == 1">
                  <div class="mb-2 font-semibold">Time*</div>
                  <input
                    type="time"
                    class="form-control"
                    :class="focus == 'time' && time == null ? 'errorInput' : ''"
                    placeholder="Your time"
                    v-model="time"
                    @input="setTime({ index: index, time: time })"
                  />
                </div>
              </template>
            </base-card>
          </div>
          <div v-show="detailsSelected">
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Name1*</div>
              <input
                type="text"
                class="form-control"
                :class="focus == 'name1' && name1 == '' ? 'errorInput' : ''"
                placeholder="Enter Name"
                maxlength="15"
                v-model="name1"
                @input="setName1(name1)"
              />
              <small class="text-xs text-ext-gray">Max 15 characters </small>
            </div>
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Name2*</div>
              <input
                type="text"
                class="form-control"
                :class="focus == 'name2' && name2 == '' ? 'errorInput' : ''"
                placeholder="Enter Name"
                maxlength="15"
                v-model="name2"
                @input="setName2(name2)"
              />
              <small class="text-xs text-ext-gray">Max 15 characters </small>
            </div>
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Event*</div>
              <input
                type="text"
                class="form-control"
                :class="focus == 'event' && event == '' ? 'errorInput' : ''"
                placeholder="Enter Event"
                maxlength="15"
                v-model="event"
                @input="setEvent(event)"
              />
              <small class="text-xs text-ext-gray">Max 15 characters </small>
            </div>
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Date*</div>
              <input
                type="date"
                class="form-control"
                :class="
                  focus == 'detailDate' && detailDate == '' ? 'errorInput' : ''
                "
                placeholder="dd/mm/yyyy"
                v-model="detailDate"
                @input="updateDetailDate(detailDate)"
              />
            </div>
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Time*</div>
              <input
                type="time"
                class="form-control"
                :class="
                  focus == 'detailTime' && detailTime == '' ? 'errorInput' : ''
                "
                v-model="detailTime"
                @input="updateDetailTime(detailTime)"
              />
            </div>
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Location*</div>
              <input
                type="text"
                class="form-control"
                :class="
                  focus == 'location' && location == '' ? 'errorInput' : ''
                "
                placeholder="Enter Location"
                maxlength="15"
                v-model="location"
                @input="setLocation(location)"
              />
              <small class="text-xs text-ext-gray">Max 15 characters </small>
            </div>
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Quote*</div>
              <input
                type="text"
                class="form-control"
                :class="focus == 'quote' && quote == '' ? 'errorInput' : ''"
                placeholder="Enter Quote"
                maxlength="50"
                v-model="quote"
                @input="setQuote(quote)"
              />
              <small class="text-xs text-ext-gray">Max 50 characters </small>
            </div>
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Left Image*</div>
              <image-drag
                class="w-full"
                :class="
                  focus == 'leftImage' &&
                  getDetail.leftImage ==
                    'https://www.w3schools.com/tags/img_girl.jpg'
                    ? 'errorInput'
                    : ''
                "
                id="left-imageDrag"
                @load-image="updateImage($event, 'leftImage')"
              />
            </div>
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Center Image*</div>
              <image-drag
                class="w-full"
                :class="
                  focus == 'centerImage' &&
                  getDetail.centerImage ==
                    'https://www.w3schools.com/tags/img_girl.jpg'
                    ? 'errorInput'
                    : ''
                "
                id="center-imageDrag"
                @load-image="updateImage($event, 'centerImage')"
              />
            </div>
            <div class="px-5 mb-5">
              <div class="mb-2 font-semibold">Right Image*</div>
              <image-drag
                class="w-full"
                :class="
                  focus == 'rightImage' &&
                  getDetail.rightImage ==
                    'https://www.w3schools.com/tags/img_girl.jpg'
                    ? 'errorInput'
                    : ''
                "
                id="right-imageDrag"
                @load-image="updateImage($event, 'rightImage')"
              />
            </div>
          </div>
          <div v-show="formatSelected">
            <frame-color
              v-if="printType != 'Digital Copy'"
              @update-frame-color="
                (color) => {
                  setFrameColor(color.color);
                }
              "
            />
            <printing-type
              @selected-printing-type="(type) => updatePrintType(type)"
            />
            <div class="px-5 mb-8" v-if="printType != 'Digital Copy'">
              <div class="mb-2 font-semibold">Gifting message</div>
              <input
                type="text"
                class="form-control"
                maxlength="100"
                placeholder="Write your message"
                v-model="giftingMessage"
                @change="setGiftingMessage(giftingMessage)"
              />
              <div class="my-2 text-xs text-ext-gray">
                Extra charges would apply for gifting
              </div>
              <input
                type="checkbox"
                class="mr-2 inputCheckSq"
                v-model="giftWrapping"
                @change="setGiftWrapping(giftWrapping)"
              />
              Add gift wrapping
            </div>
          </div>
        </div>
        <div
          class="fixed inset-x-0 bottom-0 z-10 flex items-center justify-between p-2 bg-white shadow-sm sm:p-4 lg:absolute"
        >
          <span class="text-2xl font-semibold">
            <span v-if="formatSelected">₹ {{ getPrice }}</span>
          </span>
          <div class="flex items-center">
            <button
              v-if="!designSelected && !previewSelected"
              class="mr-2 btn-outline"
              @click="back"
            >
              <img
                src="@/assets/images/ArrowRight.svg"
                alt="arrow"
                class="mr-1 rotate-180"
              />
              Previous
            </button>
            <button
              v-if="!previewSelected"
              class="btn-primary"
              :class="formatSelected && getPrice == 0 ? 'hidden' : ''"
              @click="next"
            >
              {{ ctaBtnText }}
              <img
                src="@/assets/images/ArrowRight.svg"
                alt="arrow"
                class="ml-1"
              />
            </button>
            <button
              v-else
              class="btn-primary"
              @click.prevent="selectedTab = 'design'"
            >
              Customize
              <img
                src="@/assets/images/ArrowRight.svg"
                alt="arrow"
                class="ml-1"
              />
            </button>
          </div>
        </div>
        <template v-if="previewSelected">
          <div
            class="self-center flex-grow block pt-24 pb-20 lg:hidden md:px-8 overflow-auto h-full"
          >
            <TheMapOfLovePreview />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapMutations, mapActions } from 'vuex';
  import AppHeader from '../components/AppHeader.vue';
  import AppLoader from '../components/AppLoader.vue';
  import TheMapOfLovePreview from '../components/TheMapOfLovePreview.vue';
  import MapComponent from '@/components/map/MapComponent.vue';
  import LocationInput from '../components/productOptions/LocationInput.vue';
  import BaseCard from '@/components/ui/BaseCard.vue';
  import ImageDrag from './ImageDrag.vue';
  import FrameColor from '../components/productOptions/FrameColor.vue';
  import PrintingType from '../components/productOptions/PrintingType.vue';
  import moment from 'moment';
  import { useHead } from 'unhead';

  export default {
    components: {
      AppHeader,
      AppLoader,
      TheMapOfLovePreview,
      MapComponent,
      LocationInput,
      BaseCard,
      ImageDrag,
      FrameColor,
      PrintingType,
    },
    data() {
      return {
        selectedTab: 'preview',
        lastActiveTab: 'design',
        date: null,
        time: null,
        locationAdded: [false, false, false, false],
        tabDesignValidated: false,
        tabDetailsValidated: false,
        name1: '',
        name2: '',
        event: '',
        detailDate: '',
        detailTime: '',
        location: '',
        quote: '',
        focusDetails: ['', null, null],
        giftingMessage: '',
        giftWrapping: false,
        printType: null,
        price: 899,
        loader: false,
        focus: '',
      };
    },
    computed: {
      ...mapGetters('checkout', ['getPrice', 'getImages', 'getFinalImage']),
      ...mapGetters('mapObj', [
        'mapDataUrl',
        'getMapMarker',
        'getMapStyle',
        'getCenter',
        'getMarkerLabel',
        'getCountry',
      ]),
      ...mapGetters('theMapOfLove', [
        'selectedFrameSize',
        'selectedOption',
        'getNumOfMap',
        'getDetail',
      ]),
      designSelected() {
        return this.selectedTab == 'design';
      },
      detailsSelected() {
        return this.selectedTab == 'details';
      },
      formatSelected() {
        return this.selectedTab == 'format';
      },
      previewSelected() {
        return this.selectedTab == 'preview';
      },
      mobileView() {
        return window.screen.width < 1024;
      },
      ctaBtnText() {
        return this.designSelected || this.detailsSelected
          ? 'Next'
          : 'Checkout';
      },
    },
    async created() {
      this.mobileView
        ? (this.selectedTab = 'preview')
        : (this.selectedTab = 'design');
      this.resetMap();
      this.resetDetails();
      const style1 = {
        id: 5,
        image: 'mapStyling/style6.jpg',
        color: '#F7F7F7',
        name: '',
        mapTileApi:
          'https://api.maptiler.com/maps/3268f700-33a6-41e9-88e3-95a696d64fe0/style.json?key=13NAUPgM8t0KWKl79Fm7',
        contentBgColor: '#E2D6BA',
        contentColor: '#000000',
      };
      this.updateMapStyle(style1, 2);
      const style2 = {
        id: 6,
        image: 'mapStyling/style7.jpg',
        color: '#F7F7F7',
        name: '',
        mapTileApi:
          'https://api.maptiler.com/maps/9c75281f-0e9e-4268-9797-c220578be3e4/style.json?key=13NAUPgM8t0KWKl79Fm7',
        contentBgColor: '#E2D6BA',
        contentColor: '#000000',
      };
      this.updateMapStyle(style2, 3);
      const style3 = {
        id: 7,
        image: 'mapStyling/style8.jpg',
        color: '#F7F7F7',
        name: '',
        mapTileApi:
          'https://api.maptiler.com/maps/ec73ad6c-b78e-49bd-8c51-24c8b029d5b5/style.json?key=13NAUPgM8t0KWKl79Fm7',
        contentBgColor: '#E2D6BA',
        contentColor: '#000000',
      };
      this.updateMapStyle(style3, 4);
      const marker1 = {
        image: 'mapMarker/MapPin.png',
        name: null,
        type: 'normal',
      };
      const marker2 = {
        name: '',
        type: 'marker',
        image: 'mapMarker/MapPinWhite.png',
      };
      const marker3 = {
        name: '',
        type: 'marker',
        image: 'mapMarker/MapPinGreen.png',
      };
      this.updateMapMarker(marker1, 2);
      this.updateMapMarker(marker2, 3);
      this.updateMapMarker(marker3, 4);
      if (!this.getCountry) await this.fetchCountry();
      setTimeout(() => {
        if (this.getCountry != 'India') {
          const option = {
            id: 2,
            image: 'printType/printDigitalCopy.svg',
            name: 'Digital Copy',
          };
          this.updatePrintType(option);
        }
      }, 1000);
    },
    mounted() {
      this.deleteCookies();
      useHead({
        title: 'The Map of Love | Road of Love | The Rare Window',
        meta: [
          {
            name: 'title',
            content: 'The Map of Love | Road of Love | The Rare Window',
          },
          {
            name: 'description',
            content:
              'Rare Window developed a special journey map called The Map of Love. Understanding the various stages of a relationship will assist individuals and couples in navigating their relationships.',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: 'https://therarewindow.com/theMapOfLove',
          },
        ],
      });
    },
    beforeUnmount() {
      this.setCountry('');
    },
    methods: {
      ...mapMutations('mapObj', [
        'setMapStyle',
        'setCenter',
        'resetMap',
        'setMarkerLabel',
        'setCountry',
        'setMapMarker',
      ]),
      ...mapMutations('checkout', ['setProductDetails', 'setNumOfMaps']),
      ...mapMutations('theMapOfLove', [
        'setDate',
        'setTime',
        'setName1',
        'setName2',
        'setEvent',
        'setDetailDate',
        'setDetailTime',
        'setLocation',
        'setQuote',
        'setLeftImage',
        'setRightImage',
        'setCenterImage',
        'setFrameColor',
        'setPrintingType',
        'setGiftWrapping',
        'setGiftingMessage',
        'setStarBg',
        'resetDetails',
      ]),
      ...mapActions('checkout', ['checkPrice']),
      ...mapActions('mapObj', ['fetchCountry']),
      ...mapActions('theMapOfLove', ['createImg']),
      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },
      resizeMap(index) {
        this.deleteCookies();
        this.$refs.mapComponent[index].resizeMap();
      },
      updateLocation(center, key) {
        this.deleteCookies();
        this.setCenter({ center: center, key: key });
      },
      updateMapStyle(style, index) {
        this.deleteCookies();
        this.setMapStyle({
          name: style.name,
          api: style.mapTileApi,
          key: index,
        });
      },
      updateMapMarker(marker, index) {
        this.deleteCookies();
        this.setMapMarker({
          name: marker.name,
          index: marker.id,
          type: marker.type,
          image: marker.image,
          key: index,
        });
      },
      updateImage(e, text) {
        if (text == 'leftImage') this.setLeftImage(e);
        if (text == 'rightImage') this.setRightImage(e);
        if (text == 'centerImage') this.setCenterImage(e);
      },
      updateDetailDate(detailDate) {
        this.setDetailDate(moment(detailDate).format('Do MMMM YYYY'));
      },
      updateDetailTime(detailTime) {
        const data = new Date(
          `${
            this.detailDate
              ? this.detailDate
              : new Date().toDateString('dd-mm-yyyy')
          }  ${detailTime}`,
        );
        const ampm = data.getHours() >= 12 ? 'pm' : 'am';
        const time = `${moment(
          `${
            this.detailDate
              ? this.detailDate
              : new Date().toDateString('dd-mm-yyyy')
          }  ${detailTime}`,
        ).format('hh:mm')}${ampm}`;
        this.setDetailTime(time.replace(':', '.'));
      },
      updatePrintType(type) {
        this.deleteCookies();
        this.setPrintingType(type);
        this.printType = type.name;
        if (this.printType == 'Digital Copy') {
          this.giftWrapping = false;
          this.setGiftWrapping(this.giftWrapping);
          this.checkPrice('theMapOfLove');
        } else {
          this.checkPrice('theMapOfLove');
        }
      },
      async next() {
        this.deleteCookies();
        if (this.designSelected) {
          let count = 0;
          for (let i = 0; i < this.getNumOfMap; i++) {
            if (!this.locationAdded[i]) {
              count++;
              this.$refs.locationInput[i].makeFocus();
              this.$toast.open({
                message: `Please select location ${i + 1}.`,
                type: 'error',
                duration: 5000,
                dismissible: true,
                position: 'top',
              });
              this.tabDesignValidated = false;
            }
          }
          if (!this.date) {
            this.focus = 'date';
            this.$toast.open({
              message: 'Please select date.',
              type: 'error',
              duration: 5000,
              dismissible: true,
              position: 'top',
            });
          }
          if (!this.time) {
            this.focus = 'time';
            this.$toast.open({
              message: 'Please select time.',
              type: 'error',
              duration: 5000,
              dismissible: true,
              position: 'top',
            });
          }
          if (count == 0) {
            this.selectedTab = 'details';
            this.$refs.options.scrollTop = 0;
            this.tabDesignValidated = true;
          }
        } else if (this.detailsSelected) {
          if (this.name1) {
            if (this.name2) {
              if (this.event) {
                if (this.detailDate) {
                  if (this.detailTime) {
                    if (this.location) {
                      if (this.quote) {
                        if (
                          this.getDetail.leftImage !=
                          'https://www.w3schools.com/tags/img_girl.jpg'
                        ) {
                          if (
                            this.getDetail.centerImage !=
                            'https://www.w3schools.com/tags/img_girl.jpg'
                          ) {
                            if (
                              this.getDetail.rightImage !=
                              'https://www.w3schools.com/tags/img_girl.jpg'
                            ) {
                              this.selectedTab = 'format';
                              this.$refs.options.scrollTop = 0;
                              this.tabDetailsValidated = true;
                            } else {
                              this.focus = 'rightImage';
                              this.$toast.open({
                                message: `Please select right image.`,
                                type: 'error',
                                duration: 5000,
                                dismissible: true,
                                position: 'top',
                              });
                            }
                          } else {
                            this.focus = 'centerImage';
                            this.$toast.open({
                              message: `Please select center image.`,
                              type: 'error',
                              duration: 5000,
                              dismissible: true,
                              position: 'top',
                            });
                          }
                        } else {
                          this.focus = 'leftImage';
                          this.$toast.open({
                            message: `Please select left image.`,
                            type: 'error',
                            duration: 5000,
                            dismissible: true,
                            position: 'top',
                          });
                        }
                      } else {
                        this.focus = 'quote';
                        this.$toast.open({
                          message: `Please add quote text.`,
                          type: 'error',
                          duration: 5000,
                          dismissible: true,
                          position: 'top',
                        });
                      }
                    } else {
                      this.focus = 'location';
                      this.$toast.open({
                        message: `Please add location.`,
                        type: 'error',
                        duration: 5000,
                        dismissible: true,
                        position: 'top',
                      });
                    }
                  } else {
                    this.focus = 'detailTime';
                    this.$toast.open({
                      message: `Please select Time.`,
                      type: 'error',
                      duration: 5000,
                      dismissible: true,
                      position: 'top',
                    });
                  }
                } else {
                  this.focus = 'detailDate';
                  this.$toast.open({
                    message: `Please select date.`,
                    type: 'error',
                    duration: 5000,
                    dismissible: true,
                    position: 'top',
                  });
                }
              } else {
                this.focus = 'event';
                this.$toast.open({
                  message: `Please add event text.`,
                  type: 'error',
                  duration: 5000,
                  dismissible: true,
                  position: 'top',
                });
              }
            } else {
              this.focus = 'name2';
              this.$toast.open({
                message: `Please add name2.`,
                type: 'error',
                duration: 5000,
                dismissible: true,
                position: 'top',
              });
            }
          } else {
            this.focus = 'name1';
            this.$toast.open({
              message: `Please add name1.`,
              type: 'error',
              duration: 5000,
              dismissible: true,
              position: 'top',
            });
          }
        } else if (this.formatSelected) {
          this.loader = true;
          this.selectedTab = 'preview';
          this.$refs.options.scrollTop = 0;
          this.checkout();
          setTimeout(async () => {
            await this.createImg();
          }, 4000);
        }
      },
      back() {
        this.deleteCookies();
        if (this.designSelected) {
          this.selectedTab = 'preview';
        }
        if (this.detailsSelected) {
          this.selectedTab = 'design';
        }
        if (this.formatSelected) {
          this.selectedTab = 'details';
        }
      },
      checkout() {
        this.deleteCookies();
        this.setProductDetails({
          mapType: 1,
          productName: this.selectedOption.productName,
          frameSize: '8x12', //this.selectedOption.frameSize,
          frameColor: this.selectedOption.frameColor.color,
          printingType: this.selectedOption.printingType,
          giftingMsg: this.giftingMessage,
          giftWrap: this.giftWrapping,
          blobImage: this.getImages,
        });
        this.setNumOfMaps(this.getNumOfMap);
      },
    },
    watch: {
      getFinalImage(newVal) {
        this.deleteCookies();
        if (newVal) {
          this.$router.push('/checkout');
        }
      },
      designSelected(newVal) {
        this.deleteCookies();
        if (newVal == true) {
          // this.$refs.mapComponent[0].resizeMap();
        }
      },
      formatSelected(newVal) {
        if (newVal) {
          this.deleteCookies();
          this.checkPrice('theMapOfLove');
        }
      },
      selectedTab(newVal) {
        this.deleteCookies();
        if (newVal != 'preview') {
          this.lastActiveTab = newVal;
        }
      },
    },
  };
</script>
<style scoped>
  .sideBar {
    height: calc(100vh - 65px);
  }
  .mapboxgl-ctrl-top-right,
  .maplibregl-ctrl-top-right {
    right: 15px !important;
    top: 40px !important;
  }
</style>
