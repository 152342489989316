<template>
  <div>
    <app-header />
    <app-loader :show="loader" />
    <div class="container max-w-6xl mx-auto">
      <div class="grid-cols-12 gap-8 lg:grid">
        <div class="col-span-8 px-3 pt-20">
          <base-text type="h4" v-if="orderConfirm">
            Thanks you {{ orderDetails?.billing_address[0].firstName }}!
          </base-text>
          <div class="mb-8 -mt-3 text-sm text-ext-gray" v-if="orderConfirm">
            Order #{{ orderDetails?.custom_order_id }}
          </div>
          <div
            class="relative items-center grid-cols-4 p-5 mx-8 my-5 rounded-lg md:grid"
            :class="orderConfirm ? 'bg-dark' : 'bg-red-600'"
          >
            <img
              src="@/assets/images/pattern5.svg"
              class="absolute w-20 rotate-90 -top-8 -left-8"
            />
            <img
              src="@/assets/images/pattern5.svg"
              class="absolute -bottom-8 -right-8 rotate-[270deg] w-20"
            />
            <img v-if="orderConfirm" src="@/assets/images/confermed.svg" />

            <svg
              v-else
              class="w-full"
              width="100"
              height="100"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_67_2857)">
                <rect
                  x="8"
                  y="6"
                  width="48"
                  height="48"
                  rx="24"
                  fill="rgba(0,0,0,0)"
                />
                <rect
                  x="8.5"
                  y="6.5"
                  width="47"
                  height="47"
                  rx="23.5"
                  stroke="#FFFFFF"
                />
              </g>
              <path
                d="M40.5821 22.8321L41.2892 22.125L39.875 20.7108L39.1679 21.4179L40.5821 22.8321ZM23.4179 37.1679L22.7108 37.875L24.125 39.2892L24.8321 38.5821L23.4179 37.1679ZM39.1679 21.4179L23.4179 37.1679L24.8321 38.5821L40.5821 22.8321L39.1679 21.4179Z"
                fill="white"
              />
              <path
                d="M39.1679 38.5821L39.875 39.2892L41.2892 37.875L40.5821 37.1679L39.1679 38.5821ZM24.8321 21.4179L24.125 20.7108L22.7108 22.125L23.4179 22.8321L24.8321 21.4179ZM40.5821 37.1679L24.8321 21.4179L23.4179 22.8321L39.1679 38.5821L40.5821 37.1679Z"
                fill="white"
              />
              <defs>
                <filter
                  id="filter0_d_67_2857"
                  x="0"
                  y="0"
                  width="64"
                  height="64"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="4" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.472685 0 0 0 0 0.472685 0 0 0 0 0.472685 0 0 0 0.12 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_67_2857"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_67_2857"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            <div class="col-span-3 text-ext-light-gray">
              <h3 v-if="orderConfirm" class="mb-3 text-2xl font-semibold">
                Your order is confirmed!
              </h3>
              <h3 v-else class="mb-3 text-2xl font-semibold">
                Oh no, your payment failed!
              </h3>
              <p v-if="orderConfirm">
                Hi {{ orderDetails?.billing_address[0].firstName }}, Thanks for
                placing your order #{{ orderDetails?.custom_order_id }}! Your
                order has been confirmed & placed. You’ll receive an email when
                your order is ready. Cheers!
              </p>
              <p v-else>
                Don't worry, you can still try again from here<br /><br />
                <a
                  :href="paymentLink"
                  class="px-5 py-2 text-white border border-white rounded-3xl"
                  >Try Again</a
                >
              </p>
            </div>
          </div>

          <!-- <div class="mb-5">
            <div class="flex items-center p-5 text-lg font-semibold">
              <img src="@/assets/images/chat.svg" alt="" class="mr-2" /> Order
              updates
            </div>
            <p class="p-5">
              You'll get shipping and delivery updates by email.<br />
              Want to get shipping updates over text?<br />
              <button
                class="flex items-center p-3 mt-5 border rounded-full border-dark"
              >
                <img
                  src="@/assets/images/DeviceMobile.svg"
                  class="mr-2"
                />Confirm mobile number
              </button>
            </p>
          </div> -->

          <div>
            <div class="flex items-center p-5 text-lg font-semibold">
              <img src="@/assets/images/UserIcon.svg" alt="" class="mr-2" />
              Customer information
            </div>
            <div class="grid-cols-2 gap-4 p-5 md:grid">
              <div class="mb-3">
                <strong>Contact:</strong><br />
                {{ orderDetails?.email }}
              </div>
              <div class="mb-3">
                <strong>Payment:</strong><br />
                ₹{{ orderDetails?.total_amount }}
              </div>
              <div>
                <strong>Shipping address:</strong><br />
                {{ orderDetails?.shipping_address[0].firstName }}
                {{ orderDetails?.shipping_address[0].lastName }}<br />
                {{ orderDetails?.shipping_address[0].apartment }}<br />
                {{ orderDetails?.shipping_address[0].address }}<br />
                {{ orderDetails?.shipping_address[0].state }}<br />
                {{ orderDetails?.shipping_address[0].city }}<br />
                {{ orderDetails?.shipping_address[0].pin }}<br />
                {{ orderDetails?.shipping_address[0].phone }}<br />
              </div>
              <div>
                <strong>Billing address:</strong><br />
                {{ orderDetails?.billing_address[0].firstName }}
                {{ orderDetails?.billing_address[0].lastName }}<br />
                {{ orderDetails?.billing_address[0].apartment }}<br />
                {{ orderDetails?.billing_address[0].address }}<br />
                {{ orderDetails?.billing_address[0].state }}<br />
                {{ orderDetails?.billing_address[0].city }}<br />
                {{ orderDetails?.billing_address[0].pin }}<br />
                {{ orderDetails?.billing_address[0].phone }}<br />
              </div>
              <router-link to="/" class="my-3 btn-primary"
                >Continue Shopping</router-link
              >
              <a href="/#contactus" class="w-full my-3 btn-outline">
                Need help? Contact us!
              </a>
            </div>
          </div>
        </div>
        <div class="relative col-span-4 mb-5 lg:pt-16">
          <div class="w-full lg:absolute">
            <div class="p-6 bg-ext-light-pink lg:fixed xl:max-w-sm xl:w-full">
              <p class="uppercase">Your Order</p>
              <hr class="my-4" />
              <div class="grid grid-cols-3 gap-3">
                <div>
                  <img
                    :src="orderDetails?.finalblob_img"
                    class="pointer-events-none"
                    alt=""
                  />
                </div>
                <div class="col-span-2">
                  <base-text type="h5" add-class="uppercase">
                    {{ orderDetails?.product.productName }}
                  </base-text>
                  <div class="mb-2 -mt-2 text-ext-gray">
                    Quantity: {{ orderDetails?.quantity }}
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <div class="flex justify-between mb-4">
                <span class="text-ext-gray">Subtotal:</span>
                <strong>₹ {{ orderDetails?.total_amount }}</strong>
              </div>
              <div class="flex justify-between mb-4">
                <span class="text-ext-gray">Shipping:</span>
                <strong>Free</strong>
              </div>
              <hr class="my-4" />
              <div class="flex justify-between mb-4">
                <div>
                  <strong>Total:</strong>
                  <div class="text-sm text-ext-gray">
                    Inclusive of all taxes
                  </div>
                </div>
                <strong class="text-3xl"
                  >₹ {{ orderDetails?.total_amount }}</strong
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AppHeader from '@/components/AppHeader.vue';
  import AppFooter from '@/components/AppFooter.vue';
  import AppLoader from '@/components/AppLoader.vue';
  import BaseText from '@/components/ui/BaseText.vue';
  // import BaseButton from "@/components/ui/BaseButton.vue";
  export default {
    components: {
      AppHeader,
      AppFooter,
      BaseText,
      AppLoader,
    },
    data() {
      return {
        loader: false,
        orderId: '',
        orderToken: '',
        orderDetails: null,
        paymentDetails: {},
        paymentLink: null,
        error: null,
        orderConfirm: false,
      };
    },
    computed: {
      ...mapGetters(['rootApiUrl']),
    },
    async created() {
      const t = this;
      t.orderId = t.$route.query.order_id;
      t.orderToken = t.$route.query.order_token;
      if (!t.orderId == '') {
        t.loader = true;
        await t.getBookingDetails(t.orderId);
        // await t.getBookingDetails(t.orderId);
      } else {
        t.$router.push('/');
      }
    },
    mounted() {
      this.deleteCookies();
    },
    methods: {
      deleteCookies() {
        if (
          document.cookie.includes('csrftoken') ||
          document.cookie.includes('sessionid')
        ) {
          const date = new Date(
            new Date().setTime(new Date().getUTCSeconds() + 1),
          );
          document.cookie = `csrftoken=;expires=${date};`;
          document.cookie = `sessionid=;expires=${date};`;
        }
      },
      async getBookingDetails(orderId) {
        this.deleteCookies();
        this.loader = true;
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
          uuid: orderId,
        });
        const requestOptions = {
          method: 'Post',
          headers: myHeaders,
          body: raw,
        };
        await fetch(
          'https://therarewindow.com/api/orderDetails/',
          requestOptions,
        ).then((res) => {
          if (res.ok) {
            res.json().then((data) => {
              this.checkPaymentStatus(orderId);
              this.orderDetails = data.data;
            });
          } else {
            res.json().then((err) => (this.error = err));
          }
        });
      },
      async UpdateBooking(paymentDetails) {
        this.deleteCookies();
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        let raw = null;
        if (paymentDetails.order_status == 'PAID') {
          this.orderConfirm = true;
          if (this.orderDetails.products[0].printingType == 'Digital Copy') {
            raw = JSON.stringify({
              uuid: paymentDetails.order_id,
              payment_status: 'SUCCESSFUL',
              order_status: 'Completed',
              payment_order_token: this.orderToken,
              cf_order_id: paymentDetails.cf_order_id,
              email: paymentDetails.customer_details.customer_email,
            });
          } else {
            raw = JSON.stringify({
              uuid: paymentDetails.order_id,
              payment_status: 'SUCCESSFUL',
              order_status: 'Un Attended',
              payment_order_token: this.orderToken,
              cf_order_id: paymentDetails.cf_order_id,
              email: paymentDetails.customer_details.customer_email,
            });
          }
        } else if (paymentDetails.order_status == 'ACTIVE') {
          this.orderConfirm = false;
          this.paymentLink = paymentDetails.payment_link;
          raw = JSON.stringify({
            uuid: paymentDetails.order_id,
            payment_status: 'FAILED',
            order_status: 'Pending',
            payment_order_token: this.orderToken,
            cf_order_id: paymentDetails.cf_order_id,
            email: paymentDetails.customer_details.customer_email,
          });
        }

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };
        await fetch(
          'https://therarewindow.com/api/updateOrder/',
          requestOptions,
        )
          .then((response) => {
            console.log('response', response);
            this.loader = false;
            // this.getBookingDetails(this.orderId);
          })
          .catch((error) => {
            console.warn(error);
          });
      },
      async checkPaymentStatus(orderId) {
        this.deleteCookies();
        await fetch(
          `https://therarewindow.com/api/verifyPayment/${orderId}`,
        ).then((res) => {
          if (res.ok) {
            res.json().then((data) => {
              this.UpdateBooking(data);
            });
          } else {
            const error = new Error(
              res.message || 'Failed to book. Payment faield.',
            );
            throw error;
          }
        });
        // if (response.status !== 200) {
        //   const error = new Error(
        //     response.message || "Failed to book. Payment faield."
        //   );
        //   throw error;
        // }
        // response.json().then((data) => (this.paymentDetails = data));
      },
    },
  };
</script>

<style></style>
