import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

export default {
  async createImg(context) {
    return new Promise((resolve, reject) => {
      let frameWidth = 0;
      let frameHeight = 0;
      let celestialMapOp = context.rootGetters['celestialMap/selectedOption'];
      const mapShape = context.rootGetters['celestialMap/getMapShape'];
      if (celestialMapOp.numOfMaps == 1) {
        frameWidth = 768;
        frameHeight = 1152;
      } else {
        frameWidth = 1152;
        frameHeight = 768;
      }
      let imgPayload = {
        final: null,
        base: null,
        map: null,
      };
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [frameWidth, frameHeight],
      });
      let docWidth = doc.internal.pageSize.getWidth();
      let docHeight = doc.internal.pageSize.getHeight();

      let frame = document.createElement('div');
      frame.style.height = docHeight + 'px';
      frame.style.width = docWidth + 'px';
      frame.className = 'p-16 printFrame';
      document.body.appendChild(frame);

      let parent = document.createElement('div');
      parent.id = 'parent';
      parent.className = 'grid h-full grid-flow-col grid-rows-8';
      parent.setAttribute(
        'style',
        'background-color: ' + celestialMapOp.bg.color,
      );

      let header = document.createElement('div');
      if (!celestialMapOp.details.alternativeFont) {
        header.className =
          'flex items-center justify-center text-4xl font-ext-GreatVibes -mt-14 Content';
      } else {
        header.className =
          'flex items-center justify-center text-4xl font-bold -mt-14 Content';
      }

      header.appendChild(
        document.createTextNode(celestialMapOp.details.header),
      );
      parent.appendChild(header);

      let main = document.createElement('div');
      main.className = 'flex row-span-6';
      main.id = 'main';
      parent.appendChild(main);

      let footer = document.createElement('div');
      if (!celestialMapOp.details.alternativeFont) {
        footer.className =
          'flex items-center justify-center text-3xl font-ext-GreatVibes -mt-14 Content';
      } else {
        footer.className =
          'flex items-center justify-center text-3xl font-bold -mt-14 Content';
      }
      footer.appendChild(
        document.createTextNode(celestialMapOp.details.footer),
      );
      parent.appendChild(footer);

      frame.appendChild(parent);

      // const hidden = document.createElement('div');
      // hidden.className = 'hidden-map';
      // document.body.appendChild(hidden);
      // const container = document.createElement('div');
      // container.style.width = docWidth + 'px';
      // container.style.height = container.style.width;
      // hidden.appendChild(container);

      let wrapper = document.createElement('div');
      wrapper.className =
        'relative grid w-full h-full grid-flow-col grid-rows-5 px-2';
      main.appendChild(wrapper);

      let mapWrapper = document.createElement('div');
      mapWrapper.className =
        'flex items-center justify-center row-span-4 map-wrap';
      wrapper.appendChild(mapWrapper);

      let shape = document.createElement('div');
      shape.className = 'relative h-full shape';
      mapWrapper.appendChild(shape);

      if (mapShape.name == 'Heart' || mapShape.name == 'Circle') {
        let svg = document.getElementById('Warstwa_1').cloneNode(true);
        svg.id = mapShape.name;
        svg.setAttribute('style', 'position: absolute;');
        shape.appendChild(svg);
      }

      let img = document.createElement('img');
      img.className =
        'object-contain w-full h-full border border-transparent Print';
      shape.appendChild(img);

      let textWrapper = document.createElement('div');
      textWrapper.className = 'grid textOnMap conntent-center';
      wrapper.appendChild(textWrapper);

      let title = document.createElement('div');
      if (!celestialMapOp.details.alternativeFont) {
        title.className =
          'flex items-end justify-center text-2xl font-ext-GreatVibes Content';
      } else {
        title.className = 'flex items-end justify-center text-2xl Content';
      }
      title.appendChild(
        document.createTextNode(celestialMapOp.details.text[0].main),
      );
      textWrapper.appendChild(title);

      let subtitle = document.createElement('div');
      subtitle.className = 'flex items-center justify-center text-sm Content';
      subtitle.appendChild(
        document.createTextNode(celestialMapOp.details.text[0].sub),
      );
      textWrapper.appendChild(subtitle);

      html2canvas(frame, {
        useCORS: true,
        allowTaint: true,
        scale: 2,
      }).then(
        (frameCanvas) => {
          imgPayload.base = frameCanvas.toDataURL('image/png', 1.0);

          document.querySelector('.preview').style.width = '1420px';
          document.querySelector('.preview').style.position = 'absolute';
          document.querySelector('.preview').style.zIndex = '-10';
          let celestial = context.rootGetters['celestialMap/getCelestial'];
          let config = context.rootGetters['celestialMap/getCelestialConfig'];
          let configReset =
            context.rootGetters['celestialMap/getCelestialConfigReset'];
          celestial.apply(config);
          celestial.resize({ width: 0 });

          domtoimage.toPng(document.getElementById('celestial-map1')).then(
            function (dataUrl) {
              document.querySelector('.preview').removeAttribute('style');
              celestial.apply(configReset);
              celestial.resize({ width: 0 });
              img.src = dataUrl;
              html2canvas(frame, {
                useCORS: true,
                allowTaint: true,
                scale: 2,
              }).then(
                (finalCanvas) => {
                  let previewImg = finalCanvas.toDataURL('image/png', 1.0);
                  context.commit('checkout/setPreviewImg', previewImg, {
                    root: true,
                  });
                  imgPayload.final = previewImg;

                  let content = document.querySelectorAll('.Content');
                  for (let x = 0; x < content.length; x++) {
                    content[x].style.visibility = 'hidden';
                  }
                  document.getElementById('parent').style.backgroundColor =
                    'transparent';
                  html2canvas(frame, {
                    useCORS: true,
                    allowTaint: true,
                    scale: 2,
                  }).then(
                    (mapCanvas) => {
                      imgPayload.map = mapCanvas.toDataURL('image/png', 1.0);
                      document.body.removeChild(frame);

                      context.commit('checkout/setImages', imgPayload, {
                        root: true,
                      });
                      resolve(imgPayload);
                    },
                    (error) => {
                      reject(error);
                    },
                  );
                },
                (error) => {
                  reject(error);
                },
              );
            },
            (error) => {
              reject(error);
            },
          );
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};
