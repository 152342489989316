<template>
  <div class="fixed inset-x-0 top-0 z-50 p-3 bg-white shadow-sm md:py-3">
    <div
      class="container flex items-center justify-between h-10 max-w-6xl mx-auto"
    >
      <router-link :to="{ name: 'home' }"
        ><app-logo class="inline-block w-24 md:w-auto"
      /></router-link>
      <select
        v-if="!hide"
        class="px-2 py-2 bg-gray-100 border rounded md:min-w-[320px]"
        v-model="product"
        @change="$router.push({ name: product })"
      >
        <option value="timeCapsuleMap">Time Capsule Map</option>
        <option value="storyMap">Story Map</option>
        <option value="celestialMap">Celestial Map</option>
        <option value="journeyMap">Journey Map</option>
        <option value="theMapOfLove">Couple Map</option>
      </select>
    </div>
  </div>
</template>

<script>
  import AppLogo from './ui/AppLogo.vue';
  export default {
    name: 'AppHeaderHome',
    components: {
      AppLogo,
    },
    data() {
      return {
        product: null,
        hide: false,
      };
    },
    mounted() {
      this.product = this.$route.name;
      this.hide =
        this.$route.name == 'checkout' ||
        this.$route.name == 'proceedToPayment' ||
        this.$route.name == 'confirmation' ||
        this.$route.name == 'trackingOrder' ||
        this.$route.name == 'feedback';
    },
  };
</script>
